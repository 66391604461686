import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getLicenseHistory } from './LicenseRest';
import { gStyles } from '../utils/Styles';

import { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import TableComponent from './TableComponent';

import { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let operation_codes = new LocalizedStrings({
  en: {
    type: 'Operation ',
    date: 'Date ',
    user: 'User ',
    data: 'Notes ',
    pc: 'PC ',
    asc: 'Oldest',
    desc: 'Latest',
    "100": 'License creation',
    "101": 'License activation',
    "102": 'License suspension',
    "103": 'License start',
    "104": 'License renewal',
    "105": 'License upgrade',
    "106": 'License upgrade',
    "107": 'License PC activation',
    "108": 'License PC deactivation',
    "109": 'License info update',
    "110": 'License blocked'
  }
})


function LicenseHistoryTable(props) {

  const { classes } = props;

  const { licenseHistoryList } = props;
  const { licenseId } = useParams();

  const [sort, setSort] = useState(false);

  const [state, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  var page = Number(searchParams.get('page'));

  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage === 0) ? 10 : urlRowsPerPage;

  useEffect(
    () => {
      getLicenseHistory(licenseId, sort);
    },
    [licenseId, sort, page, rowsPerPage]
  );

  const handleSortClick = () => {
    setSort(!sort)
    forceUpdate();
  }


  // QUICK NOTE: "Notes" column will present one of three cases:
  // - upgrade license: license upgraded from and license upgraded to
  // - extra information: about PC (MSBN, SN, UUID and V)
  // - license information update: value of prop before update -> value of prop after update
  const columns = [
    {
      label: 'Operation',
      renderContent: (item) => operation_codes[item.type]
    },
    {
      label: 'Date',
      renderContent: (item) => {
        const date = new Date(item.create_date * 1000);
        return date.toLocaleString('it-IT')
      },
      sortable: true
    },
    {
      label: 'User',
      renderContent: (item) => item.user
    },
    {
      label: 'PC',
      renderContent: (item) => {
        const data = JSON.parse(item.data);
        if (data.pcUIDinfo !== undefined && data.pcUIDinfo != "") return data.pcUIDinfo.PC;
        return "";
      }
    },
    {
      label: 'Additional info',
      renderContent: (item) => {
        const data = JSON.parse(item.data);

        // License info upgrade
        if (data.upgradeFrom !== undefined && data.upgradeTo !== undefined) {
          const upgradeFrom = data.upgradeFrom;
          const upgradeTo = data.upgradeTo;
          if (item.type == 105 || item.type == 106)
            return (upgradeFrom !== undefined && upgradeTo !== "") ?
              upgradeFrom + " upgraded to " + upgradeTo :
              ""
        }

        // PCUID info
        if (item.type == 107 || item.type == 108) {
          const pcUIDinfo = JSON.stringify(data.pcUIDinfo);
          if (pcUIDinfo) {
            return pcUIDinfo.replace(/[{}]/g, '').replaceAll("\",\"", "\"\n\"");
          }
          return "";
        }

        // License info update from Portal
        if (item.type == 109) {
          const additional_data = JSON.stringify(data.additional_data);
          if (additional_data) {
            const license_upgrade_info = additional_data.replaceAll(",", "'\n'");
            return license_upgrade_info.replace(/[{}]/g, '');
          }
          return "";
        }
        return data.operation ? data.operation : "";
      }
    },
  ];

  // Render function to provide the unique key for each row
  const renderRowKey = (item) => item.operation_id;

  const renderCellContent = (item, column) => column.renderContent(item);

  return (
    <TableComponent
      data={licenseHistoryList}
      columns={columns}
      classes={classes}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPage !== 10 && rowsPerPage !== 20 && rowsPerPage !== 40 ? [rowsPerPage, 10, 20, 40] : [10, 20, 40]}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSortClick={handleSortClick}
      history={history}
      renderRowKey={renderRowKey}
      renderCellContent={renderCellContent}
      loading={licenseHistoryList === undefined}
    />
  );
}

const mapStateToProps = state => {
  return {
    licenseHistoryList: state.licenseHistoryList,
  }
};

export default connect(mapStateToProps)(withStyles(gStyles)(LicenseHistoryTable));