import React, { useEffect, useState }  from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsNotifications, setSettingsNotification, deleteSettingsNotification } from './SettingsRest';
import { formatDateEpoch } from '../utils/Utils';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, FormLabel } from '@material-ui/core';
import { Box, Paper, Typography, CircularProgress, TextField, Button, IconButton, Grid, Select, Tooltip, Checkbox } from '@material-ui/core';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { DateTimePicker } from "@material-ui/pickers";
import { AddCircle, Edit, Delete, MoreHoriz } from '@material-ui/icons';

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    title:'Notifications',
    titleDialog:'Notification',

    tableHeaderActive:'Active',
    tableHeaderType:'Type',
    tableHeaderMsg:'Message',
    tableHeaderStart:'Starts',
    tableHeaderEnd:'Expires',
    
    licenseExpireLabel:'Shows when the license has expired or will expire in {value} day(s).',
    printerRibbonUsageLabel:'Shows when the printer ribbon is bellow {value}%.',
    printerUsageLabel:'Shows when the printer has printed more than {value} cards.',
    alwaysLabel:'Always shows the following message.',

    licenseExpireSelectLabel:'Licenses expiring in ... day(s).',
    printerRibbonUsageSelectLabel:'Printers that are using ribbons under ... %.',
    printerUsageSelectLabel:'Printers that printed more than ... cards.',
    alwaysSelectLabel:'Always shows.',

    neverExpiresLabel:'<never Expires>',

    labelType:'Type',
    labelValue:'Value',
    labelMsg:'Message',
    labelPersistent:'Persistent',
    labelNeverExpires:'Never expires',
    labelStartDate:'Start Date',
    labelEndDate:'End Date',

    buttonAdd:'Add Notification',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    toolTipEdit:'Edit the notification',
    toolTipDelete:'Delete the notification',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this notification?',
    yes: 'Yes',
    no: 'No',
	}
})

function SettingsNotifications( props ) {

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { } );
  const [ message, setMessage ] = useState( '' );
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );

  const { settingsNotificationTableForceRefresh } = props;
  const { settingsNotificationList } = props;

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;

  useEffect(
    ( ) => {
      getSettingsNotifications( page * rowsPerPage, rowsPerPage );
    },
    [ page, rowsPerPage, settingsNotificationTableForceRefresh ]
  );

  const handleChange = name => event => {

    switch ( name ) {
      case 'value': {

        var newValue = 0;
        if ( values.type === 201) {
          event.target.value = event.target.value === '100' ? values.value : event.target.value;
          newValue = Math.max(0, Number( event.target.value ) ).toString().slice( 0, 2 );
        }
        else if ( values.type === 202) {
          event.target.value = event.target.value === '1000000' ? values.value : event.target.value;
          newValue = Math.max(0, Number( event.target.value ) ).toString().slice( 0, 6 );
        }
        else {
          event.target.value = event.target.value === '1000' ? values.value : event.target.value;
          newValue = Math.max(0, Number( event.target.value ) ).toString().slice( 0, 3 );
        }

        setValues( { ...values, value: ( values.type === 201 ) ? newValue * 10 : newValue } );
        break;
      }
      case 'type': {
        setValues( { ...values, type: Number( event.target.value ), value: 0 } );
        break;
      }
      case 'msg': {
        setMessage( event );
        break;
      }
      case 'persistent': {
        setValues( { ...values, persistent: Number(!values.persistent) } );
        break;
      }     
      case 'start_date': {
        setValues( { ...values, start_date: event.getTime() / 1000 } );
        break;
      }
      case 'end_date': {
        setValues( { ...values, end_date: event.getTime() / 1000 } );
        break;
      }

      default:
        break;
    }
  };

  const handleAdd = () => {
    setEditMode( true );
    var now = new Date().getTime() / 1000;
    setValues( {
      notification_id: 0,
      active: 1,
      type: 501,
      value: 0,
      msg: '',
      persistent: 1,
      start_date: now,
      end_date: now + 2592000 } ); //30 days
  }

  const handleActive = ( notification ) => {
    setSettingsNotification( { ...notification, active: !notification.active } );
  }

  const handleEdit = notification => {
    setEditMode( true );
    setValues( {
      notification_id: notification.notification_id,
      active: notification.active,
      type: notification.type,
      value: notification.value,
      msg: notification.msg,
      persistent: notification.persistent,
      start_date: notification.start_date,
      end_date: notification.end_date } );
  };

  const handleClose = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    //this timeout is need to wait for the last onChange from the SunEditor
    //to avoid quick move between last character pressed and click to confirm
    setTimeout( () => {
      setEditMode( false );
      setSettingsNotification( { ...values, msg: message } );
    }, 300 );
  };

  const handleDeleteDialogClose = confirm => {
    if ( confirm )
    deleteSettingsNotification( entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleDelete = notification => {
    setEntityToDelete( notification );
  };

  return (
    <Box>
      <Paper className={ classes.workareaTopBoxes }>
        <Box className={ classes.flexHeader }>
          <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
          <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( ) => handleAdd( ) }>
            <AddCircle className={ classes.leftIcon } />
            <Typography variant='button'>{ strings.buttonAdd }</Typography>
        </Button>
        </Box>
        { settingsNotificationList !== undefined &&
          <Box className={ classes.tableArea }>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderActive }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderType }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderMsg }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderStart }</Typography>
                  </TableCell>                                                      
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderEnd }</Typography>
                  </TableCell>                                                      
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>              
              <TableBody>
                { settingsNotificationList.items.map( ( notification ) => ( 
                  <TableRow key={ notification.notification_id }>
                    <TableCell padding="checkbox">
                      <Checkbox checked={ notification.active === 1 } onClick={ ( ) => handleActive( notification ) }/>
                    </TableCell>                     
                    <TableCell>
                      { notification.type === 101 &&
                        <Typography variant='body2'>{ strings.licenseExpireLabel.replace( '{value}', notification.value ) }</Typography>
                      }
                      { notification.type === 201 &&
                        <Typography variant='body2'>{ strings.printerRibbonUsageLabel.replace( '{value}', notification.value / 10 ) }</Typography>
                      }
                      { notification.type === 202 &&
                        <Typography variant='body2'>{ strings.printerUsageLabel.replace( '{value}', notification.value ) }</Typography>
                      }
                      { notification.type === 501 &&
                        <Typography variant='body2'>{ strings.alwaysLabel }</Typography>
                      }
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={ <SunEditor
                                  setContents={ notification.msg }
                                  setOptions={{
                                    mode:'balloon',
                                    resizingBar: false,
                                    width: '300px',
                                    buttonList: [ ] }} />
                        } >
                        <MoreHoriz/>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ notification.persistent !==  1 ? formatDateEpoch( notification.start_date ) : "" }</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ notification.persistent !==  1 ? formatDateEpoch( notification.end_date ) : strings.neverExpiresLabel }</Typography>
                    </TableCell>                                        
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipEdit }>
                        <IconButton size='small' onClick={ ( ) => handleEdit( notification ) } >
                          <Edit/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipDelete }>
                        <IconButton size='small' onClick={ ( ) => handleDelete( notification ) } >
                          <Delete/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow> ) )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 7 } count={ settingsNotificationList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        { settingsNotificationList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Paper>
      
      <Dialog open={ editMode } style={ { zIndex:0, height:'900px' } }>
        <Box className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleDialog }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

              <Grid item xs={9}>
                <FormControl variant='outlined'>
                  <InputLabel required>{ strings.labelType }</InputLabel>
                  <Select
                    native
                    value={ values.type }
                    onChange={ handleChange('type') }
                    input={ <OutlinedInput labelWidth={ 50 } name='type'/> }>
                    <option value={ 101 }>{ strings.licenseExpireSelectLabel }</option>
                    <option value={ 201 }>{ strings.printerRibbonUsageSelectLabel }</option>
                    <option value={ 202 }>{ strings.printerUsageSelectLabel }</option>
                    <option value={ 501 }>{ strings.alwaysSelectLabel }</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <TextField variant='outlined' required={ values.type !== 501 } disabled={ values.type === 501 } fullWidth id='value'
                  label={ strings.labelValue } value={ values.type !== 201 ? values.value : values.value / 10 } onChange={ handleChange('value') }
                  type='number'/>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth >
                  <InputLabel variant='outlined'>{ strings.labelPersistent }</InputLabel>
                  <OutlinedInput
                    labelWidth={ 75 }
                    startAdornment={
                      <InputAdornment position="start">
                        <Checkbox
                          checked={ values.persistent === 1 }
                          onChange={ handleChange( 'persistent' ) } />
                        <Typography>{ strings.labelNeverExpires }</Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <DateTimePicker
                  label={ strings.labelStartDate }
                  showTodayButton
                  ampm={false}
                  required={ values.persistent === 1 }
                  disabled={ values.persistent === 1 }
                  fullWidth
                  inputVariant='outlined'
                  value={ formatDateEpoch( values.start_date ) }
                  onChange={ handleChange('start_date') }
                  format='yyyy/MM/dd HH:mm' />
              </Grid>

              <Grid item xs={6}>
                <DateTimePicker
                  label={ strings.labelEndDate }
                  showTodayButton
                  ampm={false}
                  required={ values.persistent === 1 }
                  disabled={ values.persistent === 1 }
                  fullWidth
                  inputVariant='outlined'
                  value={ formatDateEpoch( values.end_date ) }
                  onChange={ handleChange('end_date') }
                  format='yyyy/MM/dd HH:mm' />
              </Grid>

              <Grid item xs={12}>
                <FormControl variant='outlined' required>
                  <FormLabel><Typography variant='caption'>{ strings.labelMsg }</Typography></FormLabel>
                  <SunEditor
                    setContents={ values.msg }
                    onChange={ handleChange( 'msg' ) }
                    setOptions={{
                      mode:'classic',
                      resizingBar: false,
                      width: '570px',
                      buttonList: [ ['undo', 'redo'], ['formatBlock'], ['bold', 'underline', 'italic', 'align'], ['link' ], ] }} />
                </FormControl>
              </Grid>

            </Grid>

            <Box className={ classes.lineButtonBox }>
              <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
              <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleClose() }>{ strings.buttonCancel }</Button>
            </Box> 
          </form>
        </Box>       
      </Dialog>

      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsNotificationTableForceRefresh: state.settingsNotificationTableForceRefresh,
    settingsNotificationList: state.settingsNotificationList,
  }
};

export default connect( mapStateToProps, {  })( withStyles( gStyles )( SettingsNotifications ) );