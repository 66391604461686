import React, { useEffect, useState, useRef }  from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMEditions, setSettingsOEMEdition, deleteSettingsOEMEdition } from './SettingsRest';
import { gStyles } from '../utils/Styles';
import DropzonePic from '../components/DropzonePic';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, FormLabel, TextField } from '@material-ui/core';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Icon, Tooltip } from '@material-ui/core';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Edit, Delete, MoreHoriz } from '@material-ui/icons';
import ImageIcon from '@material-ui/icons/Image';


import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';


let strings=new LocalizedStrings({
	en:{
    title:'OEM Editions',
    titleDialog:'Edit Edition',

    tableHeaderCode:'Product Code',
    tableHeaderFile:'Product File',
    tableHeaderDescription:'Product Description',


    labelCode:'Product Code',
    labelFile:'Product File',
    labelDescription:'Description',
    labelPrefix:'Prefix',
    labelNotes:'Notes',

    buttonAdd:'Add Edition',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    toolTipEdit:'Edit Edition',
    toolTipDelete:'Delete Edition',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this edition?',
    yes: 'Yes',
    no: 'No',
	}
})

function SettingsOEMDetailEditions( props ) {

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { } );
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );


  const { settingsOEMEditionsTableForceRefresh } = props;
  const { settingsOEMEditionsList } = props;
  const { oemId } = useParams();


  const [ state, setState ] = useState( { hover: false } );   

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;
  const fileUploader = useRef(null);


  useEffect(
    ( ) => {
      getSettingsOEMEditions( page * rowsPerPage, rowsPerPage, oemId );
    },
    [ page, rowsPerPage, settingsOEMEditionsTableForceRefresh ]
  );

  const startNewFile = ( file ) => {
    var reader = new FileReader();
    reader.onload = () => {
     setValues( { ...values, picture: reader.result } );
    };
    reader.readAsDataURL(file);
  }

  const handleChange = name => event => {

    switch ( name ) {
      case 'name': {
        setValues( { ...values, name: event.target.value } );
        break;
      }
      case 'file': {
        setValues( { ...values, file: event.target.value } );
        break;
      }
      case 'description': {
        setValues( { ...values, description: event.target.value } );
        break;
      }

      default:
        break;
    }
  };

  const mouseOver = () => {
    setState({ hover: true });
    }

  const mouseOut = () => {
    setState({ hover: false });
  }

  const onPictureEdit = ( event ) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues( { ...values, picture: reader.result } );
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  const handleEdit = edition => {
    setEditMode( true );
    setValues( {
      oem_id: edition.productfamily_id,
      name: edition.product_code,
      file: edition.product_file,
      title: edition.product_public,
      description: edition.product_description,
      link: edition.product_link,
      picture: edition.product_picture,
      } );
  };

  const handleClose = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setEditMode( false );
    setSettingsOEMEdition( values );
  };

  const handleDeleteDialogClose = confirm => {
    if ( confirm )
    //deleteSettingsOEMEdition( entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleDelete = edition => {
    setEntityToDelete( edition );
  };

  return (
    <Box>        
      <Paper className={ classes.workareaTopBoxes }>
        <Box className={ classes.flexHeader }>
          <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
        </Box>
        { settingsOEMEditionsList !== undefined &&
          <Box className={ classes.tableArea }>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderCode }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderFile }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderDescription }</Typography>
                  </TableCell>                                                   
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>              
              <TableBody>
                { settingsOEMEditionsList.items.map( ( edition ) => ( 
                  <TableRow key={ edition.editionId }>
                    <TableCell style={{ width:'20%' }}>{ edition.product_code }</TableCell>        
                    <TableCell style={{ width:'35%' }}>{ edition.product_file }</TableCell>                                  
                    <TableCell style={{ width:'35%' }}>{ edition.product_description }</TableCell>                     
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipEdit }>
                        <IconButton size='small' onClick={ ( ) => handleEdit( edition ) } >
                          <Edit/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipDelete }>
                        <IconButton size='small' onClick={ ( ) => handleDelete( edition ) } >
                          <Delete/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow> ) )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 9 } count={ settingsOEMEditionsList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        { settingsOEMEditionsList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Paper>
      
      <Dialog open={ editMode } style={ { zIndex:0, height:'900px' } }>
        <Box className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleDialog }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

            <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='name' disabled
                  label={ strings.labelCode } value={ values.name }  onChange={ handleChange('name') }/>
            </Grid>

            <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='file'
                  label={ strings.labelFile } value={ values.file } onChange={ handleChange('file') } />
            </Grid>

            <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='description'
                  label={ strings.labelDescription } value={ values.description } onChange={ handleChange('description') } />
            </Grid>

            </Grid>

            <Box className={ classes.lineButtonBox }>
              <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
              <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleClose() }>{ strings.buttonCancel }</Button>
            </Box> 
          </form>
        </Box>       
      </Dialog>

      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMEditionsTableForceRefresh: state.settingsOEMEditionsTableForceRefresh,
    settingsOEMEditionsList: state.settingsOEMEditionsList,
  }
};

export default connect( mapStateToProps, {  })( withStyles( gStyles )( SettingsOEMDetailEditions ) );