import React from 'react';
import ReactDOM  from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import rootReducer, { initialState } from './utils/Reducer';
import { loadFromLocalStorage } from './utils/Utils';

import { ThemeProvider } from '@material-ui/styles';
import theme from './utils/Styles';

import App from './App';

const persistedState = loadFromLocalStorage();
export const store = createStore(rootReducer, { ...initialState, ...persistedState } );

//store.subscribe( () => saveToLocalStorage( store.getState( ) ));

ReactDOM.render(
  <ThemeProvider theme={ theme }>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={ store }>
        <Router>
          <Route path='/:filter?'>
            <App />
          </Route>
        </Router>
      </Provider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
