import React, { useEffect, useState }  from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getDistributorSubDistributors } from './DistributorRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { Paper, Typography, InputBase, IconButton, CircularProgress } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
    en: {
        tableHeaderSubDistributorId:'ID',
        tableHeaderSubDistributorName:'Sub Distributor',
        tableHeaderSubDistributorEmail:'Email',
        tableHeaderSubDistributorPhone:'Phone Number',
        tableHeaderSubDistributorCustomers:'# Customers',
        tableHeaderSubDistributorLicenses:'# Licenses',
        tableHeaderSubDistributorPrinters:'# Printers',

        searchPlaceHolder:'search'
    }
});

function DistributorSubDistributorTable( props ) { 

  const { classes } = props;

  const { distributorSubDistributorTableForceRefresh } = props;
  const { distributorSubDistributorList } = props;
  const { distributorId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;

  const searchFromURL = searchParams.get( 'search' ) === null ? '' : searchParams.get( 'search' );
  const [ searchValue, setSearchValue ] = useState( '' );

  useEffect(
    ( ) => {
      setSearchValue( searchFromURL );
      getDistributorSubDistributors( distributorId, page * rowsPerPage, rowsPerPage, searchFromURL );
    },
    [ distributorId, page, rowsPerPage, searchFromURL, distributorSubDistributorTableForceRefresh ]
  );

  const searchKeyPress = event => {
    if ( event.charCode === 13 ) {//ENTER key pressed
      event.preventDefault();
      handleSearch( );
    }
  }

  const handleSearch = () => {
    var searchParams = new URLSearchParams( history.location.search );
    if ( searchValue !== '' )
      searchParams.set( 'search', searchValue );
    else
      searchParams.delete( 'search' );
    searchParams.delete( 'page' ); 
    history.push( { search: searchParams.toString() } );
  }

  return (
    <div>
      <div className={ classes.workarea }>
        <div className={ classes.flexHeader }>
            <div className={ classes.flexExpand }/>
            <Paper className={ classes.searchPaper }>
                <InputBase
                    className={ classes.searchInput }
                    placeholder={ strings.searchPlaceHolder }
                    inputProps={ { 'aria-label': 'Search Text' } }
                    value={ searchValue }
                    onChange={ ( event ) => setSearchValue( event.target.value ) }
                    onKeyPress={ searchKeyPress } />
                <IconButton aria-label='Search' size='small' onClick={ handleSearch }>
                    <Search/>
                </IconButton>
            </Paper>
        </div>
        <div className={ classes.tableArea } >
          { distributorSubDistributorList !== undefined &&
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderSubDistributorId }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderSubDistributorName }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderSubDistributorEmail }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderSubDistributorPhone }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderSubDistributorCustomers }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderSubDistributorLicenses }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderSubDistributorPrinters }</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { distributorSubDistributorList.items.map( ( subDistributor, index ) => (
                  <TableRow key={ index }>  
                    <TableCell>  
                        <Typography variant='body2'>{ subDistributor.distributor_id }</Typography>
                    </TableCell>
                    <TableCell>
                        { <Link className={ classes.link } to={ '/distributors/' + subDistributor.distributor_id + '/details' }>
                            <Typography variant='body2'>{ subDistributor.name }</Typography>
                        </Link> }
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ subDistributor.email }</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ subDistributor.phone }</Typography>
                    </TableCell>                                      
                    <TableCell>
                      <Link className={ classes.link } to={ '/customers?search=' + subDistributor.name }>
                        <Typography variant='body2'>{ subDistributor.customers }</Typography>
                      </Link>  
                    </TableCell>
                    <TableCell>
                      <Link className={ classes.link } to={ '/licenses?search=' + subDistributor.name }>
                        <Typography variant='body2'>{ subDistributor.licenses }</Typography>
                      </Link>                    
                    </TableCell>
                    <TableCell>
                      <Link className={ classes.link } to={ '/printers?search=' + subDistributor.name }>
                        <Typography variant='body2'>{ subDistributor.printers }</Typography>
                      </Link>  
                    </TableCell>
                  </TableRow>
                )) }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 4 } count={ distributorSubDistributorList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          }
          { distributorSubDistributorList === undefined &&
              <CircularProgress className={ classes.spinner }/>
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => { 
  return {
    distributorSubDistributorTableForceRefresh: state.distributorSubDistributorTableForceRefresh,
    distributorSubDistributorList: state.distributorSubDistributorList,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( DistributorSubDistributorTable ) );