import React, { useEffect, useState }  from 'react';
import LocalizedStrings from 'react-localization';
import { connect, useDispatch } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Box, Paper, Typography, Card, ButtonBase, CardMedia, Grid, CardHeader } from '@material-ui/core';

import gridPic1 from './../resources/grids/grid1.png';
import gridPic2 from './../resources/grids/grid2.png';
import gridPic3 from './../resources/grids/grid3.png';
import gridPic4 from './../resources/grids/grid4.png';


let strings=new LocalizedStrings({
	en:{
    title:'MY DEFAULT GRID',

    info:'Default position for toolbars and dockbars.\nThis is not fixed. The user can change and adapt as he prefers.'  ,
    
    grid1:'Grid 1',
    grid2:'Grid 2',
    grid3:'Grid 3',
    grid4:'Grid 4',

	}
})


function SettingsOEMGrid( props ) {

  const { classes } = props;
  const { settingsOEMThemeValues } = props;
  const { settingsOEMEditorForceRefresh } = props;

  const [ values, setValues ] = useState( { valid: false, oem_id: 0, data:{} } );

  const dispatch = useDispatch();

  useEffect(
    () => {
    },
    [ settingsOEMEditorForceRefresh, settingsOEMThemeValues ]
  );

  if ( settingsOEMThemeValues !== undefined && !values.valid ) {   
    setValues( {
      valid: true,
      oem_id: settingsOEMThemeValues.productfamily_id,
      data:{
        grid: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.grid,
      }   ,

    } );
  }

  const handleSelectGrid = gridNum => event => {
    setValues( { ...values, data: { ...values.data , grid: gridNum} } );
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues , data: { ...settingsOEMThemeValues.data , grid: gridNum} } });

  };
  

  return (
    <Box>
    <Paper className={ classes.workareaTopGrid }  elevation={0}>
      <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
      <Typography variant='subtitle1' >{ strings.info }</Typography>
    </Paper>  
    <Paper className={ classes.workareaGrid }  elevation={0}>
      <Box>
       <Grid container spacing={2}>


          <Grid item xs={3}>
            <Card style={{ backgroundColor: values.data.grid === 1 ? '#5BC592' : ''  }}>
              <CardHeader title = "Grid1" />
              <Grid container alignItems="center" justify="center">
                <ButtonBase className={classes.cardAction} onClick={ handleSelectGrid(1) } >
                  <CardMedia title="Grid1"/>
                    <img className={ classes.appBarLogoImg } src={ gridPic1 } alt=''/>
                  <CardMedia/>
                </ButtonBase>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card style={{ backgroundColor: values.data.grid === 2 ? '#5BC592' : ''  }}>
              <CardHeader title = "Grid2" />
              <Grid container alignItems="center" justify="center">
                <ButtonBase className={classes.cardAction} onClick={ handleSelectGrid(2) } >
                  <CardMedia title="Grid2"/>
                    <img className={ classes.appBarLogoImg } src={ gridPic2 } alt=''/>
                  <CardMedia/>
                </ButtonBase>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card style={{ backgroundColor: values.data.grid === 3 ? '#5BC592' : ''  }}>
              <CardHeader title = "Grid3" />
              <Grid container alignItems="center" justify="center">
                <ButtonBase className={classes.cardAction} onClick={ handleSelectGrid(3) } >
                  <CardMedia title="Grid3"/>
                    <img className={ classes.appBarLogoImg } src={ gridPic3 } alt=''/>
                  <CardMedia/>
                </ButtonBase>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card style={{ backgroundColor: values.data.grid === 4 ? '#5BC592' : ''  }}>
              <CardHeader title = "Grid4" />
              <Grid container alignItems="center" justify="center">
                <ButtonBase className={classes.cardAction} onClick={ handleSelectGrid(4) } >
                  <CardMedia title="Grid4"/>
                    <img className={ classes.appBarLogoImg } src={ gridPic4 } alt=''/>
                  <CardMedia/>
                </ButtonBase>
              </Grid>
            </Card>
          </Grid>

        </Grid>
      </Box>
    </Paper>  

  </Box>

  );
}

const mapStateToProps = state => { 
  return {
    settingsOEMEditorForceRefresh: state.settingsOEMEditorForceRefresh,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( SettingsOEMGrid ) );