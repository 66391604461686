import { store } from '../index';
import { setLoginInfo, passChanged } from '../utils/Reducer';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// signIn
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function signIn( user, pass ) {

  const onError = error => {
    gError( 'signIn', error ); 
  }

  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/login', {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( { 'email' : user, 'password' : pass } )
  } )
  .then( response => {
    if ( response.ok ) {
        response.json().then( json => {
          store.dispatch( setLoginInfo( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// changePassword
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function changePassword( newPass ) {

  const onError = error => {
    gError( 'changePassword', error ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/login/change?authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( { 'newPassword' : newPass } )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( passChanged( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}
