import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { signIn } from './LoginRest';
import { gStyles, fieldLimits } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Avatar, Button, TextField, Link, Grid, Typography, Container, InputAdornment, IconButton } from '@material-ui/core';
import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons';

let strings=new LocalizedStrings({
	en:{
    title:'Sign In',
    textFieldEmailAddress:'Email Address',
    textFieldPassword:'Password',
    buttonSignIn:'Sign In',
    linkForgotPassword:'Forgot password?',
	}
})

function SignIn( props ) {

  const { classes } = props;

  const [ values, setValues ] = useState( { user: '', password: '', showPassword: false} );
  
  const handleChange = name => event => {
    setValues( { ...values, [name]: event.target.value } );
  };

  const onSubmitClick = ( ) => {
    signIn( values.user, values.password );
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <Container maxWidth='xs'>
      <div className={ classes.signInArea }>
        <Avatar className={ classes.signInIcon }>
          <LockOutlined/>
        </Avatar>
        <Typography component='h1' variant='h5'>{ strings.title }</Typography>
        <form className={ classes.form } noValidate>

          <TextField variant='outlined' margin='normal' required fullWidth id='email' autoFocus autoComplete='email'
            label={ strings.textFieldEmailAddress } value={ values.user } onChange={ handleChange('user') }
            inputProps={ { maxLength: fieldLimits.signIn.user.size } }/>

          <TextField variant='outlined' margin='normal' required fullWidth id='password' type={ !values.showPassword? 'password' : 'text' } autoComplete='current-password'
            label={ strings.textFieldPassword } value={ values.password } onChange={ handleChange('password') }    
            InputProps={ {
              inputProps: { maxLength: fieldLimits.signIn.password.size },
              endAdornment:
                <InputAdornment position="end">
                  <IconButton onClick={ ( ) => handleClickShowPassword( ) }>
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
            } }
          />

          <Button className={ classes.formSubmit } fullWidth variant='contained' color='secondary'
            onClick={ ( ) => onSubmitClick( ) }>{ strings.buttonSignIn }</Button>
          <Grid container>
            <Grid item xs>
              <Link href='#' variant='body2'>{ strings.linkForgotPassword }</Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default connect( null, { } )( withStyles( gStyles )( SignIn ) );