import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    CircularProgress,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination,
    Typography,
    IconButton
} from '@material-ui/core';

import TablePaginationActions from '../components/TablePaginationActions';

import { ArrowUpward, ArrowDownward } from '@material-ui/icons';

const ItemComponent = ({
    data,
    maxLength
}) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    if (data !== undefined) {
        if (!expanded) data = data.slice(0, maxLength) + (data.length > maxLength ? '...' : '')
        return (<div>
            <Typography variant='body2' style={{ wordWrap: "break-word" }}>{
                data.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))
            }</Typography>
            {data.length > maxLength &&
                (<button onClick={handleExpandClick}>
                    {expanded ? 'Show Less' : 'Show More'}
                </button>)
            }
        </div>)
    }
    else return ""
}

const TableComponent = ({
    data,
    columns,
    classes,
    rowsPerPage,
    rowsPerPageOptions,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortClick,
    history,
    renderRowKey,
    renderCellContent,
    loading,
}) => {
    const [sort, setSort] = useState(false); // FALSE FOR MORE RECENT TO OLDER, TRUE FOR OLDER TO MORE RECENT

    const from = rowsPerPage * page;
    const limit = from + rowsPerPage;
    const slicedData = data !== undefined ? data.items.slice(from, limit) : undefined;

    return (
        <div className={classes.workarea}>
            <div className={classes.tableArea}>
                {loading ?
                    (<CircularProgress className={classes.spinner} />)
                    : (
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => (
                                        <TableCell key={index}>
                                            <Typography variant='h6'>
                                                {column.label}
                                                {column.sortable && (
                                                    <IconButton onClick={() => {
                                                        handleSortClick(column);
                                                        setSort(!sort)
                                                    }
                                                    }>
                                                        {sort ? (
                                                            <ArrowUpward />
                                                        ) : (
                                                            <ArrowDownward />
                                                        )}

                                                    </IconButton>
                                                )}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {slicedData.map((item) => (
                                    <TableRow key={renderRowKey(item)}>
                                        {columns.map((column, index) => (
                                            <TableCell key={index}>
                                                {column.linkTo ? (
                                                    <Link className={classes.link} to={column.linkTo(item)}>
                                                        {renderCellContent(item, column)}
                                                    </Link>
                                                ) : (
                                                    <ItemComponent data={renderCellContent(item, column)} maxLength={30} />
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        colSpan={columns.length}
                                        count={data.items.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true }}
                                        onChangePage={(event, newPage) => handleChangePage(history, newPage)}
                                        onChangeRowsPerPage={(event) =>
                                            handleChangeRowsPerPage(history, event, page, rowsPerPage)
                                        }
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    )}
            </div>
        </div >
    );
};

export default TableComponent;
