import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { refreshCustomerTable } from '../utils/Reducer';
import { uploadCustomers } from './CustomerRest';
import { gStyles, fieldLimits } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';

import UploadCsvDialog from '../components/UploadCsvDialog';

let strings=new LocalizedStrings({
	en:{
    title: 'Upload Customers',
    licenseUnknownMsg: '{line} - Unknown license',
	}
})

function CustomerWizardUpload( props ) { 

  const checkSpecError = ( msg ) => { 
    if ( msg === 'LICENSE_NOT_FOUND' ) return strings.licenseUnknownMsg;
    return undefined;
  }

  return (
    <UploadCsvDialog
      open={ props.open }
      title={ strings.title }
      headers = { [
        { name: 'customerName', index: undefined, mandatory: true, maxLength: fieldLimits.customer.name.size },
        { name: 'customerVat', index: undefined, maxLength: fieldLimits.customer.vat.size },
        { name: 'customerAddress', index: undefined, maxLength: fieldLimits.customer.address.size },
        { name: 'contactEmail', index: undefined, maxLength: fieldLimits.customerContact.email.size },
        { name: 'contactFirstName', index: undefined, maxLength: fieldLimits.customerContact.first_name.size },
        { name: 'contactLastName', index: undefined, maxLength: fieldLimits.customerContact.last_name.size },
        { name: 'contactPhone', index: undefined, maxLength: fieldLimits.customerContact.phone.size },
        { name: 'license', index: undefined, maxLength: 48 },
      ] }
      refresh={ () => props.refreshCustomerTable() }
      uploadRest={ ( list, callback ) => uploadCustomers( list, callback ) }
      checkSpecError={ ( msg ) => checkSpecError( msg ) }
      onClose={ ( ) => props.onClose( ) }/>
  );
 }

 export default connect( null, { refreshCustomerTable } )( withStyles( gStyles )( CustomerWizardUpload ) );