import React from 'react';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';

import LicenseByEdition from './LicenseByEdition';
import FilterTable from '../filter/FilterTable';
import LicenseTable from './LicenseTable';

function LicensesDashboard( props ) {

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LicenseByEdition/>
        </Grid>
        <Grid item xs={6}>
          <FilterTable filterTableType='license'/>
        </Grid>
        <Grid item xs={12}>
          <LicenseTable/>
        </Grid> 
      </Grid>
    </Container>
  );
}

export default connect( null, { } )( withStyles( gStyles )( LicensesDashboard ) );