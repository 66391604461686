import { store } from '../index';
import { setProductFamilyList } from '../utils/Reducer';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getProductFamilies
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getProductFamilies( from, limit,search ) {

  const onError = error => {
    gError( 'getProductFamilies', error ); 
    store.dispatch( setProductFamilyList( { count:0, items:[] } ) );
  }

  store.dispatch( setProductFamilyList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/productFamilies?from=' + from + '&limit=' + limit + ( search === '' ? '' : ( '&search=' + search ) ) + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setProductFamilyList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}
