import React, { useEffect, useState }  from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getDistributorProductFamilies, deleteDistributorProductFamily, addDistributorProductFamilies } from './DistributorRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { CircularProgress, Tooltip, IconButton, Button, Typography } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Delete, AddCircle } from '@material-ui/icons';

import ProductFamilyTable from '../productFamily/ProductFamilyTable';
import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    tableHeaderName:'Product',
    tableHeaderDesignation:'Designation',

    toolTipDelete:'Removes the product',

    buttonAdd:'Add more Products',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    deleteDialogTitle: 'Remove Confirmation',
    deleteDialogText: 'Are you sure that you want to remove product \'{entity}\' from this distributor?',
    yes: 'Yes',
    no: 'No',
	}
});

function DistributorProductFamilyTable( props ) { 

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );

  const { distributorProductFamilyList, distributorProductFamilyTableForceRefresh } = props;
  const { productFamilyAssignList } = props;
  const { distributorId } = useParams();

  const { userType } = props;

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 10 : urlRowsPerPage;

  useEffect(
    ( ) => { 
      getDistributorProductFamilies( distributorId, page * rowsPerPage, rowsPerPage );
   },
   [ distributorId, page, rowsPerPage, distributorProductFamilyTableForceRefresh ]
  );
  
  const handleAdd = () => {
    setEditMode( true );
  };

  const handleCancel = () => {
    setEditMode( false );
  };

  const handleConfirm = ( ) => {
    addDistributorProductFamilies( distributorId, productFamilyAssignList );
    setEditMode( false );
  };

  const handleDeleteDialogClose = confirm => {
    if ( confirm )
      deleteDistributorProductFamily( distributorId, entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleDelete = productFamily => {
    setEntityToDelete( productFamily );
  };

  return (
    <div>
      <div className={ classes.workarea }>
        <div className={ classes.flexHeader }>
          { userType === 0 &&
          <Button variant="contained" color="secondary" onClick={ ( ) => handleAdd( ) }>
            <AddCircle className={ classes.leftIcon } />
            <Typography variant='button'>{ strings.buttonAdd }</Typography>
          </Button>
          }
        </div>
        <div className={ classes.tableArea } >
          { distributorProductFamilyList !== undefined &&
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderName }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderDesignation }</Typography>
                  </TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                { distributorProductFamilyList.items.map( productFamily => (
                  <TableRow key={ productFamily.productfamily_id }> 
                    <TableCell>
                      <Typography variant='body2'>{ productFamily.name }</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ productFamily.designation }</Typography>
                    </TableCell>
                    { userType === 0 &&
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipDelete }>
                        <IconButton size='small' onClick={ ( ) => handleDelete( productFamily ) } >
                          <Delete/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    }
                  </TableRow>
                )) }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 3 } count={ distributorProductFamilyList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }/>
                </TableRow>
              </TableFooter>
            </Table>
          }
          { distributorProductFamilyList === undefined &&
              <CircularProgress className={ classes.spinner }/>
          }
        </div>
      </div>
      <Dialog maxWidth='lg' open={ editMode }>
        <div className={ classes.workarea }>
          <ProductFamilyTable assignMode={ true }/>
          <div className={ classes.lineButtonBox }>
            <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleConfirm() }>{ strings.buttonConfirm }</Button>
            <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleCancel() }>{ strings.buttonCancel }</Button>
          </div>
        </div>
      </Dialog>
      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText.replace( '{entity}', entityToDelete !== undefined ? entityToDelete.name : '' ) }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
 }

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    distributorProductFamilyList: state.distributorProductFamilyList,
    distributorProductFamilyTableForceRefresh: state.distributorProductFamilyTableForceRefresh,
    productFamilyAssignList: state.productFamilyAssignList
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( DistributorProductFamilyTable ) );