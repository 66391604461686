import React, { useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import { Switch, Route, Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Paper, AppBar, Toolbar, Tabs, Tab, Button, Grid } from '@material-ui/core';
import { getSettingsOEMTheme, setSettingsOEMTheme } from './SettingsRest';

import SettingsOEMGrid from './SettingsOEMGrid';
import SettingsOEMTheme from './SettingsOEMTheme';
import SettingsOEMEditions from './SettingsOEMEditions';
import SettingsOEMUpgrades from './SettingsOEMUpgrades';
import SettingsOEMVariables from './SettingsOEMVariables';


let strings=new LocalizedStrings({
	en:{
    title:'MY OEM CUSTOMIZATION',

    tabGrid:'DEFAULT GRID',
    tabTheme:'APP CUSTOM THEME',
    tabEditions:'EDITIONS',
    tabUpgrades:'UPGRADES',
    tabVariables:'VARIABLES',

	}
})


function SettingsOEMDashboard( props ) {

  const { classes } = props;
  const { oemId } = useParams();
  const { settingsOEMThemeValues } = props;

  useEffect(
    () => {
      getSettingsOEMTheme( oemId );
    },
    [  ]
  );

  const handleSave = ( ) => {
    setSettingsOEMTheme( settingsOEMThemeValues );
  };

  return (
      <Paper className={ classes.selectedItemPaper }>
        <Grid container justify="flex-end">
          <Button className={ classes.lineButton } onClick={ ( ) => handleSave( ) } variant='contained' color='secondary'> save </Button>
          <Button className={ classes.lineButton } variant='contained' color='secondary'> build </Button> 
        </Grid>

        <AppBar className={ classes.selectedItemAppBar } position='static'>
          <Toolbar variant="dense">
            <Route
              render={({ location }) => ( 
                <Tabs value={ location.pathname.split('/')[3] }>
                  <Tab label={ strings.tabGrid } component={ Link } value='grid' to={ '/myOEM/' + oemId + '/grid' }/>
                  <Tab label={ strings.tabTheme }  component={ Link } value='theme' to={ '/myOEM/' + oemId + '/theme' }/>
                  <Tab label={ strings.tabEditions }  component={ Link } value='editions' to={ '/myOEM/' + oemId + '/editions' }/>
                  <Tab label={ strings.tabUpgrades }  component={ Link } value='upgrades' to={ '/myOEM/' + oemId + '/upgrades' }/>
                  <Tab label={ strings.tabVariables }  component={ Link } value='variables' to={ '/myOEM/' + oemId + '/variables' }/>
                </Tabs>
            ) } />
          </Toolbar>
        </AppBar>
        <Switch>
          <Route path='/myOEM/:oemId/grid'><SettingsOEMGrid/></Route>
          <Route path='/myOEM/:oemId/theme'><SettingsOEMTheme/></Route>
          <Route path='/myOEM/:oemId/editions'><SettingsOEMEditions/></Route>
          <Route path='/myOEM/:oemId/upgrades'><SettingsOEMUpgrades/></Route>
          <Route path='/myOEM/:oemId/variables'><SettingsOEMVariables/></Route>
        </Switch>        
      </Paper>

  );
}

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( SettingsOEMDashboard ) );