import React from 'react';
import { Switch, Route, Link, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Paper, AppBar, Toolbar, Typography, Tabs, Tab, IconButton } from '@material-ui/core';
import  { Close } from '@material-ui/icons';

import LicenseDetail from './LicenseDetail';
import LicensePrinterTable from './LicensePrinterTable';
import LicenseHistoryTable from './LicenseHistoryTable';

let strings=new LocalizedStrings({
	en:{
    tabDetails:'Details',
    tabPrinters:'Printers',
    tabHistory:'History',
	}
})

function LicenseDetailDashboard( props ) {

  const { classes } = props;

  const { licenseId } = useParams();

  return (
      <Paper className={ classes.selectedItemPaper }>
        <Toolbar variant="dense"> 
          <Typography className={ classes.flexExpand } variant='h6'>{ licenseId }</Typography>
          <IconButton component={ Link } to='/licenses'>
            <Close />
          </IconButton>
        </Toolbar>
        <AppBar className={ classes.selectedItemAppBar } position='static'>
          <Toolbar variant="dense">
            <Route
              render={({ location }) => ( 
                <Tabs value={ location.pathname.split('/')[3] }>  
                  <Tab label={ strings.tabDetails } component={ Link } value='details' to={ '/licenses/' + licenseId + '/details' }/>
                  <Tab label={ strings.tabPrinters } component={ Link } value='printers'  to={ '/licenses/' + licenseId + '/printers' }/>
                  <Tab label={ strings.tabHistory } component={ Link } value='history'  to={ '/licenses/' + licenseId + '/history' }/>
                </Tabs>
            ) } />
          </Toolbar>
        </AppBar>
        <Switch>
          <Route path='/licenses/:licenseId/details'><LicenseDetail/></Route>
          <Route path='/licenses/:licenseId/printers'><LicensePrinterTable/></Route>
          <Route path='/licenses/:licenseId/history'><LicenseHistoryTable/></Route>
        </Switch>           
      </Paper>

  );
}

export default connect( null, { } )( withStyles( gStyles )( LicenseDetailDashboard ) );