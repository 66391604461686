import { store } from '../index';
import { setPrinterList, setPrinterSelected, setPrinterModelList, setPrinterStatusList, refreshPrinterTable } from '../utils/Reducer';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';
import { colorArray } from '../utils/Styles';



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPrinters
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPrinters( from, limit, search, filterId ) {

  const onError = error => {
    gError( 'getPrinters', error );
    store.dispatch( setPrinterList( { count:0, items:[] } ) );
  }
  
  store.dispatch( setPrinterList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/printers?from=' + from + '&limit=' + limit + '&filterId=' + filterId + ( search === '' ? '' : ( '&search=' + search ) ) + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setPrinterList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPrinter
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPrinter( printerId ) {

  const onError = error => {
    gError( 'getPrinter', error );
    store.dispatch( setPrinterSelected( undefined ) );
  }

  store.dispatch( setPrinterSelected( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/printers/' + printerId + '?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setPrinterSelected( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPrinterStatus
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPrinterStatus( printerId ) {

  const onError = error => {
    gError( 'getPrinterStatus', error );
    store.dispatch( setPrinterStatusList( { cardCount: [], ribbons: [] } ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/printers/' + printerId + '/status?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        var processedList = { cardCount: [], ribbons: [] };
        //DESC
        //json = [
        //  { status_id:16, create_date:1000000028, status:"{"cardCount":"5020","ribbonRemain":"300","ribbonQty":"1000","ribbonType":"k White","transferRibbonRemain":"250","transferRibbonQty":"500","transferRibbonType":"k Transfer" }" },
        //  { status_id:15, create_date:1000000027, status:"{"cardCount":"5010","ribbonRemain":"977","ribbonQty":"1000","ribbonType":"k White (RCT015NAA)"}" },
        //  { status_id:14, create_date:1000000026, status:"{"cardCount":"5000","ribbonRemain":"967","ribbonQty":"1000","ribbonType":"k White (RCT015NAA)"}" },
        //]
        json.forEach( item => {
          var status = JSON.parse( item.status );
          processedList.cardCount.push( { create_date: item.create_date, value: status.cardCount } );

          //primary ribbon
          if ( status.ribbonType !== undefined ) {
            var ribbon = processedList.ribbons.find( ribbonItem => { return ribbonItem.type === status.ribbonType; } );
            if ( ribbon === undefined ) {
              ribbon = { type: status.ribbonType, qty: status.ribbonQty, remain: status.ribbonRemain, lastUsageDate: item.create_date, status: [] };
              processedList.ribbons.push( ribbon );
            }
            ribbon.status.push( { create_date: item.create_date, value: status.ribbonRemain } );
          }
          //secondary ribbon
          if ( status.ribbon2Type !== undefined ) {
            ribbon = processedList.ribbons.find( ribbonItem => { return ribbonItem.type === status.ribbon2Type; } );
            if ( ribbon === undefined ) {
              ribbon = { type: status.ribbon2Type, qty: status.ribbon2Qty, remain: status.ribbon2Remain, lastUsageDate: item.create_date, status: [] };
              processedList.ribbons.push( ribbon );
            }
            ribbon.status.push( { create_date: item.create_date, value: status.ribbon2Remain } );
          }

          //transferRibbon
          if ( status.transferRibbonType !== undefined ) {
            ribbon = processedList.ribbons.find( ribbonItem => { return ribbonItem.type === status.transferRibbonType; } );
            if ( ribbon === undefined ) {
              ribbon = { type: status.transferRibbonType, qty: status.transferRibbonQty, remain: status.transferRibbonRemain, lastUsageDate: item.create_date, status: [] };
              processedList.ribbons.push( ribbon );
            }
            ribbon.status.push( { create_date: item.create_date, value: status.transferRibbonRemain } );
          }
        } );
        //processedList = {
        //  cardCount: [ { create_date:1000000028, value:'5020' }, { create_date:1000000027, value:'5010' }, { create_date:1000000026, value:'5000' } ],
        //  ribbons: [
        //    { type:'k White (RCT015NAA)', qty:'1000', remain:'300', lastUsageDate:1000000027 , status:[ { create_date:1000000027, value:977 }, { create_date:1000000026, value:967 } ] },
        //    { type:'k White', qty:'1000', remain:'300', lastUsageDate:1000000028 , status:[ { create_date:1000000028, value:300 } ] },
        //    { type:'k Transfer', qty:'500', remain:'250', lastUsageDate:1000000028 , status:[ { create_date:1000000028, value:250 } ] },
        //  ]
        //}
        store.dispatch( setPrinterStatusList( processedList ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getPrinterModels
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getPrinterModels( ) {

  const onError = error => {
    gError( 'getPrinterModels', error );
    store.dispatch( setPrinterModelList( defaultData ) );
  }

  const defaultData = { labels: [ ],
    datasets: [ { 
      data: [],
      backgroundColor: colorArray,
    } ]
  };

  store.dispatch( setPrinterModelList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/printers/models?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        var printerModelList = { labels: json.map( model => ( model.model ) ),
          datasets: [ { 
            data: json.map( model => ( model.count ) ),
            backgroundColor: defaultData.datasets[0].backgroundColor,
          } ]
        };
        store.dispatch( setPrinterModelList( printerModelList ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// checkForPrinter
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function checkForPrinter( serialNumber, callback ) {

  const onError = error => {
    callback( false );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/printers/' + serialNumber + '/check?authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      callback( true );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// assignPrinter
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function assignPrinter( serialNumber, callback ) {

  const onError = error => {
    callback( false );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/printers/' + serialNumber + '/assign?authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      callback( true );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deletePrinter
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deletePrinter( printer ) {

  const onError = error => {
    gError( 'deletePrinter', error ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/printers/' + printer.serial_number + '?authToken=' + authToken, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshPrinterTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// uploadPrinters
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function uploadPrinters( printers, callback ) {

  const onError = error => {
    gError( 'uploadPrinter', error ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/printers/upload?authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body: JSON.stringify( printers ),
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        callback( json );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}
