import React from 'react';
import { Switch, Route, Link, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Paper, AppBar, Toolbar, Typography, Tabs, Tab, IconButton } from '@material-ui/core';
import  { Close } from '@material-ui/icons';

import PrinterDetail from './PrinterDetail';
import PrinterRibbonTable from './PrinterRibbonTable';
import PrinterUsage from './PrinterUsage';

let strings=new LocalizedStrings({
	en:{
    tabDetails:'Details',
    tabRibbons:'Ribbons',
    tabUsage:'Printer & Ribbon usage',
	}
})

function PrinterDetailDashboard( props ) {

  const { classes } = props;

  const { printerId } = useParams();

  return (
      <Paper className={ classes.selectedItemPaper }>
        <Toolbar variant="dense"> 
          <Typography className={ classes.flexExpand } variant='h6'>{ printerId }</Typography>
          <IconButton component={ Link } to='/printers'>
            <Close />
          </IconButton>
        </Toolbar>
        <AppBar className={ classes.selectedItemAppBar } position='static'>
          <Toolbar variant="dense">
            <Route
              render={({ location }) => ( 
                <Tabs value={ location.pathname.split('/')[3] } >
                  <Tab label={ strings.tabDetails } component={ Link } value='details' to={ '/printers/' + printerId + '/details' }/>
                  <Tab label={ strings.tabRibbons } component={ Link } value='ribbons' to={ '/printers/' + printerId + '/ribbons' }/>
                  <Tab label={ strings.tabUsage } component={ Link } value='usage' to={ '/printers/' + printerId + '/usage' }/>
                </Tabs>
            ) } />
          </Toolbar>
        </AppBar>
        <Switch>
          <Route path='/printers/:printerId/details'><PrinterDetail/></Route>
          <Route path='/printers/:printerId/ribbons'><PrinterRibbonTable/></Route>
          <Route path='/printers/:printerId/usage'><PrinterUsage/></Route>
        </Switch>          
      </Paper>

  );
}


export default connect( null, { } )( withStyles( gStyles )( PrinterDetailDashboard ) );