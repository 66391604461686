import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';

import SettingsNotifications from './SettingsNotifications';
import SettingsWhatsNew from './SettingsWhatsNew';
import SettingsLearning from './SettingsLearning';
import SettingsOEMDetailDashboard from './SettingsOEMDetailDashboard';

function SettingsDashboard( props ) {

  return (
    <Container maxWidth={false}>

      <Grid container spacing={2}>
  
        <Grid item xs={12}>
          <Switch>
            <Route path='/settings/notifications'><SettingsNotifications/></Route>
            <Route path='/settings/whatsnew'><SettingsWhatsNew/></Route>
            <Route path='/settings/learning'><SettingsLearning/></Route>
            <Route path='/settings/dashboard'><SettingsOEMDetailDashboard/></Route>
          </Switch>
        </Grid>

      </Grid>

    </Container>
  );
}

export default connect( null, { } )( withStyles( gStyles )( SettingsDashboard ) );
