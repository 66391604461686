import React, { useEffect, useState, useRef }  from 'react';
import LocalizedStrings from 'react-localization';
import { connect, useDispatch } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, Typography, Button, CircularProgress, GridList, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { SketchPicker } from 'react-color';

import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import DropzonePic from '../components/DropzonePic';



let strings=new LocalizedStrings({
	en:{
    title:'MY OEM CUSTOMIZATION',

    softwareLogo:'Software Logo',
    softwareIcon:'Software Icon',
    backgroundImage:'Background Image',

    labelBackgroundColor: 'Background Colors',
    labelPickColor: 'Select color',
    labelTextColor: 'Text Colors',
    labelDefault: 'Default',
    labelPrimary: 'Primary',
    labelSecondary: 'Secondary',
    labelSuccess: 'Success',
    labelError: 'Error',
    labelWarning: 'Warning',
    labelInfo: 'Info',
    labelButtonStates: 'Buttons (States)',
    labelNormalFill: 'Normal - Fill:',
    labelNormalOutline: 'Normal - Outline:',
    labelNormalIcon: 'Normal - Icon Color:',
    labelNormalText: 'Normal - Text Color:',  
    labelSelectedFill: 'Selected/Mouseover - Fill:',
    labelSelectedOutline: 'Selected/Mouseover - Outline:',
    labelSelectedIcon: 'Selected/Mouseover - Icon Color:',
    labelSelectedText: 'Selected/Mouseover - Text Color:',
    labelDisabledFill: 'Disabled - Fill:',
    labelDisabledOutline: 'Disabled - Outline:',
    labelDisabledIcon: 'Disabled - Icon Color:',
    labelDisabledText: 'Disabled - Text Color:',  
    

    confirm: 'Confirm',

	}
})


function SettingsOEMEditor( props ) {

  const { classes } = props;
  const { settingsOEMEditorForceRefresh } = props;
  const { settingsOEMThemeValues } = props;

  const [ values, setValues ] = useState( { valid: false, oem_id: 0, logo: '', icon: '', background: '', data:{} } );
  const [ colorToEdit, setColorToEdit ] = useState( {value: '', color: ''} );

  const dispatch = useDispatch();
  const fileUploader = useRef(null);

  useEffect(
    () => {
    },
    [ settingsOEMEditorForceRefresh, settingsOEMThemeValues ]
  );

  if ( settingsOEMThemeValues !== undefined && !values.valid ) {   
    setValues( {
      valid: true,
      oem_id: settingsOEMThemeValues.productfamily_id,
      logo: settingsOEMThemeValues.logo,
      icon: settingsOEMThemeValues.icon,
      background: settingsOEMThemeValues.background,
      data:{
        background_color_default: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.background_color_default,
        background_color_primary: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.background_color_primary,
        background_color_secondary: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.background_color_secondary,
        text_color_default: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.text_color_default,
        text_color_primary: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.text_color_primary,
        text_color_secondary: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.text_color_secondary,
        text_color_success: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.text_color_success,
        text_color_error: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.text_color_error,
        text_color_warning: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.text_color_warning,
        text_color_info: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.text_color_info,
        button_normal_fill: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_normal_fill,
        button_normal_outline: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_normal_outline,
        button_normal_icon: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_normal_icon,
        button_normal_text: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_normal_text,
        button_selected_fill: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_selected_fill,
        button_selected_outline: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_selected_outline,
        button_selected_icon: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_selected_icon,
        button_selected_text: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_selected_text,
        button_disabled_fill: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_disabled_fill,
        button_disabled_outline: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_normal_outline,
        button_disabled_icon: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_disabled_icon,
        button_disabled_text: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.button_disabled_text,
      }   ,

    } );
  }

  const startNewFile = ( file, name ) => {

    switch ( name ) {
      case 'logo': {
        var reader = new FileReader();
        reader.onload = () => {
          setValues( { ...values, logo: reader.result } );
        };
        reader.readAsDataURL(file);
        break;
      }
      case 'icon': {
        var reader = new FileReader();
        reader.onload = () => {
          setValues( { ...values, icon: reader.result } );
        };
        reader.readAsDataURL(file);
        break;
      }
      case 'background': {
        var reader = new FileReader();
        reader.onload = () => {
          setValues( { ...values, background: reader.result } );
        };
        reader.readAsDataURL(file);        
        break;
      }

      default:
        break;
    }

  }

  const  handleChange = name => event => {

    var reader = new FileReader();
    reader.onload = () => {
      setValues( { ...values, [name]: reader.result } );
    };
    reader.readAsDataURL(event.target.files[0]);

    /*switch ( name ) {
      case 'logo': {
        var reader = new FileReader();
        reader.onload = () => {
          setValues( { ...values, logo: reader.result } );
        };
        reader.readAsDataURL(event.target.files[0]);
        break;
      }
      case 'icon': {
        var reader = new FileReader();
        reader.onload = () => {
          setValues( { ...values, icon: reader.result } );
        };
        reader.readAsDataURL(event.target.files[0]);
        break;
      }
      case 'background': {
        var reader = new FileReader();
        reader.onload = () => {
          setValues( { ...values, background: reader.result } );
        };
        reader.readAsDataURL(event.target.files[0]);        
        break;
      }

      default:
        break;
    }*/
  };

  const handleChangeColor = name => event => {
    if( event.target !== undefined)
      setValues( { ...values, data: { ...values.data , [name]: event.target.value} } );
    else{
      setValues( { ...values, data: { ...values.data , [name]: event.hex} } );
      setColorToEdit( { ...colorToEdit, value: event.hex } );
      dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues , data: { ...settingsOEMThemeValues.data , [name]: event.hex} } });
    }  
  };

  const handleBlur = name => event => {
    setValues( { ...values, data: { ...values.data , [name]: event.target.value} } );
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues , data: { ...settingsOEMThemeValues.data , [name]: event.target.value} } });

  };

  const onPictureEdit = ( event, name ) => {

    switch ( name ) {
      case 'logo': {
        var reader = new FileReader();
        reader.onload = () => {
          setValues( { ...values, logo: reader.result } );
        };
        reader.readAsDataURL(event.target.files[0]);
        break;
      }
      case 'icon': {
        var reader = new FileReader();
        reader.onload = () => {
          setValues( { ...values, icon: reader.result } );
        };
        reader.readAsDataURL(event.target.files[0]);
        break;
      }
    }
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  const handleEditColor = (value, color) => {
    setColorToEdit({value: value, color: color});
  }

  const handleEditColorClose = confirm => {
    setColorToEdit( {value: ''} );
  }

  return (
    <Box >
        { settingsOEMThemeValues !== undefined && 
          <Box className={ classes.workarea }>
            <form className={ classes.form } autoComplete="off">
                <GridList className={ classes.workareaEditor } container spacing={20} cellHeight={'auto'}>
                  
                  { values.logo === '' &&
                  <Grid item xs={12}>
                    <FormControl variant='outlined'>
                    <FormLabel><Typography color='secondary'>{ strings.softwareLogo }</Typography></FormLabel>
                      <DropzonePic onFileAdded={ ( file ) => { startNewFile( file, 'logo' ) } } />
                    </FormControl>
                  </Grid>
                  }


                  { values.logo !== '' &&
                    <Grid item xs={12}>
                      <FormControl variant='outlined' onClick={ handleEditPicClick }>
                        <FormLabel><Typography color='secondary'>{ strings.softwareLogo }</Typography></FormLabel>
                          <img src={values.logo} height={ 160 } width={ 160 } alt=""/>
                          <input type='file' accept='.png'  ref= { fileUploader } style={{display: "none"}} onChange={ handleChange( 'logo' ) }/>
                      </FormControl>
                    </Grid>
                  }


                  { values.icon === '' &&
                    <Grid item xs={6}>
                      <FormControl variant='outlined'>
                      <FormLabel><Typography color='secondary'>{ strings.softwareIcon }</Typography></FormLabel>
                        <DropzonePic onFileAdded={ ( file ) => { startNewFile( file, 'icon' ) } } />
                      </FormControl>
                    </Grid>
                  }


                  { values.icon !== '' &&
                    <Grid item xs={6}>
                      <FormControl variant='outlined' onClick={ handleEditPicClick }>
                        <FormLabel><Typography color='secondary'>{ strings.softwareIcon }</Typography></FormLabel>
                          <img src={values.icon} height={ 160 } width={ 160 } alt=""/>
                          <input type='file' accept='.png'  ref= { fileUploader } style={{display: "none"}} onChange={ ( file ) => { onPictureEdit( file, 'icon' ) }}/>
                      </FormControl>
                    </Grid>
                  }

                  { values.background === '' &&
                    <Grid item xs={6}>
                      <FormControl variant='outlined'>
                      <FormLabel><Typography color='secondary'>{ strings.backgroundImage }</Typography></FormLabel>
                        <DropzonePic onFileAdded={ ( file ) => { startNewFile( file, 'background' ) } } />
                      </FormControl>
                    </Grid>
                  }


                  { values.background !== '' &&
                    <Grid item xs={6}>
                      <FormControl variant='outlined' onClick={ handleEditPicClick }>
                        <FormLabel><Typography color='secondary'>{ strings.backgroundImage }</Typography></FormLabel>
                          <img src={values.background} height={ 160 } width={ 160 } alt=""/>
                          <input type='file' accept='.png'  ref= { fileUploader } style={{display: "none"}} onChange={ ( file ) => { handleChange( file, 'background' ) }}/>
                      </FormControl>
                    </Grid>
                  }
                  
                  <Grid item xs={12}>
                    <Typography color='secondary'>{ strings.labelBackgroundColor }</Typography>
                  </Grid>

                  <Grid item xs={12} container spacing={2} >
                      <Grid item xs={6} container spacing={2} >
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelDefault }</Typography>    
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelPrimary }</Typography>               
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelSecondary }</Typography>               
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>

                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor }  value={ values.data.background_color_default } onChange={handleChangeColor('background_color_default')} onBlur={ handleBlur('background_color_default') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.background_color_default }} onClick={ ( ) => handleEditColor( values.data.background_color_default, 'background_color_default' ) } variant='contained'/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor } value={ values.data.background_color_primary } onChange={handleChangeColor('background_color_primary')}  onBlur={ handleBlur('background_color_primary') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.background_color_primary }} onClick={ ( ) => handleEditColor( values.data.background_color_primary, 'background_color_primary' ) } variant='contained'/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor } value={ values.data.background_color_secondary } onChange={handleChangeColor('background_color_secondary')} onBlur={ handleBlur('background_color_secondary') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.background_color_secondary }} onClick={ ( ) => handleEditColor( values.data.background_color_secondary, 'background_color_secondary' ) } variant='contained'/>
                        </Grid>                       

                      </Grid>                    
                      
                  </Grid>


                  <Grid item xs={12}>
                    <Typography color='secondary'>{ strings.labelTextColor }</Typography>
                  </Grid>

                  <Grid item xs={12} container spacing={2} >
                      <Grid item xs={6} container spacing={2}  >
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelDefault }</Typography>    
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelPrimary }</Typography>               
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelSecondary }</Typography>               
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelSuccess }</Typography>               
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelError }</Typography>               
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelWarning }</Typography>               
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color='primary'>{ strings.labelInfo }</Typography>               
                        </Grid>
                      </Grid>

                      <Grid item xs={6} container spacing={2}>

                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor } value={ values.data.text_color_default } onChange={handleChangeColor('text_color_default')} onBlur={ handleBlur('text_color_default') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_default }} onClick={ ( ) => handleEditColor( values.data.text_color_default, 'text_color_default' ) } variant='contained'/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor } value={ values.data.text_color_primary } onChange={handleChangeColor('text_color_primary')} onBlur={ handleBlur('text_color_primary') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_primary }} onClick={ ( ) => handleEditColor( values.data.text_color_primary, 'text_color_primary' ) } variant='contained'/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor } value={ values.data.text_color_secondary } onChange={handleChangeColor('text_color_secondary')} onBlur={ handleBlur('text_color_secondary') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_secondary }} onClick={ ( ) => handleEditColor( values.data.text_color_secondary, 'text_color_secondary' ) } variant='contained'/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor } value={ values.data.text_color_success } onChange={handleChangeColor('text_color_success')} onBlur={ handleBlur('text_color_success') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_success }} onClick={ ( ) => handleEditColor( values.data.text_color_success, 'text_color_success' ) } variant='contained'/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor } value={ values.data.text_color_error } onChange={handleChangeColor('text_color_error')} onBlur={ handleBlur('text_color_error') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_error }} onClick={ ( ) => handleEditColor( values.data.text_color_error, 'text_color_error' ) } variant='contained'/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor } value={ values.data.text_color_warning } onChange={handleChangeColor('text_color_warning')} onBlur={ handleBlur('text_color_warning') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_warning }} onClick={ ( ) => handleEditColor( values.data.text_color_warning, 'text_color_warning' ) } variant='contained'/>
                        </Grid>


                        <Grid item xs={12}>
                          <TextField className={ classes.lineEditor } value={ values.data.text_color_info } onChange={handleChangeColor('text_color_info')} onBlur={ handleBlur('text_color_info') }/>
                          <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.text_color_info }} onClick={ ( ) => handleEditColor( values.data.text_color_info, 'text_color_info' ) } variant='contained'/>
                        </Grid>

                      </Grid>
                    
                      
                  </Grid>

                  <Grid item xs={12}>
                    <Typography color='secondary'>{ strings.labelButtonStates }</Typography>
                  </Grid>

                  <Grid item xs={12} container spacing={2} >
                      <Grid item xs={12} container spacing={2}  >
                        <Grid item xs={6}  container spacing={1}>
                          <Typography color='primary'>{ strings.labelNormalFill }</Typography>   

                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_normal_fill } onChange={handleChangeColor('button_normal_fill')} onBlur={ handleBlur('button_normal_fill') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_fill }} onClick={ ( ) => handleEditColor( values.data.button_normal_fill, 'button_normal_fill' ) } variant='contained'/>
                          </Grid>
                         
                        </Grid>
                        <Grid item xs={6} container spacing={1}>
                          <Typography color='primary'>{ strings.labelNormalOutline }</Typography>  
                          
                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_normal_outline } onChange={handleChangeColor('button_normal_outline')} onBlur={ handleBlur('button_normal_outline') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_outline }} onClick={ ( ) => handleEditColor( values.data.button_normal_outline, 'button_normal_outline' ) } variant='contained'/>
                          </Grid>
            
                        </Grid>
                        <Grid item xs={6} container spacing={1}>
                          <Typography color='primary'>{ strings.labelNormalIcon }</Typography>   
                          
                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_normal_icon } onChange={handleChangeColor('button_normal_icon')} onBlur={ handleBlur('button_normal_icon') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_icon }} onClick={ ( ) => handleEditColor( values.data.button_normal_icon, 'button_normal_icon' ) } variant='contained'/>
                          </Grid>
           
                        </Grid>
                        <Grid item xs={6} container spacing={1}>
                          <Typography color='primary'>{ strings.labelNormalText }</Typography> 
                          
                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_normal_text } onChange={handleChangeColor('button_normal_text')} onBlur={ handleBlur('button_normal_text') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_normal_text }} onClick={ ( ) => handleEditColor( values.data.button_normal_text, 'button_normal_text' ) } variant='contained'/>
                          </Grid>
             
                        </Grid>
                        <Grid item xs={12}>
                          <Button style={{ backgroundColor: values.data.button_normal_fill, borderColor: values.data.button_normal_outline }} variant="contained" startIcon={<HomeIcon style={{ color: values.data.button_normal_icon}} />} />
                        </Grid>
                        
                      </Grid>

                      <Grid item xs={6} container spacing={2}>
                          <Typography color='primary'>{ strings.labelSelectedFill }</Typography>   

                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_selected_fill } onChange={handleChangeColor('button_selected_fill')} onBlur={ handleBlur('button_selected_fill') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_fill }} onClick={ ( ) => handleEditColor( values.data.button_selected_fill, 'button_selected_fill' ) } variant='contained'/>
                          </Grid>

                        </Grid>
                        <Grid item xs={6} container spacing={2}>
                          <Typography color='primary'>{ strings.labelSelectedOutline }</Typography>  
                          
                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_selected_outline } onChange={handleChangeColor('button_selected_outline')} onBlur={ handleBlur('button_selected_outline') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_outline }} onClick={ ( ) => handleEditColor( values.data.button_selected_outline, 'button_selected_outline' ) } variant='contained'/>
                          </Grid>
            
                        </Grid>
                        <Grid item xs={6} container spacing={2}>
                          <Typography color='primary'>{ strings.labelSelectedIcon }</Typography>   
                          
                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_selected_icon } onChange={handleChangeColor('button_selected_icon')} onBlur={ handleBlur('button_selected_icon') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_icon }} onClick={ ( ) => handleEditColor( values.data.button_selected_icon, 'button_selected_icon' ) } variant='contained'/>
                          </Grid>
         
                        </Grid>
                        <Grid item xs={6} container spacing={2}>
                          <Typography color='primary'>{ strings.labelSelectedText }</Typography> 
                          
                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_selected_text } onChange={handleChangeColor('button_selected_text')} onBlur={ handleBlur('button_selected_text') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_selected_text }} onClick={ ( ) => handleEditColor( values.data.button_selected_text, 'button_selected_text' ) } variant='contained'/>
                          </Grid>
              
                        </Grid>
                        <Grid item xs={12}>
                          <Button style={{ backgroundColor: values.data.button_selected_fill, borderColor: values.data.button_selected_outline }} variant="contained" startIcon={<HomeOutlinedIcon style={{ color: values.data.button_selected_icon}} />} />
                        </Grid>

                        <Grid item xs={6} container spacing={2}>
                          <Typography color='primary'>{ strings.labelDisabledFill }</Typography>   

                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_disabled_fill } onChange={handleChangeColor('button_disabled_fill')} onBlur={ handleBlur('button_disabled_fill') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_fill }} onClick={ ( ) => handleEditColor( values.data.button_disabled_fill, 'button_disabled_fill' ) } variant='contained'/>
                          </Grid>

                        </Grid>
                        <Grid item xs={6} container spacing={2}>
                          <Typography color='primary'>{ strings.labelDisabledOutline }</Typography>  
                          
                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_disabled_outline } onChange={handleChangeColor('button_disabled_outline')} onBlur={ handleBlur('button_disabled_outline') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_outline }} onClick={ ( ) => handleEditColor( values.data.button_disabled_outline, 'button_disabled_outline' ) } variant='contained'/>
                          </Grid>
          
                        </Grid>
                        <Grid item xs={6} container spacing={2}>
                          <Typography color='primary'>{ strings.labelDisabledIcon }</Typography>   
                          
                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_disabled_icon } onChange={handleChangeColor('button_disabled_icon')} onBlur={ handleBlur('button_disabled_icon') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_icon }} onClick={ ( ) => handleEditColor( values.data.button_disabled_icon, 'button_disabled_icon' ) } variant='contained'/>
                          </Grid>
         
                        </Grid>
                        <Grid item xs={6} container spacing={2}>
                          <Typography color='primary'>{ strings.labelDisabledText }</Typography> 
                          
                          <Grid item xs={12}>
                            <TextField className={ classes.lineEditor } value={ values.data.button_disabled_text } onChange={handleChangeColor('button_disabled_text')} onBlur={ handleBlur('button_disabled_text') }/>
                            <Button style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', backgroundColor: values.data.button_disabled_text }} onClick={ ( ) => handleEditColor( values.data.button_disabled_text, 'button_disabled_text' ) } variant='contained'/>
                          </Grid>
            
                        </Grid>
                        <Grid item xs={12}>
                          <Button style={{ backgroundColor: values.data.button_disabled_fill, borderColor: values.data.button_disabled_outline }} variant="contained" disabled startIcon={<HomeIcon style={{ color: values.data.button_disabled_icon}} />} />
                        </Grid>

                  </Grid>
                
                          
                </GridList>
              </form>
            </Box>
          }
          { settingsOEMThemeValues === undefined &&
            <CircularProgress className={ classes.spinner }/>
          }

       <Dialog open={ colorToEdit.value !== '' } onClose={ ( ) => handleEditColorClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.labelPickColor }</DialogTitle>
        <DialogContent>
          <SketchPicker  
            color={ colorToEdit.value }
            onChangeComplete={handleChangeColor( colorToEdit.color )}
          />
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleEditColorClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.confirm }</Button>
        </DialogActions>
      </Dialog>

    </Box>
      

  );
}

const mapStateToProps = state => { 
  return {
    settingsOEMEditorForceRefresh: state.settingsOEMEditorForceRefresh,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
  }
};

export default connect( mapStateToProps )( withStyles( gStyles )( SettingsOEMEditor ) );