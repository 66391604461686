import React, { useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getLicensePrinters } from './LicenseRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import TableComponent from './TableComponent';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

function LicensePrinterTable(props) {

  const { classes } = props;

  const { licensePrinterList } = props;
  const { licenseId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams(history.location.search);
  const page = Number(searchParams.get('page'));
  var urlRowsPerPage = Number(searchParams.get('rows'));
  const rowsPerPage = (urlRowsPerPage === undefined) ? 10 : urlRowsPerPage;

  useEffect(
    () => {
      getLicensePrinters(licenseId, page * rowsPerPage, rowsPerPage);
    },
    [licenseId, page, rowsPerPage]
  );

  const columns = [
    {
      label: 'Serial Number',
      linkTo: (item) => `/printers/${item.serial_number}/details`,
      renderContent: (item) => <Typography variant='body2'>{item.serial_number}</Typography>,
    },
    {
      label: 'Manufacturer',
      renderContent: (item) => <Typography variant='body2'>{item.manufacturer}</Typography>,
    },
    {
      label: 'Model',
      renderContent: (item) => <Typography variant='body2'>{item.model}</Typography>,
    },
  ];

  // Render function to provide the unique key for each row
  const renderRowKey = (item) => item.serial_number;

  const renderCellContent = (item, column) => column.renderContent(item);

  return (
    <TableComponent
      data={licensePrinterList}
      columns={columns}
      classes={classes}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      history={history}
      renderRowKey={renderRowKey}
      renderCellContent={renderCellContent}
      loading={licensePrinterList === undefined}
    />
  );
}

const mapStateToProps = state => {
  return {
    licensePrinterList: state.licensePrinterList,
  }
};

export default connect(mapStateToProps, {})(withStyles(gStyles)(LicensePrinterTable));