import React, { useEffect }  from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Switch, Route, Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Paper, AppBar, Toolbar, Tabs, Tab } from '@material-ui/core';

import SettingsOEMDetailUpgrades from './SettingsOEMDetailUpgrades';
import SettingsOEMDetailEditions from './SettingsOEMDetailEditions';


let strings=new LocalizedStrings({
	en:{
    title:'Editions/Upgrades',

    tabUpgrades:'Upgrades',
    tabEditions:'Editions',
	}
})


function SettingsOEMDetailDashboard( props ) {

  const { classes } = props;
  const { oemId } = useParams();
  
  let history = useHistory();

  /*useEffect(
    () => {
      if ( history.location.pathname === '/settings/OEM/dashboard' )
        history.replace( '/settings/OEM/dashboard/upgrades' );
    },
    [ history ]
  )*/


  return (
      <Paper className={ classes.selectedItemPaper }>
        <AppBar className={ classes.selectedItemAppBar } position='static'>
          <Toolbar variant="dense">
            <Route
              render={({ location }) => ( 
                <Tabs value={ location.pathname.split('/')[3] }>
                  <Tab label={ strings.tabEditions }  component={ Link } value='editions' to={ '/settings/' + oemId + '/editions' }/>
                  <Tab label={ strings.tabUpgrades } component={ Link } value='upgrades' to={ '/settings/' + oemId + '/upgrades' }/>
                </Tabs>
            ) } />
          </Toolbar>
        </AppBar>
        <Switch>
          <Route path='/settings/:oemId/editions'><SettingsOEMDetailEditions/></Route>
          <Route path='/settings/:oemId/upgrades'><SettingsOEMDetailUpgrades/></Route>
        </Switch>        
      </Paper>

  );
}

const mapStateToProps = state => { 
  return {
    userType: state.userType,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( SettingsOEMDetailDashboard ) );