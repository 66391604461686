import React, { useState }  from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { refreshLicenseTable } from '../utils/Reducer';
import { getEvolisLicense, upgradeEvolisLicense } from './LicenseRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';

import MaskedInput from 'react-text-mask';
import { Button, Typography, TextField, FormControl, InputLabel, Select } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core';

let strings=new LocalizedStrings({
	en:{
    title: 'Upgrade external license',
    
    step1Label: 'Select license',
    step1ErrorLabel: 'Select license - license not found',
    step1Text: 'Please select the license you wish to upgrade',
    step1TextFieldLabel: 'License',
    
    step2Label: 'Select upgrade',
    step2ErrorLabel: 'Select upgrade - must select upgrade',
    step2OptionalLabel: 'Upgrade {upgradeTo}',
    
    labelLicense:'License Id: ',
    labelType:'Type: ',
    labelDongleSerial:'Dongle Serial: ',
    labelDongleVid:'Dongle VID: ',
    labelDonglePid:'Dongle PID: ',
    labelActivationCode:'Activation Code: ',
    labelActive:'Active: ',
    labelOriginalEdition:'Original Edition: ',
    labelCurrentEdition:'Current Edition: ',
    labelUpgradeCount:'Upgrade count: ',
    labelUpgrade: 'Upgrade',

    confirmationLabel: 'Confirmation',
    confirmationErrorLabel: 'Confirmation - upgrade not allowed',
    confirmationText: 'You are about to upgrade license {license} from {to}. This license wasn\'t your but is about to become.',

    typeDongle: 'Dongle',
    typeCode: 'Activation Code',
    typePrinter: 'Printer Activation',
    typeTrial: 'Trial',

    next: 'Next',
    back: 'Back',
    confirm: 'Confirm',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
	}
});

function TextMaskCustom( props ) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9a-f]/, /[0-9a-f]/, '-', /[0-9a-f]/, /[0-9a-f]/, /[0-9a-f]/, /[0-9a-f]/, '-', /[0-9a-f]/, /[0-9a-f]/, /[0-9a-f]/, /[0-9a-f]/, '-', /[0-9a-f]/, /[0-9a-f]/, /[0-9a-f]/, /[0-9a-f]/]}
      showMask
    />
  );
}

function LicenseUpgradeExternalWizard( props ) { 

  const { classes } = props;

  const { open, onClose } = props;

  const { refreshLicenseTable } = props;

  const [ activeStep, setActiveStep ] = useState( 0 );
  const [ step1Error, setStep1Error ] = useState( false );
  const [ step1Value, setStep1Value ] = useState( ' -    -    -    ' );
  const [ license, setLicense ] = useState( undefined );
  const [ upgradeTo, setUpgradeTo ] = useState( -1 );
  const [ upgradeToError, setUpgradeToError ] = useState( false );
  const [ confirmationError, setConfirmationError ] = useState( false );

  const handleNext = () => {
    switch ( activeStep ) {
      case 0:
        setStep1Error( false );
        getEvolisLicense( step1Value, step1CB );
        break;
      case 1:
        if (upgradeTo !== -1) {
          setUpgradeToError( false );
          setActiveStep( activeStep + 1 );
        }
        else {
          setUpgradeToError( true );
        }
        break;
      case 2:
          upgradeEvolisLicense( license, license.upgradePath[upgradeTo].pcode, confirmationCB );
        break;
      default: break;
    }
  }

  const handleBack = () => {
    switch ( activeStep ) {
      case 0:
        handleCloseDialog();
        break;
      case 1:
        setUpgradeTo( -1 );
        setUpgradeToError( false );
        setActiveStep( activeStep - 1 );
        break;
      case 2:
        setConfirmationError( false );
        setActiveStep( activeStep - 1 );
        break;
      default:
        break;
    }
  }

  const step1CB = ( license ) => {
    if (license !== undefined ) {
      setLicense( license );
      setActiveStep( activeStep + 1 );
    }
    else {
      setStep1Error( true );
    }
  }

  const confirmationCB = ( result ) => {
    if ( result ) {
      handleCloseDialog();
      refreshLicenseTable();
    }
    else {
      setConfirmationError( true );
    }
  }

  const handleCloseDialog = () => {
    setActiveStep( 0 );
    setStep1Error( false );
    setStep1Value( ' -    -    -    ' );
    setLicense( undefined );
    setUpgradeTo( -1 );
    setUpgradeToError( false );
    setConfirmationError( false );
    onClose();
  }

  return (
    <Dialog open={ open }>
      <DialogTitle className={ classes.title }>{ strings.title }</DialogTitle>
      <DialogContent style={ { width: '500px', height: '490px' } }>
        <Stepper activeStep={ activeStep } orientation='vertical'>
          <Step>
            <StepLabel error={ step1Error } optional={ license !== undefined && activeStep >=1 ? <Typography variant='body1'>{ license.license }</Typography> : '' }>
              <Typography variant='body1'>{ step1Error ? strings.step1ErrorLabel : strings.step1Label }</Typography>
            </StepLabel>
            <StepContent>
              <Typography variant='body1'>{ strings.step1Text }</Typography>
              <TextField variant='outlined' margin='dense' autoFocus
                label={ strings.step1TextFieldLabel } value={ step1Value } onChange={ event => setStep1Value( event.target.value ) }
                InputProps={ { inputComponent: TextMaskCustom } } />
            </StepContent>
          </Step>
          <Step>
            <StepLabel error={ upgradeToError } optional={ activeStep >= 2 ? <Typography variant='body1'>{ strings.step2OptionalLabel.replace('{upgradeTo}', license.upgradePath[upgradeTo].description) }</Typography> : '' }>
              <Typography variant='body1'>{ upgradeToError ? strings.step2ErrorLabel : strings.step2Label }</Typography>
            </StepLabel>
            { license !== undefined &&
              <StepContent>
                <Typography variant='body1'>{ strings.labelLicense }<b>{ license.license }</b></Typography>
                <Typography variant='body1'>{ strings.labelActive }<b>{ license.active === 1 ? strings.yes : strings.no }</b></Typography>
                { license.license_type === 1 &&
                  <div>
                    <Typography variant='body1'>{ strings.labelType }<b>{ strings.typeDongle }</b></Typography>
                    <Typography variant='body1'>{ strings.labelDongleSerial }<b>{ license.serial }</b></Typography>
                    <Typography variant='body1'>{ strings.labelDongleVid }<b>{ license.vid }</b></Typography>
                    <Typography variant='body1'>{ strings.labelDonglePid }<b>{ license.pid }</b></Typography>
                  </div>
                }
                { license.license_type === 2 &&
                  <div>
                    <Typography variant='body1'>{ strings.labelType }<b>{ strings.typeCode }</b></Typography>
                    <Typography variant='body1'>{ strings.labelActivationCode }<b>{ license.activation_code }</b></Typography>
                  </div>
                }
                { license.license_type === 3 &&
                  <div>
                    <Typography variant='body1'>{ strings.labelType }<b>{ strings.typePrinter }</b></Typography>
                    <Typography variant='body1'>{ strings.labelActivationCode }<b>{ license.activation_code }</b></Typography>
                  </div>
                }
                { license.license_type === 4 &&
                  <div>
                    <Typography variant='body1'>{ strings.labelType }<b>{ strings.typeTrial }</b></Typography>
                  </div>
                }
                <Typography variant='body1'>{ strings.labelOriginalEdition }<b>{ license.original }</b></Typography>
                <Typography variant='body1'>{ strings.labelCurrentEdition }<b>{ license.edition }</b></Typography>
                <Typography variant='body1'>{ strings.labelUpgradeCount }<b>{ license.upgradedCount }</b></Typography>
                <FormControl style={ { display:'flex', margin:'0px auto', width:'340px' } }>
                  <InputLabel>{ strings.labelUpgrade }</InputLabel>
                  <Select native value={ upgradeTo } onChange={ event => setUpgradeTo( event.target.value ) } >
                    <option value='' />
                    { license.upgradePath.map( ( path, index ) => <option key={ index } value={ index }>{ path.description }</option> ) }
                  </Select>
                </FormControl>
              </StepContent>
            }
          </Step>
          <Step>
            <StepLabel error={ confirmationError }>
              <Typography variant='body1'>{ confirmationError ? strings.confirmationErrorLabel : strings.confirmationLabel }</Typography>
            </StepLabel>
            <StepContent>
              { license !== undefined && upgradeTo !== -1 &&
                <Typography variant='body1'>
                  { strings.confirmationText.replace('{license}', license.license ).replace('{to}', license.upgradePath[upgradeTo].description ) }
                </Typography>
              }
             </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions className={ classes.lineButtonBox }>
        <Button className={ classes.lineButton } onClick={ handleBack } variant='contained' color='secondary'>{ activeStep === 0 ? strings.cancel : strings.back }</Button>
        <Button className={ classes.lineButton } onClick={ handleNext } variant='contained' color='secondary'>{ activeStep === 2 ? strings.confirm : strings.next }</Button>
        </DialogActions>
    </Dialog>
  );
 }

export default connect( null, { refreshLicenseTable } )( withStyles( gStyles )( LicenseUpgradeExternalWizard ) );