import React, { useEffect, useState }  from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Paper, Box, Grid, TextField, Button, Typography } from '@material-ui/core';
import { getSettingsOEMTheme } from './SettingsRest';



let strings=new LocalizedStrings({
	en:{
    title:'Variables',

    labelShort:'App Short',
    labelName:'App Name',
    labelSite:'Software Website',
    labelCopyright:'Copyright',
    labelCompany:'Company Name',
    labelPhone:'Phone',
    labelEmail:'Company Email',
    labelCompanySite:'Company Website',

	}
})


function SettingsOEMVariables( props ) {

  const { classes } = props;
  const { settingsOEMVariablesForceRefresh } = props;
  const { settingsOEMThemeValues } = props;

  const { oemId } = useParams();

  const [ values, setValues ] = useState( { valid: false, oem_id: 0, data:{} } );

  const dispatch = useDispatch();

  
  useEffect(
    () => {
      //getSettingsOEMTheme( oemId );
    },
    [ settingsOEMVariablesForceRefresh, settingsOEMThemeValues ]
  );

  if ( settingsOEMThemeValues !== undefined && !values.valid ) {   
    setValues( {
      valid: true,
      oem_id: settingsOEMThemeValues.productfamily_id,
      data:{
        variable_app_short: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.variable_app_short,
        variable_app_name: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.variable_app_name,
        variable_software_website: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.variable_software_website,
        variable_copyright: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.variable_copyright,
        variable_company_name: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.variable_company_name,
        variable_phone: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.variable_phone,
        variable_company_email: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.variable_company_email,
        variable_company_website: (settingsOEMThemeValues.data === '') ? undefined : settingsOEMThemeValues.data.variable_company_website,
      },

    } );
  }

  const handleChange = name => event => {
    setValues( { ...values, data: { ...values.data , [name]: event.target.value} } );
    dispatch({ type: 'SET_SETTINGS_OEM_THEME_VALUES', value: { ...settingsOEMThemeValues , data: { ...settingsOEMThemeValues.data , [name]: event.target.value} } });

  };

  return (
  <Box>
    <Paper className={ classes.workareaTopBoxes }>
      <Box className={ classes.workarea }>
        <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
        <form className={ classes.form } autoComplete="off">
            <Grid container spacing={2}>
             
              <Grid item xs={6}>
                <TextField required fullWidth id='short' autoFocus
                    label={ strings.labelShort } value={ values.data.variable_app_short } onChange={ handleChange('variable_app_short') }
                    inputProps={ { maxLength: 8 } }/>
              </Grid>


              <Grid item xs={6}>
                <TextField required fullWidth id='name' autoFocus
                    label={ strings.labelName } value={ values.data.variable_app_name } onChange={ handleChange('variable_app_name') }
                    inputProps={ { maxLength: 50 } }/>
              </Grid>


              <Grid item xs={6}>
                <TextField required fullWidth id='site' autoFocus
                    label={ strings.labelSite } value={ values.data.variable_software_website } onChange={ handleChange('variable_software_website') }
                    inputProps={ { maxLength: 50 } }/>
              </Grid>


              <Grid item xs={6}>
                <TextField required fullWidth id='copyright' autoFocus
                    label={ strings.labelCopyright } value={ values.data.variable_copyright } onChange={ handleChange('variable_copyright') }
                    inputProps={ { maxLength: 50 } }/>
              </Grid>

              <Grid item xs={6}>
                <TextField required fullWidth id='company' autoFocus
                    label={ strings.labelCompany } value={ values.data.variable_company_name } onChange={ handleChange('variable_company_name') }
                    inputProps={ { maxLength: 50 } }/>
              </Grid>

              <Grid item xs={6}>
                <TextField required fullWidth id='phone' autoFocus
                    label={ strings.labelPhone } value={ values.data.variable_phone } onChange={ handleChange('variable_phone') }
                    inputProps={ { maxLength: 50 } }/>
              </Grid>

              <Grid item xs={6}>
                <TextField required fullWidth id='email' autoFocus
                    label={ strings.labelEmail } value={ values.data.variable_company_email } onChange={ handleChange('variable_company_email') }
                    inputProps={ { maxLength: 50 } }/>
              </Grid>

              <Grid item xs={6}>
                <TextField required fullWidth id='companySite' autoFocus
                    label={ strings.labelCompanySite } value={ values.data.variable_company_website } onChange={ handleChange('variable_company_website') }
                    inputProps={ { maxLength: 50 } }/>
              </Grid>
             
                       
            </Grid>
          </form>
        </Box>
     </Paper>
  </Box>

  );
}

const mapStateToProps = state => { 
  return {
    settingsOEMVariablesForceRefresh: state.settingsOEMVariablesForceRefresh,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( SettingsOEMVariables ) );