import React from 'react';
import { connect } from 'react-redux';
import { clearErrorMessageList } from './utils/Reducer';
import { gStyles } from './utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Container, Typography, Snackbar } from '@material-ui/core';

import SignIn from './login/SignIn';
import Main from './Main';

function App( props ) {

  const { classes } = props;
  const { authToken } = props;
  const { errorMessageList, clearErrorMessageList } = props;

  return (
    <Container className={ classes.app } maxWidth='xl'>
      { !authToken && <SignIn/> }
      { authToken && <Main/> }
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
        open={ errorMessageList.length > 0 }
        message={ errorMessageList.map( ( item, index) => {
          return (
            <Typography key={ index } variant='body1' color='inherit'>{ item }</Typography>
          )
        } ) }
        autoHideDuration={ 3000 }
        onClose={ clearErrorMessageList }
      />
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    authToken: state.authToken,
    errorMessageList: state.errorMessageList,
  }
};

export default connect( mapStateToProps, { clearErrorMessageList } )( withStyles( gStyles )( App ) );
