import React, { useEffect }  from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getPrinterModels } from './PrinterRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Paper, Typography, CircularProgress } from '@material-ui/core';
import { Pie } from 'react-chartjs-3';

let strings=new LocalizedStrings({
	en:{
    title:'Printers by Model',
	}
})

function PrinterByModel( props ) {

  const { classes } = props;
  
  const { printerModelList } = props;
  const { printerTableForceRefresh } = props;

  useEffect(
    ( ) => {
      getPrinterModels( );
    },
    [ printerTableForceRefresh ]
  );

  return (
    <Paper className={ classes.workareaTopBoxes }>
      <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
      { printerModelList !== undefined &&
        <div className={ classes.piechart }>
          <Pie data={ printerModelList }
            legend={ { display: true, position: 'right' } }
            options={ { responsive: true, maintainAspectRatio: false } }/>
        </div>
      }
      { printerModelList === undefined &&
        <CircularProgress className={ classes.spinner }/>
      }
    </Paper>
  );
}

const mapStateToProps = state => { 
  return {
    printerTableForceRefresh: state.printerTableForceRefresh,
    printerModelList: state.printerModelList,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( PrinterByModel ) );