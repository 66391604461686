import React, { useEffect, useState }  from 'react';
import { Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { setCustomerAssignItem } from '../utils/Reducer';
import { getCustomers, exportCustomers, createCustomer, deleteCustomer } from './CustomerRest';
import { formatName } from '../utils/Utils';
import { gStyles, fieldLimits } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { Box, Paper, Typography, CircularProgress, InputBase, Button, IconButton, Tooltip, Checkbox, Grid, TextField } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Search, Delete, RadioButtonChecked, RadioButtonUnchecked, AddCircle, CloudUpload, CloudDownload } from '@material-ui/icons';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';
import CustomerWizardUpload from './CustomerWizardUpload';

let strings=new LocalizedStrings({
	en:{
    title:'Customers',
    titleDialog:'Customer',
    buttonAdd:'Add customer',
    buttonUpload:'Upload customers',
    buttonDownload:'Download customers',
    toolTipDelete:'Delete customer',
    tableHeaderDistributorName:'Distributor',
    tableHeaderCustomerName:'Name',
    tableHeaderCustomerVat:'VAT',
    tableHeaderContactEmail:'Email',
    tableHeaderContactName:'Contact',
    tableHeaderContactPhone:'Phone Number',
    searchPlaceHolder:'search',

    defaultCustomerName:'new customer',
    labelName:'Name',
    labelVAT:'VAT',
    labelAddress:'Address',

    buttonEdit:'Edit',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete customer \'{entity}\'?',
    yes: 'Yes',
    no: 'No',
	}
});

function CustomerTable( props ) { 

  const { classes } = props;

  const { assignMode } = props;

  const [ localPage, setLocalPage ] = useState(0);
  const [ localRowsPerPage, setLocalRowsPerPage ] = useState(10);
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );
  const [ wizardUploadOpen, setWizardUploadOpen ] = useState( false );
  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { } );

  const { userType } = props;
  const { allowFeatures } = props;
  const { customerTableForceRefresh } = props;
  const { customerList } = props;
  const { customerAssignItem, setCustomerAssignItem } = props;

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = !assignMode ? Number( searchParams.get( 'page' ) ) : localPage;
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  urlRowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;
  const rowsPerPage = !assignMode ? urlRowsPerPage : localRowsPerPage;

  const searchFromURL = searchParams.get( 'search' ) === null ? '' : searchParams.get( 'search' );
  const [ searchValue, setSearchValue ] = useState( '' );
  const [ searchTrigger, setSearchTrigger ] = useState( '' );

  useEffect(
    ( ) => {
      if ( !assignMode ) setSearchValue( searchFromURL );
      getCustomers( page * rowsPerPage, rowsPerPage, assignMode ? searchTrigger : searchFromURL );
    },
    [ page, rowsPerPage, assignMode, searchFromURL, searchTrigger, customerTableForceRefresh ]
  );

  const handleLocalChangePage = ( event, newPage ) => {
    if ( !assignMode )
      handleChangePage( history, newPage );
    else
      setLocalPage( newPage );
  }

  const handleLocalChangeRowsPerPage = event => {
    if (!assignMode)
      handleChangeRowsPerPage( history, event, page, rowsPerPage )
    else {
      //Each value of rowsPerPage must be 2n(base), examples: 10, 20, 40; 4, 8, 16; 5, 10, 20, 40, 80
      var newRowsPerPage = parseInt(event.target.value, 10);
      setLocalPage( Math.trunc( page * ( rowsPerPage / newRowsPerPage ) ) );
      setLocalRowsPerPage( newRowsPerPage );
    }
  }

  const searchKeyPress = event => {
    if ( event.charCode === 13 ) {//ENTER key pressed
      event.preventDefault();
      handleSearch( );
    }
  }

  const handleSearch = () => {
    if ( assignMode ) {
      setSearchTrigger( searchValue );
      return;
    }

    var searchParams = new URLSearchParams( history.location.search );
    if ( searchValue !== '' )
      searchParams.set( 'search', searchValue );
    else
      searchParams.delete( 'search' );
    searchParams.delete( 'page' ); 
    history.push( { search: searchParams.toString() } );
  }

  const handleChange = name => event => {
    setValues( { ...values, [name]: event.target.value } );
  };

  const handleAdd = () => {
    setValues( { customer_id: 0, name: strings.defaultCustomerName, vat: '', address: '' } );
    setEditMode( true );
  } 

  const handleConfirm = ( event ) => {
    event.preventDefault();
    createCustomer( values );
    setEditMode( false );
  };

  const handleCancel = () => {
    setEditMode( false );
  };

  const handleDelete = customer => {
    setEntityToDelete( customer );
  };
  
  const handleDeleteDialogClose = confirm => {
    if ( confirm )
      deleteCustomer( entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleCheck = customer => {
    setCustomerAssignItem( { ...customerAssignItem, name: customer.name, customer_id: customer.customer_id } );
  }

  return (
    <Box>
      <Paper className={ classes.workarea }>
        <Box className={ classes.flexHeader }>
          <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
          { !assignMode && userType !== 0 &&
            <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ handleAdd }>
                <AddCircle className={ classes.leftIcon } />
                { strings.buttonAdd }
            </Button>
          }
          { !assignMode && userType !== 0 &&
            <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( ) => setWizardUploadOpen( true ) }>
              <CloudUpload className={ classes.leftIcon } />
              { strings.buttonUpload }
            </Button>
          }
          { !assignMode && userType !== 0 &&
            <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( ) => exportCustomers( ) }>
              <CloudDownload className={ classes.leftIcon } />
              { strings.buttonDownload }
            </Button>
          }
          <Box className={ classes.flexExpand }/>
          <Paper className={ classes.searchPaper }>
            <InputBase
              className={ classes.searchInput }
              placeholder={ strings.searchPlaceHolder }
              inputProps={ { 'aria-label': 'Search Text' } }
              value={ searchValue }
              onChange={ ( event ) => setSearchValue( event.target.value ) }
              onKeyPress={ searchKeyPress }/>
            <IconButton aria-label='Search' size='small' onClick={ handleSearch }>
              <Search/>
            </IconButton>
          </Paper>
        </Box>
        
        <Box className={ classes.tableArea } >
          { customerList !== undefined &&
            <Table size='small'>
              <TableHead>
                <TableRow>
                  { assignMode && <TableCell/> }
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderCustomerName }</Typography>
                  </TableCell>
                  { (userType === 0 || (userType === 1 && (allowFeatures & 4) === 4)) &&
                    <TableCell>
                      <Typography variant='h6'>{ strings.tableHeaderDistributorName }</Typography>
                    </TableCell>
                  }
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderCustomerVat }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderContactEmail }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderContactName }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderContactPhone }</Typography>
                  </TableCell>
                  { !assignMode && userType !== 0 && <TableCell/> }
                </TableRow>
              </TableHead>
              <TableBody>
                { customerList.items.map( customer => (
                  <TableRow key={ customer.customer_id }>
                    { assignMode &&
                      <TableCell padding="checkbox" onClick={ ( ) => handleCheck( customer ) }>
                        <Checkbox
                          checked={ customerAssignItem !== undefined && customerAssignItem.customer_id === customer.customer_id }
                          checkedIcon={ <RadioButtonChecked/> }
                          icon={ <RadioButtonUnchecked/> }
                          color='primary'/>
                      </TableCell>
                    }
                    { !assignMode &&
                      <TableCell>
                        <Link className={ classes.link } to={ '/customers/' + customer.customer_id + '/details' }>
                          <Typography variant='body2'>{ customer.name }</Typography>
                        </Link>                    
                      </TableCell>
                    }
                    { assignMode &&
                      <TableCell>
                        <Typography variant='body2'>{ customer.name }</Typography>
                      </TableCell>
                    }
                    { userType === 0 &&
                      <TableCell>
                        <Link className={ classes.link } to={ '/distributors/' + customer.distributor_id + '/details' }>
                          <Typography variant='body2'>{ customer.distributor_name }</Typography>
                        </Link>                    
                      </TableCell>                        
                    }
                    { userType === 1 && (allowFeatures & 4) === 4 &&
                      <TableCell>
                        <Link className={ classes.link } to={ '/distributors/' + customer.subdistributor_id + '/details' }>
                          <Typography variant='body2'>{ customer.subdistributor_name }</Typography>
                        </Link>                    
                      </TableCell>                        
                    }
                    <TableCell>
                      <Typography variant='body2'>{ customer.vat }</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ customer.email }</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ formatName( customer.first_name, customer.last_name ) }</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ customer.phone }</Typography>
                    </TableCell>
                    { !assignMode && userType !== 0 && customer.is_direct_customer === 1 &&
                      <TableCell className={ classes.tableButton } >
                        <Tooltip title={ strings.toolTipDelete }>
                          <IconButton size='small' onClick={ ( ) => handleDelete( customer ) } >
                            <Delete/>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    }
                    { !assignMode && userType !== 0 && customer.is_direct_customer === 0 &&
                      <TableCell/>
                    }
                  </TableRow>
                )) }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 8 } count={ customerList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ handleLocalChangePage }
                    onChangeRowsPerPage={ handleLocalChangeRowsPerPage }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          }
          { customerList === undefined &&
              <CircularProgress className={ classes.spinner }/>
          }
        </Box>
      </Paper>
      <Dialog open={ editMode }>
        <Box className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleDialog }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

              <Grid item xs={8}>
                <TextField fullWidth variant='outlined' required id='name' disabled={ !editMode }
                  label={ strings.labelName } value={ values.name } onChange={ handleChange('name') }
                  inputProps={ { maxLength: fieldLimits.customer.name.size } }/>
              </Grid> 

              <Grid item xs={4}>
                <TextField fullWidth variant='outlined' id='vat' disabled={ !editMode }
                  label={ strings.labelVAT } value={ values.vat } onChange={ handleChange('vat') }
                  inputProps={ { maxLength: fieldLimits.customer.vat.size } }/>
              </Grid> 

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' id='address' disabled={ !editMode }
                  label={ strings.labelAddress } value={ values.address } onChange={ handleChange('address') }
                  inputProps={ { maxLength: fieldLimits.customer.address.size } }/>
              </Grid> 

              <Grid item xs={12}>
                <Box className={ classes.lineButtonBox }>
                  <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
                  <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleCancel() }>{ strings.buttonCancel }</Button>
                </Box> 
              </Grid> 

            </Grid> 
          </form>
        </Box>       
      </Dialog>      
      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText.replace( '{entity}', entityToDelete !== undefined ? entityToDelete.name : '' ) }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
      <CustomerWizardUpload open={ wizardUploadOpen } onClose={ () => setWizardUploadOpen( false ) }/>
    </Box>
  );
 }

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    allowFeatures: state.allowFeatures,
    customerTableForceRefresh: state.customerTableForceRefresh,
    customerSearch: state.customerSearch,
    customerList: state.customerList,
    customerAssignItem: state.customerAssignItem,
  }
};

export default connect( mapStateToProps, { setCustomerAssignItem } )( withStyles( gStyles )( CustomerTable ) );