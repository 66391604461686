import { store } from '../index';
import { setFilters, setFilterCounter, refreshFilters } from '../utils/Reducer';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getFilters
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getFilters( filterTableType, from, limit ) {

  const onError = error => {
    gError( 'getFilters', error );
    store.dispatch( setFilters( { count:0, items:[] } ) );
  }

  store.dispatch( setFilters( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/filters?filterType=' + filterTableType + '&from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setFilters( json ) );
        json.items.forEach( ( filter, index ) => { if ( filter.count === undefined ) getFilterCount( filterTableType, filter, index ); } );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getFilterCount
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getFilterCount( filterTableType, filter, index ) {

  const onError = error => {
    gError( 'getFilterCount', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/filters/' + filter.filter_id + '?filterType=' + filterTableType + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setFilterCounter( { index: index, count: json.count } ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setFilter
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setFilter( filter ) {

  const onError = error => {
    gError( 'setFilter', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/filters/' + filter.filter_id + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( filter )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshFilters( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteFilter
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteFilter( filter ) {

  const onError = error => {
    gError( 'deleteFilter', error );
    store.dispatch( refreshFilters( ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/filters/' + filter.filter_id + '?authToken=' + authToken, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshFilters( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}
