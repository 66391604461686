import React, { useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getDistributorFeatures, setDistributorFeatures } from './DistributorRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { CircularProgress, Checkbox, Typography } from '@material-ui/core';


let strings=new LocalizedStrings({
	en:{
    tableHeaderFeature:'Feature',
    tableHeaderActive:'Active',

    featureNotifications:'Notifications',
    featureWhatsNew:'What\'s New',
    featureDistributors:'Distributors',
	}
});

function DistributorFeatureTable( props ) { 

  const { classes } = props;

  const { distributorFeatureList } = props;
  const { distributorId } = useParams();

  const { userType } = props;

  var pageFeatures = [
    { name: strings.featureNotifications, value: 1 }, //00001
    { name: strings.featureWhatsNew, value: 2 }, //00010
  ];

  if(userType === 0) {
    pageFeatures.push( { name: strings.featureDistributors, value: 4 }, )
  }

  useEffect(
    ( ) => { 
      getDistributorFeatures( distributorId );
   },
   [ distributorId ]
  );

  const handleClick = ( featureIndex ) => {
    var bitwiseFeatures = distributorFeatureList.features;

    if(( bitwiseFeatures & pageFeatures[featureIndex].value ) !== 0)
    { //handle uncheck
      bitwiseFeatures = bitwiseFeatures & ~pageFeatures[featureIndex].value;
    }
    else //handle check
    {
      bitwiseFeatures = bitwiseFeatures | pageFeatures[featureIndex].value;
    }
    setDistributorFeatures( distributorId, bitwiseFeatures );
  }

  return (
    <div>
      <div className={ classes.workarea }>
        <div className={ classes.tableArea } >
          { distributorFeatureList !== undefined &&
            <Table size='small'>
              <TableHead>
                <TableRow>
                <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderFeature }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderActive }</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { pageFeatures.map( ( feature, index ) => (
                  <TableRow key={ index }>
                    <TableCell>
                      <Typography variant='body2'>{ feature.name }</Typography>
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox checked={ distributorFeatureList !== undefined && (( distributorFeatureList.features & pageFeatures[index].value ) !== 0) } disabled={ userType !== 0 } onClick={ ( ) => handleClick( index ) }/>
                    </TableCell>              
                  </TableRow>
                )) }
              </TableBody>
            </Table>
          }
          { distributorFeatureList === undefined &&
              <CircularProgress className={ classes.spinner }/>
          }
        </div>
      </div>
    </div>
  );
 }

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    distributorFeatureList: state.distributorFeatureList
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( DistributorFeatureTable ) );