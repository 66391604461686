import React, { useEffect, useState }  from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { setProductFamilyAssignList } from '../utils/Reducer';
import { getProductFamilies } from './ProductFamilyRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { Paper, Typography, CircularProgress, InputBase, IconButton, Checkbox } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    title:'Products',
    tableHeaderName:'Product',
    tableHeaderDesignation:'Designation',
    searchPlaceHolder:'search',
	}
});

function ProductFamilyTable( props ) { 

  const { classes } = props;

  const { assignMode } = props;

  const { productFamilyList } = props;
  const { productFamilyAssignList, setProductFamilyAssignList } = props;
  
  const [ localPage, setLocalPage ] = useState(0);
  const [ localRowsPerPage, setLocalRowsPerPage ] = useState(10);

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = !assignMode ? Number( searchParams.get( 'page' ) ) : localPage;
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  urlRowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;
  const rowsPerPage = !assignMode ? urlRowsPerPage : localRowsPerPage;

  const [ searchValue, setSearchValue ] = useState( '' );
  const [ searchTrigger, setSearchTrigger ] = useState( '' );

  useEffect(
    ( ) => {
      getProductFamilies( page * rowsPerPage, rowsPerPage, searchTrigger );
    },
    [ page, rowsPerPage, searchTrigger ]
  );

  const searchKeyPress = event => {
    if ( event.charCode === 13 ) {//ENTER key pressed
      event.preventDefault();
      handleSearch( );
    }
  }

  const handleSearch = () => {
    setSearchTrigger( searchValue );
  }

  const handleLocalChangePage = ( event, newPage ) => {
    if ( !assignMode )
      handleChangePage( history, newPage );
    else
      setLocalPage( newPage );
  }

  const handleLocalChangeRowsPerPage = event => {
    if (!assignMode)
      handleChangeRowsPerPage( history, event, page, rowsPerPage )
    else {
      //Each value of rowsPerPage must be 2n(base), examples: 10, 20, 40; 4, 8, 16; 5, 10, 20, 40, 80
      var newRowsPerPage = parseInt(event.target.value, 10);
      setLocalPage( Math.trunc( page * ( rowsPerPage / newRowsPerPage ) ) );
      setLocalRowsPerPage( newRowsPerPage );
    }
  }

  const handleCheck = ( productFamily ) => {
    var newSelecteItems = productFamilyAssignList;
    var index = newSelecteItems.indexOf( productFamily.productfamily_id );
    if (index !== -1)
      newSelecteItems.splice( index, 1 );
    else
      newSelecteItems.push( productFamily.productfamily_id );

    setProductFamilyAssignList( newSelecteItems );
  }

  return (
    <Paper className={ classes.workarea }>
      <div className={ classes.flexHeader }>
        <Typography  variant='h4' className={ classes.title }>{ strings.title }</Typography>
        <div className={ classes.flexExpand }/>
        <Paper className={ classes.searchPaper }>
          <InputBase
            className={ classes.searchInput }
            placeholder={ strings.searchPlaceHolder }
            inputProps={ { 'aria-label': 'Search Text' } }
            value={ searchValue }
            onChange={ ( event ) => setSearchValue( event.target.value ) }
            onKeyPress={ ( event ) => searchKeyPress( event ) }/>
          <IconButton aria-label='Search' size='small' onClick={ handleSearch }>
            <Search/>
          </IconButton>
        </Paper>
      </div>
      
      <div className={ classes.tableArea } >
        { productFamilyList !== undefined &&
          <Table size='small'>
            <TableHead>
              <TableRow>
                { assignMode && <TableCell/> }
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderName }</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{ strings.tableHeaderDesignation }</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { productFamilyList.items.map( productFamily => (
                <TableRow key={ productFamily.productfamily_id }>
                  { assignMode &&
                    <TableCell padding="checkbox" onClick={ ( ) => handleCheck( productFamily ) }>
                      <Checkbox/>
                    </TableCell>
                  }
                  <TableCell>
                    <Typography variant='body2'>{ productFamily.name }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2'>{ productFamily.designation }</Typography>
                  </TableCell>
                </TableRow>
              )) }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 3 } count={ productFamilyList.count } rowsPerPage={ rowsPerPage } page={ page }
                  SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                  onChangePage={ handleLocalChangePage }
                  onChangeRowsPerPage={ handleLocalChangeRowsPerPage }
                  ActionsComponent={ TablePaginationActions }
                />
              </TableRow>
            </TableFooter>
          </Table>
        }
        { productFamilyList === undefined &&
            <CircularProgress className={ classes.spinner }/>
        }
      </div>
    </Paper>
  );
 }

const mapStateToProps = state => { 
  return {
    productFamilyList: state.productFamilyList,
    productFamilyAssignList: state.productFamilyAssignList,
  }
};

export default connect( mapStateToProps, { setProductFamilyAssignList } )( withStyles( gStyles )( ProductFamilyTable ) );