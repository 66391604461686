import { store } from '../index';
import { setSettingsNotificationList, refreshSettingsNotificationTable } from '../utils/Reducer';
import { setSettingsWhatsnewList, refreshSettingsWhatsnewTable } from '../utils/Reducer';
import { setSettingsLearningList, refreshSettingsLearningTable } from '../utils/Reducer';
import { setSettingsOEMList, setSettingsOEMEditionsList, setSettingsOEMUpgradesList, setSettingsOEMThemeValues, setSettingsDistributorsList } from '../utils/Reducer';
import { refreshSettingsOEMTable, refreshSettingsOEMEditionsTable, refreshSettingsOEMEditor, refreshSettingsOEMPreview, refreshSettingsOEMVariables, refreshSettingsOEMUpgradesTable } from '../utils/Reducer';
import { setSettingsOEMDistributorList } from '../utils/Reducer';

import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsNotifications
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsNotifications( from, limit ) {

  const onError = error => {
    gError( 'getSettingsNotifications', error ); 
    store.dispatch( setSettingsNotificationList( { count:0, items:[] } ) );
  }

  store.dispatch( setSettingsNotificationList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/notifications?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsNotificationList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsWhatsnew
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsWhatsnew( from, limit ) {

  const onError = error => {
    gError( 'getSettingsWhatsnew', error ); 
    store.dispatch( setSettingsWhatsnewList( { count:0, items:[] } ) );
  }

  store.dispatch( setSettingsWhatsnewList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/whatsnew?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsWhatsnewList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsLearning
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsLearning( from, limit ) {

  const onError = error => {
    gError( 'getSettingsLearning', error ); 
    store.dispatch( setSettingsLearningList( { count:0, items:[] } ) );

  }

  store.dispatch( setSettingsLearningList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/learning?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsLearningList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEM
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEM( from, limit ) {

  const onError = error => {
    gError( 'getSettingsOEM', error ); 
    store.dispatch( setSettingsOEMList( { count:0, items:[] } ) );
    store.dispatch( setSettingsDistributorsList( { distributors:[] } ) );

  }

  store.dispatch( setSettingsOEMList( undefined ) );
  store.dispatch( setSettingsDistributorsList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oem?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsOEMList( json ) );
        store.dispatch( setSettingsDistributorsList( json.distributors ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMDistributor
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMDistributor( from, limit ) {

  const onError = error => {
    gError( 'getSettingsOEMDistributor', error ); 
    store.dispatch( setSettingsOEMDistributorList( { count:0, items:[] } ) );
  }

  store.dispatch( setSettingsOEMDistributorList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemdistributor?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsOEMDistributorList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMEditions
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMEditions( from, limit, oemId ) {

  const onError = error => {
    gError( 'getSettingsOEMEditions', error ); 
    store.dispatch( setSettingsOEMEditionsList( { count:0, items:[] } ) );

  }

  store.dispatch( setSettingsOEMEditionsList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemeditions/' + oemId + '?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsOEMEditionsList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMUpgrades
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMUpgrades( from, limit, oemId ) {

  const onError = error => {
    gError( 'getSettingsOEMUpgrades', error ); 
    store.dispatch( setSettingsOEMUpgradesList( { count:0, items:[] } ) );

  }

  store.dispatch( setSettingsOEMUpgradesList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemupgrades/' + oemId + '?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsOEMUpgradesList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsOEMTheme
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getSettingsOEMTheme( oemId ) {

  const onError = error => {
    gError( 'getSettingsOEMTheme', error ); 
    store.dispatch( setSettingsOEMThemeValues( [] ) );

  }

  store.dispatch( setSettingsOEMThemeValues( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemtheme/' + oemId + '?&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        if(json.data !== ''){
          var data = JSON.parse(json.data);
          json.data = data;
        }       
        store.dispatch( setSettingsOEMThemeValues( json ) );
        store.dispatch( refreshSettingsOEMPreview() );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsUpgrades
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
/*export function getSettingsUpgrades( from, limit ) {

  const onError = error => {
    gError( 'getSettingsUpgrades', error ); 
    store.dispatch( setSettingsUpgradesList( { count:0, items:[] } ) );

  }

  store.dispatch( setSettingsUpgradesList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/upgrades?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsUpgradesList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}*/


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getSettingsEditions
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
/*export function getSettingsEditions( from, limit ) {

  const onError = error => {
    gError( 'getSettingsEditions', error ); 
    store.dispatch( setSettingsEditionsList( { count:0, items:[] } ) );

  }

  store.dispatch( setSettingsEditionsList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/editions?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setSettingsEditionsList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}*/


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsNotification
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsNotification( notification ) {

  const onError = error => {
    gError( 'setSettingsNotification', error );
    store.dispatch( refreshSettingsNotificationTable( ) );
  }

  if ( notification.msgShow !== undefined )
    delete notification.msgShow;

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/notifications/' + notification.notification_id + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( notification )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsNotificationTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}





//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsWhatsnew
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsWhatsnew( whatsnew ) {

  const onError = error => {
    gError( 'setSettingsWhatsnew', error );
    store.dispatch( refreshSettingsWhatsnewTable( ) );
  }

  if ( whatsnew.msgShow !== undefined )
    delete whatsnew.msgShow;

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/whatsnew/' + whatsnew.whatsnew_id + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( whatsnew )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsWhatsnewTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsLearning
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsLearning( learning ) {

  const onError = error => {
    gError( 'setSettingsLearning', error );
    store.dispatch( refreshSettingsLearningTable( ) );
  }

  if ( learning.msgShow !== undefined )
    delete learning.msgShow;

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/learning/' + learning.learning_id + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( learning )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsLearningTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsOEM
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOEM( oem ) {

  const onError = error => {
    gError( 'setSettingsOEM', error );
    store.dispatch( refreshSettingsOEMTable( ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oem/' + oem.oem_id + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( oem )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsOEMTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsOEMEdition
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOEMEdition( edition ) {

  const onError = error => {
    gError( 'setSettingsOEMEdition', error );
    store.dispatch( refreshSettingsOEMEditionsTable( ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemedition/' + edition.oem_id + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( edition )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsOEMEditionsTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsOEMUpgrades
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOEMUpgrades( upgrade ) {

  const onError = error => {
    gError( 'setSettingsOEMUpgrades', error );
    store.dispatch( refreshSettingsOEMUpgradesTable( ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemupgrade/' + upgrade.oem_id + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( upgrade )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsOEMUpgradesTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsOEMTheme
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setSettingsOEMTheme( theme ) {

  const onError = error => {
    gError( 'setSettingsOEMTheme', error );
    store.dispatch( refreshSettingsOEMEditor( ) );
    store.dispatch( refreshSettingsOEMPreview( ) );
    store.dispatch( refreshSettingsOEMVariables( ) );
  }
 
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oemtheme/' + theme.productfamily_id + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( theme )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsOEMEditor( ) );
      store.dispatch( refreshSettingsOEMPreview( ) );
      store.dispatch( refreshSettingsOEMVariables( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsUpgrades
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
/*export function setSettingsUpgrades( upgrade ) {

  const onError = error => {
    gError( 'setSettingsUpgrades', error );
    store.dispatch( refreshSettingsUpgradesTable( ) );
  }

  if ( upgrade.msgShow !== undefined )
    delete upgrade.msgShow;

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/upgrades/' + upgrade.product_code + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( upgrade )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsUpgradesTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}*/


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setSettingsEditions
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
/*export function setSettingsEditions( edition ) {

  const onError = error => {
    gError( 'setSettingsEditions', error );
    store.dispatch( refreshSettingsEditionsTable( ) );
  }

  if ( edition.msgShow !== undefined )
    delete edition.msgShow;

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/upgrades/' + edition.product_code + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( edition )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsEditionsTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}*/


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteSettingsNotification
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteSettingsNotification( notification ) {

  const onError = error => {
    gError( 'deleteSettingsNotification', error );
    store.dispatch( refreshSettingsNotificationTable( ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/notifications/' + notification.notification_id + '?authToken=' + authToken, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsNotificationTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteSettingsWhatsnew
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteSettingsWhatsnew( whatsnew ) {

  const onError = error => {
    gError( 'deleteSettingsWhatsnew', error );
    store.dispatch( refreshSettingsWhatsnewTable( ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/whatsnew/' + whatsnew.whatsnew_id + '?authToken=' + authToken, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsWhatsnewTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteSettingsLearning
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteSettingsLearning( learning ) {

  const onError = error => {
    gError( 'deleteSettingsLearning', error );
    store.dispatch( refreshSettingsLearningTable( ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/learning/' + learning.learning_id + '?authToken=' + authToken, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsLearningTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteSettingsOEM
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteSettingsOEM( oem ) {

  const onError = error => {
    gError( 'deleteSettingsOEM', error );
    store.dispatch( refreshSettingsOEMTable( ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/settings/oem/' + oem.productfamily_id + '?authToken=' + authToken, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshSettingsOEMTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}