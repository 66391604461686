import React, { useEffect, useState, useRef }  from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMEditions, setSettingsOEMEdition } from './SettingsRest';
import { gStyles } from '../utils/Styles';
import DropzonePic from '../components/DropzonePic';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, FormLabel, TextField } from '@material-ui/core';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, FormControl, Tooltip, Dialog } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import ImageIcon from '@material-ui/icons/Image';
import Icon from '@material-ui/core/Icon';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    title:'Editions',
    titleDialog:'Editions',

    tableHeaderEditionName:'Edition Name',
    tableHeaderTitle:'Target/Title',
    tableHeaderDescription:'Description',
    tableHeaderPicture:'Picture',
    tableHeaderLink:'Find out more/Link',

    labelEditionName:'Edition Name',
    labelTitle:'Target/Title',
    labelDescription:'Description',
    labelPicture:'Picture',
    labelLink:'Find out more/Link',

    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    toolTipEdit:'Edit the Edition',
    toolTipDeletePicture:'Remove featured picture',
    toolTipEditPicture:'Click the picture to edit',

    yes: 'Yes',
    no: 'No',
	}
})

function SettingsOEMEditions( props ) {

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { } );

  const { settingsOEMEditionsTableForceRefresh } = props;
  const { settingsOEMEditionsList } = props;

  const [ state, setState ] = useState( { hover: false } );   

  const { oemId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;
  const fileUploader = useRef(null);

  useEffect(
    ( ) => {
      getSettingsOEMEditions( page * rowsPerPage, rowsPerPage, oemId );
    },
    [ page, rowsPerPage, settingsOEMEditionsTableForceRefresh ]
  );

  const startNewFile = ( file ) => {
    var reader = new FileReader();
    reader.onload = () => {
     setValues( { ...values, picture: reader.result } );
    };
    reader.readAsDataURL(file);
  }

  const handleChange = name => event => {

    switch ( name ) {
      case 'name': {
        setValues( { ...values, name: event.target.value } );
        break;
      }
      case 'title': {
        setValues( { ...values, title: event.target.value } );
        break;
      }
      case 'description': {
        setValues( { ...values, description: event.target.value } );
        break;
      }
      case 'delPicture': {
        setValues( { ...values, picture: '' } );
        break;
      }
      case 'link': {
        setValues( { ...values, link: event.target.value } );
        break;
      }

      default:
        break;
    }
  };

  const mouseOver = () => {
    setState({ hover: true });
    }

  const mouseOut = () => {
    setState({ hover: false });
  }

  const onPictureEdit = ( event ) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues( { ...values, picture: reader.result } );
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  const handleEdit = edition => {
    setEditMode( true );
    setValues( {
      oem_id: edition.productfamily_id,
      name: edition.product_code,
      file: edition.product_file,
      title: edition.product_public,
      description: edition.product_description,
      picture: edition.product_picture === null ? '' : edition.product_picture,
      link: edition.product_link } );
  };

  const handleClose = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setEditMode( false );
    setSettingsOEMEdition( values );
  };

  return (
    <Box>        
      <Paper className={ classes.workareaTopBoxes }>
        <Box className={ classes.flexHeader }>
          <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
        </Box>
        { settingsOEMEditionsList !== undefined &&
          <Box className={ classes.tableArea }>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderEditionName }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderTitle }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderDescription }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderPicture }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderLink }</Typography>
                  </TableCell>                                                     
                  <TableCell/>
                </TableRow>
              </TableHead>              
              <TableBody>
                { settingsOEMEditionsList.items.map( ( edition ) => ( 
                  <TableRow key={ edition.editionId }>
                    <TableCell style={{ width:'20%' }}>{ edition.product_code }</TableCell>        
                    <TableCell style={{ width:'15%' }}>{ edition.product_public }</TableCell>                                  
                    <TableCell style={{ width:'35%' }}>{ edition.product_description }</TableCell>                     
                    <TableCell style={{ width:'10%' }}>
                    <Tooltip title={state.hover ? (<img src={edition.product_picture} height={ 100 } width={ 100 } alt="" />) : ''}>
                      <Icon size='small' onMouseOver={mouseOver} onMouseOut={mouseOut} >
                        <ImageIcon/>  
                      </Icon> 
                    </Tooltip>
                    </TableCell>
                    <TableCell style={{ width:'25%' }}>{ edition.product_link }</TableCell>                                      
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipEdit }>
                        <IconButton size='small' onClick={ ( ) => handleEdit( edition ) } >
                          <Edit/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow> ) )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 9 } count={ settingsOEMEditionsList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        { settingsOEMEditionsList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Paper>
      
      <Dialog open={ editMode } style={ { zIndex:0, height:'900px' } }>
        <Box className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleDialog }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

            <Grid item xs={12}>
              <TextField fullWidth variant='outlined' margin='dense' id='title'
                label={ strings.labelTitle } value={ values.title } onChange={ handleChange('title') } />
            </Grid>

            <Grid item xs={12}>
              <TextField fullWidth variant='outlined' margin='dense' id='description'
                label={ strings.labelDescription } value={ values.description } onChange={ handleChange('description') } />
            </Grid>

              { values.picture === '' &&
              <Grid item xs={6}>
                <FormControl variant='outlined' required>
                <FormLabel><Typography variant='caption'>{ strings.labelPicture }</Typography></FormLabel>
                  <DropzonePic onFileAdded={ ( file ) => { startNewFile( file ) } } />
                </FormControl>
              </Grid>
              }

              { values.picture !== '' &&
              <Grid item xs={6}>
                <FormControl variant='outlined' required onClick={ handleEditPicClick }>
                  <FormLabel><Typography variant='caption'>{ strings.labelPicture }</Typography></FormLabel>
                    <img src={values.picture} height={ 160 } width={ 160 } alt=""/>
                    <input type='file' accept='.png'  ref= { fileUploader } style={{display: "none"}} onChange={ ( file ) => { onPictureEdit( file ) }}/>
                    <FormControl><Typography variant='body1' style={{fontStyle:"italic", fontSize:"13px"}}>{ strings.toolTipEditPicture }</Typography></FormControl>
                </FormControl>
                <FormControl><FormLabel onClick={ handleChange( 'delPicture' ) }><Typography style={{color:"blue", textDecoration:"underline", fontSize:"13px"}}>{ strings.toolTipDeletePicture }</Typography></FormLabel> </FormControl>

              </Grid>
              }
              <Grid item xs={12}>
                <TextField fullWidth type='url' variant='outlined' margin='dense' id='link'
                  label={ strings.labelLink } value={ values.link } onChange={ handleChange('link') }/>
              </Grid>

            </Grid>

            <Box className={ classes.lineButtonBox }>
              <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
              <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleClose() }>{ strings.buttonCancel }</Button>
            </Box> 
          </form>
        </Box>       
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMEditionsTableForceRefresh: state.settingsOEMEditionsTableForceRefresh,
    settingsOEMEditionsList: state.settingsOEMEditionsList,
  }
};

export default connect( mapStateToProps, {  })( withStyles( gStyles )( SettingsOEMEditions ) );