import React from 'react';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';

import PrinterByModel from './PrinterByModel';
import FilterTable from '../filter/FilterTable';
import PrinterTable from './PrinterTable';

function PrintersDashboard( props ) {
  
  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PrinterByModel/>
        </Grid>
        <Grid item xs={6}>
          <FilterTable filterTableType='printer'/>
        </Grid>
        <Grid item xs={12}>
          <PrinterTable/>
        </Grid> 
      </Grid>
    </Container>
  );
}

export default connect( null, { } )( withStyles( gStyles )( PrintersDashboard ) );
