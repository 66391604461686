import LocalizedStrings from 'react-localization';
import { store } from '../index';
import { addErrorMessage } from './Reducer';

const strings = new LocalizedStrings( {
	en:{
    unknownError: 'Unknown error',
    sessionExpiredError: 'Session expired',
    serverInternalError: 'Server internal error',
    serverIsDown: 'Server is down',
    distributorNotFound: 'Distributor not found or you don\'t have access to it',
    distributorUserNotFound: 'User not found or you don\'t have access to it',
    distributorProductFamilyNotFound: 'Product not found or you don\'t have access to it',
    distributorDeleteNotAllowed: 'Distributor not found or you don\'t have permissions to it',
    customerNotFound: 'Customer not found or you don\'t have access to it',
    customerContactNotFound: 'Contact not found or you don\'t have access to it',
    customerLicenseNotFound: 'The license is not assigned to the customer',
    licenseNotFound: 'License not found or you don\'t have access to it',
    upgradeNotAllowed: 'You cannot upgrade this license',
    printerNotFound: 'Printer not found or you don\'t have access to it',
    filterNotFound: 'Filter not found or you don\'t have access to it',
    notificationNotFound: 'Notification not found or you don\'t have access to it',
    featureNotActive: 'The feature is not active',
	}
} )

const translateError = error => {
  switch ( error ) {
    case 'SERVER_INTERNAL_ERROR': return strings.serverInternalError;
    case 'LOGIN_NOK': return strings.sessionExpiredError;
    case 'DISTRIBUTOR_NOT_FOUND': return strings.distributorNotFound;
    case 'DISTRIBUTOR_USER_NOT_FOUND': return strings.distributorUserNotFound;
    case 'DISTRIBUTOR_PRODUCTFAMILY_NOT_FOUND': return strings.distributorProductFamilyNotFound;
    case 'DISTRIBUTOR_DELETE_NOT_ALLOWED': return strings.distributorDeleteNotAllowed;
    case 'CUSTOMER_NOT_FOUND': return strings.customerNotFound;
    case 'CUSTOMER_CONTACT_NOT_FOUND': return strings.customerContactNotFound;
    case 'CUSTOMER_LICENSE_NOT_FOUND': return strings.customerLicenseNotFound;
    case 'LICENSE_NOT_FOUND': return strings.licenseNotFound;
    case 'UPGRADE_NOT_ALLOWED': return strings.upgradeNotAllowed;
    case 'PRINTER_NOT_FOUND': return strings.printerNotFound;
    case 'FILTER_NOT_FOUND': return strings.filterNotFound;
    case 'NOTIFICATION_NOT_FOUND': return strings.notificationNotFound;
    case 'FEATURE_NOT_ACTIVE': return strings.featureNotActive;
    case 'Failed to fetch': return strings.serverIsDown;
    default: return strings.unknownError;
  }
}

export const webAPIUser = 'cpwebapi';
export const webAPIPass = 'ZVE8rh2DWJPQEWar';

export const gError = ( func, error ) => {
  console.error( func + '.OnError: ' + error );
  store.dispatch( addErrorMessage( translateError( error ) ) );
}
