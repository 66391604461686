import React, { useEffect, useState, useRef }  from 'react';
import { Route, Switch, Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMDistributor, setSettingsOEM, deleteSettingsOEM } from './SettingsRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, FormLabel, TextField } from '@material-ui/core';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Select, Tooltip, MenuItem } from '@material-ui/core';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { AddCircle, Edit, Delete, MoreHoriz } from '@material-ui/icons';


import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';


let strings=new LocalizedStrings({
	en:{
    title:'OEM',
    titleDialog:'New OEM',

    tableHeaderDistributor:'Distributor',
    tableHeaderName:'Name',
    tableHeaderDesignation:'Designation',
    tableHeaderPrefix: 'Prefix',
    tableHeaderNotes:'Notes',


    labelDistributor:'Distributor',
    labelName:'Name',
    labelDesignation:'Designation',
    labelPrefix:'Prefix',
    labelNotes:'Notes',

    buttonAdd:'New OEM',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    toolTipEdit:'Edit OEM',
    toolTipDelete:'Delete OEM',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this OEM?',
    yes: 'Yes',
    no: 'No',
	}
})

function SettingsOEMDistributor( props ) {

  const { classes } = props;

  const [ values, setValues ] = useState( { } );

  const { settingsOEMDistributorTableForceRefresh } = props;
  const { settingsOEMDistributorList } = props;


  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;

  useEffect(
    ( ) => {
      getSettingsOEMDistributor( page * rowsPerPage, rowsPerPage );
    },
    [ page, rowsPerPage, settingsOEMDistributorTableForceRefresh ]
  );


  return (
    <Box>
      <Paper className={ classes.workareaTopBoxes }>
        { settingsOEMDistributorList !== undefined &&
          <Box className={ classes.tableArea }>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderName }</Typography>
                  </TableCell> 
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderDesignation }</Typography>
                  </TableCell>  
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderPrefix }</Typography>
                  </TableCell>                                                     
                </TableRow>
              </TableHead>              
              <TableBody>
                { settingsOEMDistributorList.items.map( ( oem ) => ( 
                  <TableRow key={ oem.productfamily_id }>                    
                    
                    <TableCell style={{ width:'20%' }}>
                      <Link className={ classes.link } to={ '/myOEM/' + oem.productfamily_id + '/grid'}>
                        <Typography variant='body2'>{ oem.name }</Typography>
                      </Link>                    
                    </TableCell>
                    
                    <TableCell style={{ width:'20%' }}>{ oem.designation }</TableCell> 
                    <TableCell style={{ width:'20%' }}>{ oem.prefix }</TableCell> 
                                                     
                  </TableRow> ) )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 9 } count={ settingsOEMDistributorList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        { settingsOEMDistributorList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Paper>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMTableForceRefresh: state.settingsOEMTableForceRefresh,
    settingsOEMDistributorList: state.settingsOEMDistributorList,
  }
};

export default connect( mapStateToProps, {  })( withStyles( gStyles )( SettingsOEMDistributor ) );