import { store } from '../index';
import { setLicenseList, setLicenseSelected, setLicensePrinterList, setLicenseHistoryList, setLicenseEditionList, refreshLicenseTable, setAllowUpgrades } from '../utils/Reducer';
import { updateLicenseWithUpgrades } from '../utils/Utils';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';
import { colorArray } from '../utils/Styles';



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getLicenses
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getLicenses( from, limit, search, filterId ) {

  const onError = error => {
    gError( 'getLicenses', error );
    store.dispatch( setLicenseList( { count:0, items:[] } ) );
  }

  store.dispatch( setLicenseList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses?from=' + from + '&limit=' + limit + '&filterId=' + filterId + ( search === '' ? '' : ( '&search=' + search ) ) + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
        response.json().then( json => {
          json.items = json.items.map( ( license ) => {
            return updateLicenseWithUpgrades( license, store.getState().allowUpgrades );
          } )
          store.dispatch( setLicenseList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getLicense
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getLicense( licenseId ) {

  const onError = error => {
    gError( 'getLicense', error );
    store.dispatch( setLicenseSelected( undefined ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/' + licenseId + '?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setLicenseSelected( updateLicenseWithUpgrades( json, store.getState().allowUpgrades ) ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getLicensePrinters
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getLicensePrinters( licenseId, from, limit ) {

  const onError = error => {
    gError( 'getLicensePrinters', error );
    store.dispatch( setLicensePrinterList( { count:0, items:[] } ) );
  }

  store.dispatch( setLicensePrinterList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/' + licenseId + '/printers?from=' + from + '&limit=' + limit + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setLicensePrinterList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getLicenseHistory
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getLicenseHistory( licenseId, sort ) {

  const onError = error => {
    gError( 'getLicenseHistory', error );
    store.dispatch( setLicenseHistoryList( { count:0, items:[] } ) );
  }

  const sortSQL = sort ? 1 : 0;
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/' + licenseId + '/history?authToken=' + authToken + '&sort=' + sortSQL, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setLicenseHistoryList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getLicenseEditions
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getLicenseEditions( ) {

  const onError = error => {
    gError( 'getLicenseEditions', error );
    store.dispatch( setLicenseEditionList( defaultData ) );
  }

  const defaultData = { labels: [ ],
    datasets: [ { 
      data: [],
      backgroundColor: colorArray,
    } ]
  };

  store.dispatch( setLicenseEditionList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/editions?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        var licenseEditionList = { labels: json.map( edition => ( edition.edition ) ),
          datasets: [ { 
            data: json.map( edition => ( edition.count ) ),
            backgroundColor: defaultData.datasets[0].backgroundColor,
          } ]
        };
        store.dispatch( setLicenseEditionList( licenseEditionList ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// toogleLicense
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function toogleLicense( license ) {

  const onError = error => {
    gError( 'toogleLicense', error );
    store.dispatch( setLicenseList( { count:0, items:[] } ) );
  }

  store.dispatch( setLicenseList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/' + license.license + '/' + (license.active ? 'suspend' : 'activate') + '?&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( { 'distributorName' : license.distributor_name } )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshLicenseTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// startLicense
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function startLicense( license, upgradeTo ) {

  const onError = error => {
    gError( 'startLicense', error );
    store.dispatch( setLicenseList( { count:0, items:[] } ) );
  }

  store.dispatch( setLicenseList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/' + license.license + '/start?&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( { 'distributorName' : license.distributor_name, 'upgradeFrom': license.pcode, 'upgradeTo': upgradeTo } )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshLicenseTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// renewLicense
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function renewLicense( license, upgradeTo ) {

  const onError = error => {
    gError( 'renewLicense', error );
    store.dispatch( setLicenseList( { count:0, items:[] } ) );
  }

  store.dispatch( setLicenseList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/' + license.license + '/renew?&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( { 'distributorName' : license.distributor_name, 'upgradeFrom': license.pcode, 'upgradeTo': upgradeTo } )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshLicenseTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// upgradeLicense
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function upgradeLicense( license, upgradeTo ) {

  const onError = error => {
    gError( 'upgradeLicense', error );
    store.dispatch( setLicenseList( { count:0, items:[] } ) );
  }

  store.dispatch( setLicenseList( undefined ) );
  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/' + license.license + '/upgrade?authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( { 'distributorName' : license.distributor_name, 'upgradeFrom': license.pcode, 'upgradeTo': upgradeTo } )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshLicenseTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getAllowUpgrades
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getAllowUpgrades( ) {

  if ( store.getState().allowUpgradesFilled !== undefined )
    return;

  const onError = error => {
    gError( 'getAllowUpgrades', error );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/allowUpgrades?&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setAllowUpgrades( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getEvolisLicense
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getEvolisLicense( license, callback ) {

  const onError = error => {
    callback( undefined );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/' + license + '/evolis?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        callback( updateLicenseWithUpgrades( json, store.getState().allowUpgrades ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// upgradeEvolisLicense
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function upgradeEvolisLicense( license, upgradeTo, callback ) {

  const onError = error => {
    callback( false );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/licenses/' + license.license + '/evolis/upgrade?authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( { 'distributorName' : license.distributor_name, 'upgradeFrom': license.pcode, 'upgradeTo': upgradeTo } )
  } )
  .then( response => {
    if ( response.ok ) {
      callback( true );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}