import React, { useEffect, useState }  from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getFilters, setFilter, deleteFilter } from './FilterRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableBody, TableFooter, TableRow, TableCell, TablePagination } from '@material-ui/core';
import { Paper, Typography, CircularProgress, TextField, Button, IconButton, Grid, FormControl, InputLabel, Select, OutlinedInput, Tooltip } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { AddCircle, Edit, Delete, RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';

import TablePaginationActions from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    title:'Filters',
    titleDialog:'Filter',

    printerCounterLabel:'You have a total of {count} printer(s).',
    licenseCounterLabel:'You have a total of {count} license(s).',
    printerIdleLabel:'You have {count} idle printer(s) more than {value} day(s).',
    printerRibbonUsageLabel:'You have {count} printer(s) that are using ribbons under {value}%.',
    printerUsageLabel:'You have {count} printer(s) that printed more than {value} cards.',
    licenseExpireLabel:'You have {count} license(s) expired or expiring in {value} day(s).',

    printerIdleSelectLabel:'Printers inactive for more than ... day(s).',
    printerRibbonUsageSelectLabel:'Printers that are using ribbons under ... %.',
    printerUsageSelectLabel:'Printers that printed more than ... cards.',
    licenseExpireSelectLabel:'Licenses expiring in ... day(s).',

    labelType:'Type',
    labelValue:'Value',

    buttonAdd:'Add filter',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    toolTipEdit:'Edit the filter',
    toolTipDelete:'Delete the filter',
    toolTipSetDefault:'Apply the filter',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this filter?',
    yes: 'Yes',
    no: 'No',
	}
})

function FilterTable( props ) {

  const { classes } = props;

  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(4);
  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { filter_id: 0, type: '', value: '' } );
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );

  const { filterTableType } = props;
  const { printerTableForceRefresh } = props;
  const { filtersForceRefresh } = props;
  const { filterList } = props;

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const filterIdFromURL = searchParams.get( 'filter' ) === null ? -1 : Number(searchParams.get( 'filter' ));

  useEffect(
    ( ) => {
      getFilters( filterTableType, page * rowsPerPage, rowsPerPage );
    },
    [ filterTableType, page, rowsPerPage, filtersForceRefresh, printerTableForceRefresh ]
  );

  const handleChangePage = ( event, newPage ) => { 
    setPage(newPage);
  }

  const handleChangeRowsPerPage = event => {
    //Each value of rowsPerPage must be 2n(base), examples: 10, 20, 40; 4, 8, 16; 5, 10, 20, 40, 80
    var newRowsPerPage = parseInt(event.target.value, 10);
    setPage( Math.trunc( page * ( rowsPerPage / newRowsPerPage ) ) );
    setRowsPerPage( newRowsPerPage );
  }

  const handleChange = name => event => {

    switch ( name ) {
      case 'value': {

        var newValue = 0;
        if ( values.type === 202) {
          event.target.value = event.target.value === '100' ? values.value : event.target.value;
          newValue = Math.max(0, Number( event.target.value ) ).toString().slice( 0, 2 );
        }
        else if ( values.type === 203) {
          event.target.value = event.target.value === '1000000' ? values.value : event.target.value;
          newValue = Math.max(0, Number( event.target.value ) ).toString().slice( 0, 6 );
        }
        else {
          event.target.value = event.target.value === '1000' ? values.value : event.target.value;
          newValue = Math.max(0, Number( event.target.value ) ).toString().slice( 0, 3 );
        }

        setValues( { ...values, value: ( values.type === 202 ) ? newValue * 10 : newValue } );
        break;
      }
      case 'type': {
        setValues( { ...values, type: Number( event.target.value ), value: 0 } );
        break;
      }
      default:
        break;
    }
  };

  const handleAdd = () => {
    setEditMode( true );
    setValues( { filter_id: 0, type: filterTableType === 'license' ? 101 : 201, value: 0 } );
  }

  const onCheck = filter => {
    if ( filter.filter_id !== filterIdFromURL ) {
      var searchParams = new URLSearchParams( history.location.search );
      if ( filter.filter_id !== -1 )
        searchParams.set( 'filter', filter.filter_id );
      else
      searchParams.delete( 'filter' );
      searchParams.delete( 'page' ); 
      history.push( { search: searchParams.toString() } );
    }
  };

  const handleEdit = filter => {
    setEditMode( true );
    setValues( { filter_id: filter.filter_id, type: filter.type, value: filter.value } );
  };

  const onClose = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setEditMode( false );
    setFilter( values );
  };

  const handleDeleteDialogClose = confirm => {
    if ( confirm )
      deleteFilter( entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleDelete = filter => {
    setEntityToDelete( filter );
  };

  return (
    <div>
      <Paper className={ classes.workareaTopBoxes }>
        <div className={ classes.flexHeader }>
          <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
          <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( ) => handleAdd( ) }>
            <AddCircle className={ classes.leftIcon } />
            { strings.buttonAdd }
        </Button>
        </div>
        { filterList !== undefined &&
          <div className={ classes.tableArea }>
            <Table size='small'>
              <TableBody>
                { filterList.items.map( ( filter ) => ( 
                  <TableRow key={ filter.filter_id }>
                    { ( filter.count === undefined || ( filter.count !== undefined && filter.count <= 0 ) ) &&
                      <TableCell className={ classes.tableButton }/>
                    }
                    { filter.count !== undefined && filter.count > 0 &&
                      <TableCell className={ classes.tableButton } onClick={ ( ) => onCheck( filter ) }>
                        <Tooltip title={ strings.toolTipSetDefault } >
                          <div>
                            <IconButton size='small'>
                              { filter.filter_id === filterIdFromURL ? <RadioButtonChecked/> : <RadioButtonUnchecked/> }
                            </IconButton>
                          </div>
                        </Tooltip>
                      </TableCell>            
                    }
                    <TableCell>
                      { filter.type === 0 &&
                        <Typography variant='body2'>{ ( filterTableType === 'printer' ? strings.printerCounterLabel : strings.licenseCounterLabel )
                          .replace( '{count}', filter.count !== undefined ? filter.count : '?' ) }</Typography>
                      }
                      { filter.type === 101 &&
                        <Typography variant='body2'>{ strings.licenseExpireLabel
                          .replace('{count}', filter.count !== undefined ? filter.count : '?' )
                          .replace( '{value}', filter.value ) }</Typography>
                      }
                      { filter.type === 201 &&
                        <Typography variant='body2'>{ strings.printerIdleLabel
                          .replace( '{count}', filter.count !== undefined ? filter.count : '?' )
                          .replace( '{value}', filter.value ) }</Typography>
                      }
                      { filter.type === 202 &&
                        <Typography variant='body2'>{ strings.printerRibbonUsageLabel
                          .replace('{count}', filter.count !== undefined ? filter.count : '?' )
                          .replace( '{value}', filter.value / 10 ) }</Typography>
                      }
                      { filter.type === 203 &&
                        <Typography variant='body2'>{ strings.printerUsageLabel
                          .replace('{count}', filter.count !== undefined ? filter.count : '?' )
                          .replace( '{value}', filter.value ) }</Typography>
                      }
                    </TableCell>
                    { filter.type === 0 &&
                      <TableCell colSpan={ 2 } />
                    }
                    { filter.type !== 0 &&
                      <TableCell className={ classes.tableButton } >
                        <Tooltip title={ strings.toolTipEdit }>
                          <IconButton size='small' onClick={ ( ) => handleEdit( filter ) } >
                            <Edit/>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    }
                    { filter.type !== 0 &&
                      <TableCell className={ classes.tableButton } >
                        <Tooltip title={ strings.toolTipDelete }>
                          <IconButton size='small' onClick={ ( ) => handleDelete( filter ) } >
                            <Delete/>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    }
                  </TableRow> ) )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 4, 8 ] } colSpan={ 4 } count={ filterList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ handleChangePage }
                    onChangeRowsPerPage={ handleChangeRowsPerPage }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        }
        { filterList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Paper>
      <Dialog open={ editMode }>
        <div className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleDialog }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>
              
              <Grid item xs={9}>
                <FormControl variant='outlined'>
                  <InputLabel required>{ strings.labelType }</InputLabel>
                  <Select
                    native
                    value={ values.type }
                    onChange={ handleChange('type') }
                    input={ <OutlinedInput labelWidth={ 50 } name='type'/>}>
                    { filterTableType === 'license' && <option value={101}>{ strings.licenseExpireSelectLabel }</option> }
                    { filterTableType === 'printer' && <option value={201}>{ strings.printerIdleSelectLabel }</option> }
                    { filterTableType === 'printer' && <option value={202}>{ strings.printerRibbonUsageSelectLabel }</option> }
                    { filterTableType === 'printer' && <option value={203}>{ strings.printerUsageSelectLabel }</option> }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <TextField variant='outlined' required fullWidth id='value'
                  label={ strings.labelValue } value={ values.type !== 202 ? values.value : values.value / 10 } onChange={ handleChange('value') }
                  type='number'/>
              </Grid>

              <Grid item xs={12}>
                <div className={ classes.lineButtonBox }>
                  <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
                  <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => onClose() }>{ strings.buttonCancel }</Button>
                </div>
              </Grid>

            </Grid>            
          </form>
        </div>       
      </Dialog>
      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    filtersForceRefresh: state.filtersForceRefresh,
    printerTableForceRefresh: state.printerTableForceRefresh,
    filterList: state.filterList,
  }
};

export default connect( mapStateToProps, {  })( withStyles( gStyles )( FilterTable ) );