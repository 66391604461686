import React, { useEffect, useState, useRef }  from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMUpgrades, setSettingsOEMUpgrades } from './SettingsRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, FormLabel, TextField } from '@material-ui/core';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Icon, Tooltip } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';


let strings=new LocalizedStrings({
	en:{
    title:'OEM Upgrade',
    titleDialog:'Edit Upgrade',

    tableHeaderCode:'Product Code',
    tableHeaderBase:'Product Base',
    tableHeaderDescription:'Product Description',


    labelCode:'Product Code',
    labelBase:'Product Base',
    labelDescription:'Description',

    buttonAdd:'Add Upgrade',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    toolTipEdit:'Edit Upgrade',
    toolTipDelete:'Delete Upgrade',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this upgrade?',
    yes: 'Yes',
    no: 'No',
	}
})

function SettingsOEMDetailUpgrades( props ) {

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { } );

  const { settingsOEMUpgradesTableForceRefresh } = props;
  const { settingsOEMUpgradesList } = props;
  const { oemId } = useParams();


  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;
  const fileUploader = useRef(null);


  useEffect(
    ( ) => {
      getSettingsOEMUpgrades( page * rowsPerPage, rowsPerPage, oemId );
    },
    [ page, rowsPerPage, settingsOEMUpgradesTableForceRefresh ]
  );

  const handleChange = name => event => {

    switch ( name ) {
      case 'code': {
        setValues( { ...values, code: event.target.value } );
        break;
      }
      case 'base': {
        setValues( { ...values, base: event.target.value } );
        break;
      }
      case 'description': {
        setValues( { ...values, description: event.target.value } );
        break;
      }

      default:
        break;
    }
  };

  const handleEdit = upgrade => {
    setEditMode( true );
    setValues( {
      oem_id: upgrade.productfamily_id,
      code: upgrade.product_code,
      base: upgrade.product_base,
      description: upgrade.product_description} );
  };

  const handleClose = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setEditMode( false );
    setSettingsOEMUpgrades( values );
  };

  return (
    <Box>        
      <Paper className={ classes.workareaTopBoxes }>
        <Box className={ classes.flexHeader }>
          <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
        </Box>
        { settingsOEMUpgradesList !== undefined &&
          <Box className={ classes.tableArea }>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderCode }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderBase }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderDescription }</Typography>
                  </TableCell>                                                   
                  <TableCell/>
                </TableRow>
              </TableHead>              
              <TableBody>
                { settingsOEMUpgradesList.items.map( ( upgrade ) => ( 
                  <TableRow key={ upgrade.upgradeId }>
                    <TableCell style={{ width:'25%' }}>{ upgrade.product_code }</TableCell>        
                    <TableCell style={{ width:'25%' }}>{ upgrade.product_base }</TableCell>                                  
                    <TableCell style={{ width:'40%' }}>{ upgrade.product_description }</TableCell>                     
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipEdit }>
                        <IconButton size='small' onClick={ ( ) => handleEdit( upgrade ) } >
                          <Edit/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow> ) )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 9 } count={ settingsOEMUpgradesList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        { settingsOEMUpgradesList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Paper>
      
      <Dialog open={ editMode } style={ { zIndex:0, height:'900px' } }>
        <Box className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleDialog }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

            <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='code' disabled
                  label={ strings.labelCode } value={ values.code }  onChange={ handleChange('code') }/>
            </Grid>

            <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='base' disabled
                  label={ strings.labelBase } value={ values.base } onChange={ handleChange('base') } />
            </Grid>

            <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='description'
                  label={ strings.labelDescription } value={ values.description } onChange={ handleChange('description') } />
            </Grid>

            </Grid>

            <Box className={ classes.lineButtonBox }>
              <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
              <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleClose() }>{ strings.buttonCancel }</Button>
            </Box> 
          </form>
        </Box>       
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMUpgradesTableForceRefresh: state.settingsOEMUpgradesTableForceRefresh,
    settingsOEMUpgradesList: state.settingsOEMUpgradesList,
  }
};

export default connect( mapStateToProps, {  })( withStyles( gStyles )( SettingsOEMDetailUpgrades ) );