import React, { useEffect, useState }  from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEMUpgrades, setSettingsOEMUpgrades } from './SettingsRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, TextField } from '@material-ui/core';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Tooltip, Dialog } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    title:'Upgrades',
    titleDialog:'Upgrades',

    tableHeaderProductCode:'Product Code',
    tableHeaderProductPublic:'My Product Code',
    tableHeaderProductDescription:'Product Description',
    tableHeaderPicture:'Picture',
    tableHeaderLink:'Link',

    labelProductCode:'Product Code',
    labelProductPublic:'My Product Code',
    labelProductDescription:'Product Description',
    labelPicture:'Picture',
    labelLink:'Link',

    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    toolTipEdit:'Edit the Upgrade',
    toolTipDelete:'Delete the Upgrade',
    toolTipDeletePicture:'Remove featured picture',
    toolTipEditPicture:'Click the picture to edit',

    yes: 'Yes',
    no: 'No',
	}
})

function SettingsOEMUpgrades( props ) {

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { } );

  const { settingsOEMUpgradesTableForceRefresh } = props;
  const { settingsOEMUpgradesList } = props;

  const { oemId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;

  useEffect(
    ( ) => {
      getSettingsOEMUpgrades( page * rowsPerPage, rowsPerPage, oemId );
    },
    [ page, rowsPerPage, settingsOEMUpgradesTableForceRefresh ]
  );

  const handleChange = name => event => {

    switch ( name ) {
      case 'description': {
        setValues( { ...values, description: event.target.value } );
        break;
      }
      case 'title': {
        setValues( { ...values, title: event.target.value } );
        break;
      }

      default:
        break;
    }
  };

  const handleEdit = upgrade => {
    setEditMode( true );
    setValues( {
      oem_id: upgrade.productfamily_id,
      code: upgrade.product_code,
      base: upgrade.product_base,      
      title: upgrade.product_public,
      description: upgrade.product_description } );
  };

  const handleClose = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setEditMode( false );
    setSettingsOEMUpgrades( values );
  };

  return (
    <Box>        
      <Paper className={ classes.workareaTopBoxes }>
        <Box className={ classes.flexHeader }>
          <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
        </Box>
        { settingsOEMUpgradesList !== undefined &&
          <Box className={ classes.tableArea }>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderProductCode }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderProductPublic }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderProductDescription }</Typography>
                  </TableCell>                                                     
                  <TableCell/>
                </TableRow>
              </TableHead>              
              <TableBody>
                { settingsOEMUpgradesList.items.map( ( upgrade ) => ( 
                  <TableRow key={ upgrade.product_code }>
                    <TableCell>{ upgrade.product_code }</TableCell>        
                    <TableCell>{ upgrade.product_public }</TableCell>                                  
                    <TableCell>{ upgrade.product_description }</TableCell>                                                         
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipEdit }>
                        <IconButton size='small' onClick={ ( ) => handleEdit( upgrade ) } >
                          <Edit/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow> ) )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 9 } count={ settingsOEMUpgradesList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        { settingsOEMUpgradesList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Paper>
      
      <Dialog open={ editMode } style={ { zIndex:0, height:'900px' } }>
        <Box className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleDialog }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

            <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='name' disabled
                  label={ strings.labelProductCode } value={ values.name } />
            </Grid>

            <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='title'
                  label={ strings.labelProductPublic } value={ values.title } onChange={ handleChange('title') } />
            </Grid>

            <Grid item xs={12}>
                <TextField fullWidth variant='outlined' margin='dense' id='description'
                  label={ strings.labelProductDescription } value={ values.description } onChange={ handleChange('description') } />
            </Grid>

            </Grid>

            <Box className={ classes.lineButtonBox }>
              <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
              <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleClose() }>{ strings.buttonCancel }</Button>
            </Box> 
          </form>
        </Box>       
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMUpgradesTableForceRefresh: state.settingsOEMUpgradesTableForceRefresh,
    settingsOEMUpgradesList: state.settingsOEMUpgradesList,
  }
};

export default connect( mapStateToProps, {  })( withStyles( gStyles )( SettingsOEMUpgrades ) );