import React, { useEffect, useState }  from 'react';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { logout, setChangePass } from './utils/Reducer';
import { getAllowUpgrades } from './license/LicenseRest';
import { gStyles } from './utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Paper, Box, AppBar, Toolbar, Typography, IconButton, Fab, Tabs, Tab, Menu, MenuItem, Divider } from '@material-ui/core';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Person, ArrowDropDown, NewReleases } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';

import ChangePassword from './login/ChangePassword';
import DistributorsDashboard from './distributor/DistributorsDashboard';
import DistributorDetailDashboard from './distributor/DistributorDetailDashboard';
import CustomersDashboard from './customer/CustomersDashboard';
import CustomerDetailDashboard from './customer/CustomerDetailDashboard';
import LicensesDashboard from './license/LicensesDashboard';
import LicenseDetailDashboard from './license/LicenseDetailDashboard';
import PrintersDashboard from './printer/PrintersDashboard';
import PrinterDetailDashboard from './printer/PrinterDetailDashboard';
import SettingsDashboard from './settings/SettingsDashboard';
import SettingsOEMDashboard from './settings/SettingsOEMDashboard';
import SettingsNotifications from './settings/SettingsNotifications';
import SettingsWhatsNew from './settings/SettingsWhatsNew';
import SettingsLearning from './settings/SettingsLearning';
import SettingsOEM from './settings/SettingsOEM';
import SettingsOEMDetailDashboard from './settings/SettingsOEMDetailDashboard';
import SettingsOEMDistributor from './settings/SettingsOEMDistributor';

import logo from './resources/logoCPpt.png';
import topbanner from './resources/topbanner.png';
import distributorsIcon from './resources/customersIcon.png';
import { SettingsApplications } from '@material-ui/icons';
import customersIcon from './resources/customersIcon.png';
import printersIcon from './resources/printersIcon.png';
import licenseIcon from './resources/licenseIcon.png';

let strings=new LocalizedStrings({
	en:{
    tabDistributors:'Distributors',
    tabSettings:'Settings',
    tabSettingsOEM:'MyOEMs',
    tabCustomers:'Customers',
    tabLicenses:'Licenses',
    tabPrinters:'Printers',

    menuChangePass:'Change password',
    menuLogout:'Logout',

    menuSettings: 'Settings',
    settingsNotifications: 'Notifications',
    settingsWhatsNew:'What\'s New',
    settingsLearning: 'Learning',
    settingsOEM: 'OEMs',
    settingsMyOEMs: 'MyOEMs',
	}
})


function Main( props ) {

  const { classes } = props;

  const { userEmail, userType, allowFeatures } = props;
  const { needPassword } = props;
  const { changePass, setChangePass } = props;
  const { logout } = props;
  
  const [ openDrawer, setOpenDrawer ] = useState( false );
  const [ userMenuEl, setUserMenuEl ] = useState( null );
  const [ settingsEl, setSettingsEl ] = useState( null );

  let history = useHistory();

  useEffect(
    () => {
      if ( history.location.pathname === '/' )
        history.replace( userType === 0 ?'/distributors':'/licenses' );
      getAllowUpgrades();
    },
    [ userType, history ]
  );

  const handleUserMenuClick = event => {
    setUserMenuEl( event.currentTarget );
  };

  const handleSettingsClick = event => {
    setSettingsEl( event.currentTarget );
  };

  const handleUserMenuClose = () => {
    setUserMenuEl( null );
  };

  const handleSettingsClose = () => {
    setSettingsEl( null );
  };


  const handleLogout = () => {
    handleUserMenuClose( );
    logout();
    history.push('/');
  }

  const handleChangePass = () => {
    handleUserMenuClose( );
    setChangePass( true );
  }

  const handleSettingsTab = name => event => {
    history.replace( '/settings/'+[name] )
  }

  const handleSettingsTabMyOEMs = name => event => {
    history.replace( '/'+[name] )
  }

  const currentTab = ( loc ) => {
    if ( loc.pathname === '/' ) return 'licenses';
    var tab = loc.pathname.split('/')[1];
    if ( tab === 'distributors' || tab === 'customers' || tab === 'licenses'  || tab === 'printers' || tab === 'settings' )
      return tab;
    return false;
  }

  const handleOpenDrawer = () => {
    setOpenDrawer( !openDrawer );
  }

  const handleLogoClick = () => {
    history.replace(userType === 0 ? '/distributors' : '/licenses');
  };

  return (
    <Box>
      { ( needPassword || changePass ) &&
        <ChangePassword/>
      }
      { !( needPassword || changePass ) &&
        <Box>
          <Box className={ classes.appBarBox }>
            <AppBar className={ classes.appBar }>
              <Toolbar>
                {/* <Link to="/" style={{ textDecoration: 'none' }}>
                  <img className={classes.appBarLogoImg} src={logo} alt='' />
                </Link> */}
                  <img
                    className={classes.appBarLogoImg}
                    src={logo}
                    alt=''
                    onClick={handleLogoClick}
                    style={{ cursor: 'pointer' }}
                  />
                <Typography variant='h6'> { strings.title } </Typography>
                <Route
                  render={({ location }) => ( 
                    <Tabs className={ classes.flexExpand } value={ currentTab( location ) } onChange={ () => setOpenDrawer( false ) }>
                      { (userType === 0 || (userType === 1 && (allowFeatures & 4) === 4)) &&
                        <Tab label={ strings.tabDistributors } icon={ <img src={ distributorsIcon } alt=''/> } value='distributors' component={ Link } to='/distributors'/>
                      }
                      <Tab label={ strings.tabCustomers } icon={ <img src={ customersIcon } alt=''/> } value='customers' component={ Link } to='/customers'/>
                      <Tab label={ strings.tabLicenses } icon={ <img src={ licenseIcon } alt=''/> } value='licenses' component={ Link } to='/licenses'/>
                      <Tab label={ strings.tabPrinters } icon={ <img src={ printersIcon } alt=''/> } value='printers' component={ Link } to='/printers'/>
                      { userType === 10 &&
                      <Tab label={ strings.tabSettings } icon={ <img src={ printersIcon } alt=''/> } value='settings' component={ Link } to='/settings/OEM'/>
                      }
                    </Tabs>
                ) } />

                
                { userType === 10 &&
                <Typography variant='subtitle2'> SETTINGS </Typography>
                }
                { userType === 10 &&
                <IconButton color='inherit' onClick={ handleSettingsClick }><SettingsIcon/></IconButton>
                }
                <Menu
                  getContentAnchorEl={ null }
                  anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
                  transformOrigin={ { vertical: 'top', horizontal: 'left' } }
                  anchorEl={ settingsEl }
                  keepMounted
                  open={ Boolean(settingsEl) }
                  onClose={ handleSettingsClose } >
                  <MenuItem onClick={ handleSettingsTab('notifications') }><Typography variant='body1'>{ strings.settingsNotifications }</Typography></MenuItem>
                  <Divider/>
                  <MenuItem onClick={ handleSettingsTab('whatsnew') }><Typography variant='body1'>{ strings.settingsWhatsNew }</Typography></MenuItem>
                  <Divider/>
                  <MenuItem onClick={ handleSettingsTab('learning') }><Typography variant='body1'>{ strings.settingsLearning }</Typography></MenuItem>
                  <Divider/>
                  { userType === 10 &&
                  <MenuItem onClick={ handleSettingsTab('OEM') }><Typography variant='body1'>{ strings.settingsOEM }</Typography></MenuItem>
                  }
                  { userType === 10 &&
                  <MenuItem onClick={ handleSettingsTabMyOEMs('MyOEMs') }><Typography variant='body1'>{ strings.settingsMyOEMs }</Typography></MenuItem>
                  }
                </Menu>
                <Box className={ classes.appUserBox }>
                  <Person className={ classes.appUserItem } fontSize='large'/>
                  <Typography className={ classes.appUserItem } variant='caption'>{ userEmail }</Typography>
                </Box>
                <IconButton color='inherit' onClick={ handleUserMenuClick }><ArrowDropDown/></IconButton>
                <Menu
                  getContentAnchorEl={ null }
                  anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
                  transformOrigin={ { vertical: 'top', horizontal: 'left' } }
                  anchorEl={ userMenuEl }
                  keepMounted
                  open={ Boolean(userMenuEl) }
                  onClose={ handleUserMenuClose } >
                  <MenuItem onClick={ handleChangePass }><Typography variant='body1'>{ strings.menuChangePass }</Typography></MenuItem>
                  <Divider/>
                  <MenuItem onClick={ handleLogout }><Typography variant='body1'>{ strings.menuLogout }</Typography></MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>
            <Box className={ classes.appBarBanner }>
              <img src={ topbanner } alt=''/>
            </Box>
          </Box>
          
          { userType === 1 && (allowFeatures & 4) === 4 &&
          <Drawer open={Boolean(openDrawer)} elevation={0} variant='persistent' style={{ width : "181px"}} >
              <Box className={ classes.appDrawer }>
                <Fab className={ classes.appDrawerSettingsButton } onClick={handleOpenDrawer}> 
                  <SettingsApplications fontSize = 'large'/>
                </Fab>
                <List>
                  <ListItem>
                    <ListItemText primary="Settings" />
                  </ListItem>
                  <Link to="/settings/notifications" style={{ textDecoration: 'none' }}>
                    <ListItem button>
                        <ListItemIcon>
                          <NewReleases />
                        </ListItemIcon>
                        <ListItemText primary="Notifications" />
                    </ListItem>
                  </Link>
                </List>
              </Box>
          </Drawer>
          }


          <Box className={ classes.appDashboard }>
            <Switch >
              <Route path='/distributors/:distributorId'><DistributorDetailDashboard/></Route>
              <Route path='/distributors'><DistributorsDashboard/></Route>
              <Route path='/customers/:customerId'><CustomerDetailDashboard/></Route>
              <Route path='/customers'><CustomersDashboard/></Route>
              <Route path='/licenses/:licenseId'><LicenseDetailDashboard/></Route>
              <Route path='/licenses'><LicensesDashboard/></Route>
              <Route path='/printers/:printerId'><PrinterDetailDashboard/></Route>
              <Route path='/printers'><PrintersDashboard/></Route>
              <Route path='/settings/notifications'><SettingsNotifications/></Route>
              <Route path='/settings/whatsnew'><SettingsWhatsNew/></Route>
              <Route path='/settings/learning'><SettingsLearning/></Route>
              <Route path='/settings/OEM'><SettingsOEM/></Route>
              <Route path='/settings/:oemId'><SettingsOEMDetailDashboard/></Route>
              <Route path='/myOEMs'><SettingsOEMDistributor/></Route>
              <Route path='/myOEM/:oemId'><SettingsOEMDashboard/></Route>
            </Switch>
          </Box>
          { userType === 1 && (allowFeatures & 4) === 4 &&
          <Fab className={ classes.appSettingsButton } onClick={handleOpenDrawer}> 
            <SettingsApplications fontSize = 'large'/>
          </Fab>
          }

        </Box>
      }
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    userEmail: state.userEmail,
    userType: state.userType,
    allowFeatures: state.allowFeatures,
    needPassword: state.needPassword,
    changePass: state.changePass,
  }
};

export default connect( mapStateToProps, { logout, setChangePass } )( withStyles( gStyles )( Main ) );
