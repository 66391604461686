import React, { useEffect, useState, useRef }  from 'react';
import { Route, Switch, Link, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsOEM, setSettingsOEM, deleteSettingsOEM } from './SettingsRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, FormLabel, TextField } from '@material-ui/core';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Select, Tooltip, MenuItem } from '@material-ui/core';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { AddCircle, Edit, Delete, MoreHoriz } from '@material-ui/icons';


import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';


let strings=new LocalizedStrings({
	en:{
    title:'OEMs',
    titleDialog:'OEM',

    tableHeaderDistributor:'Distributor',
    tableHeaderName:'Name',
    tableHeaderDesignation:'Designation',
    tableHeaderPrefix: 'Prefix',
    tableHeaderNotes:'Notes',


    labelDistributor:'Distributor',
    labelName:'Name',
    labelDesignation:'Designation',
    labelPrefix:'Prefix',
    labelNotes:'Notes',

    buttonAdd:'New OEM',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    toolTipEdit:'Edit OEM',
    toolTipDelete:'Delete OEM',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this OEM?',
    yes: 'Yes',
    no: 'No',
	}
})

function SettingsOEM( props ) {

  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { } );
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );

  const { settingsOEMTableForceRefresh } = props;
  const { settingsOEMList } = props;
  const { settingsDistributorsList } = props;

  const [ state, setState ] = useState( { hover: false } );   

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;
  const fileUploader = useRef(null);

  useEffect(
    ( ) => {
      getSettingsOEM( page * rowsPerPage, rowsPerPage );
    },
    [ page, rowsPerPage, settingsOEMTableForceRefresh ]
  );

  const handleChange = name => event => {

    switch ( name ) {
      case 'distributor': {
        setValues( { ...values, distributor: Number( event.target.value ) } );
        break;
      }
      case 'name': {
        setValues( { ...values, name: event.target.value } );
        break;
      }
      case 'designation': {
        setValues( { ...values, designation: event.target.value } );
        break;
      }
      case 'prefix': {
        setValues( { ...values, prefix: event.target.value } );
        break;
      }
      case 'notes': {
        setValues( { ...values, notes: event } );
        break;
      }

      default:
        break;
    }
  };


  const handleAdd = () => {
    setEditMode( true );
    setValues( {
      oem_id: 0,
      distributor_id: 0,
      name: '',
      designation: '',
      prefix: '',
      notes:'' } );
  }

  const handleEdit = oem => {
    setEditMode( true );
    setValues( {
      oem_id: oem.productfamily_id,
      distributor_id: oem.distributor,
      name: oem.name,
      designation: oem.designation,
      prefix: oem.prefix,
      notes: oem.notes } );
  };

  const handleClose = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setEditMode( false );
    setSettingsOEM( values );
  };

  const handleDeleteDialogClose = confirm => {
    if ( confirm )
    deleteSettingsOEM( entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleDelete = oem => {
    setEntityToDelete( oem );
  };

  return (
    <Box>
      <Paper className={ classes.workareaTopBoxes }>
        <Box className={ classes.flexHeader }>
          <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
          <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( ) => handleAdd( ) }>
            <AddCircle className={ classes.leftIcon } />
            <Typography variant='button'>{ strings.buttonAdd }</Typography>
        </Button>
        </Box>
        { settingsOEMList !== undefined &&
          <Box className={ classes.tableArea }>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderDistributor }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderName }</Typography>
                  </TableCell> 
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderDesignation }</Typography>
                  </TableCell>  
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderPrefix }</Typography>
                  </TableCell> 
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderNotes }</Typography>
                  </TableCell>                                                     
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>              
              <TableBody>
                { settingsOEMList.items.map( ( oem ) => ( 
                  <TableRow key={ oem.productfamily_id }>                    
                    <TableCell style={{ width:'20%' }}>{ oem.distributor_id }</TableCell>
                    
                    <TableCell style={{ width:'20%' }}>
                      <Link className={ classes.link } to={ '/settings/' + oem.productfamily_id + '/editions' }>
                        <Typography variant='body2'>{ oem.name }</Typography>
                      </Link>                    
                    </TableCell>
                    
                    <TableCell style={{ width:'20%' }}>{ oem.designation }</TableCell> 
                    <TableCell style={{ width:'20%' }}>{ oem.prefix }</TableCell> 
                    <TableCell>
                      <Tooltip
                        title={ <SunEditor
                                  setContents={ oem.notes }
                                  setOptions={{
                                    mode:'balloon',
                                    resizingBar: false,
                                    width: '300px',
                                    buttonList: [ ] }} />
                        } >
                        <MoreHoriz/>
                      </Tooltip>
                    </TableCell>                                       
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipEdit }>
                        <IconButton size='small' onClick={ ( ) => handleEdit( oem ) } >
                          <Edit/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipDelete }>
                        <IconButton size='small' onClick={ ( ) => handleDelete( oem ) } >
                          <Delete/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow> ) )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 9 } count={ settingsOEMList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        { settingsOEMList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Paper>
 
      <Dialog open={ editMode } style={ { zIndex:0, height:'900px' } }>
        <Box className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleDialog }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

            <Grid item xs={6}>
                <FormControl variant='outlined'>
                  <InputLabel required>{ strings.labelDistributor }</InputLabel>
                  <Select
                    native
                    value={ values.distributor }
                    onChange={ handleChange('distributor') }
                    input={ <OutlinedInput labelWidth={ 75 } name='distributor'/> }>
                    { names.map((distributor, index) => (
                      <MenuItem key={index} value={index} >
                      {distributor}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField required fullWidth id='name' autoFocus
                    label={ strings.labelName } value={ values.name } onChange={ handleChange('name') }
                    inputProps={ { maxLength: 64 } }/>
              </Grid>

              <Grid item xs={6}>
                <TextField required fullWidth id='designation' autoFocus
                    label={ strings.labelDesignation } value={ values.designation } onChange={ handleChange('designation') }
                    inputProps={ { maxLength: 64 } }/>
              </Grid>

              <Grid item xs={6}>
                <TextField required fullWidth id='prefix' autoFocus
                    label={ strings.labelPrefix } value={ values.prefix } onChange={ handleChange('prefix') }
                    inputProps={ { maxLength: 8 } }/>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant='outlined' required>
                  <FormLabel><Typography variant='caption'>{ strings.labelNotes }</Typography></FormLabel>
                  <SunEditor
                    setContents={ values.notes }
                    onChange={ handleChange( 'notes' ) }
                    setOptions={{
                      mode:'balloon',
                      resizingBar: false,
                      width: '275px',
                      buttonList: [ ['undo', 'redo'], ['formatBlock'], ['bold', 'underline', 'italic', 'align'], ['link' ], ] }} />
                </FormControl>
              </Grid>

            </Grid>

            <Box className={ classes.lineButtonBox }>
              <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
              <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleClose() }>{ strings.buttonCancel }</Button>
            </Box> 
          </form>
        </Box>       
      </Dialog>

      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsOEMTableForceRefresh: state.settingsOEMTableForceRefresh,
    settingsOEMList: state.settingsOEMList,
    settingsDistributorsList: state.settingsDistributorsList,

  }
};

export default connect( mapStateToProps, {  })( withStyles( gStyles )( SettingsOEM ) );