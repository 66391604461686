import React, { useEffect }  from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getLicenseEditions } from './LicenseRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Paper, Typography, CircularProgress } from '@material-ui/core';
import { Pie } from 'react-chartjs-3';

let strings=new LocalizedStrings({
	en:{
    title:'Licenses by Editions',
	}
})

function LicenseByEdition( props ) {

  const { classes } = props;
  
  const { licenseEditionList } = props;

  useEffect(
    ( ) => {
      getLicenseEditions( );
    },
    []
  );

  return (
    <Paper className={ classes.workareaTopBoxes }>
      <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
      { licenseEditionList !== undefined &&
        <div className={ classes.piechart }>
          <Pie data={ licenseEditionList }
            legend={ { display: true, position: 'right' } }
            options={ { responsive: true, maintainAspectRatio: false } }/>
        </div>
      }
      { licenseEditionList === undefined &&
        <CircularProgress className={ classes.spinner }/>
      }
    </Paper>
  );
}

const mapStateToProps = state => { 
  return {
    licenseEditionList: state.licenseEditionList,
  }
};

export default connect( mapStateToProps, { })( withStyles( gStyles )( LicenseByEdition ) );