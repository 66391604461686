import React, { useEffect, useState, useRef }  from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getSettingsLearning, setSettingsLearning, deleteSettingsLearning } from './SettingsRest';
import { formatDateEpoch } from '../utils/Utils';
import { gStyles } from '../utils/Styles';
import DropzonePic from '../components/DropzonePic';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination, FormLabel, TextField } from '@material-ui/core';
import { Box, Paper, Typography, CircularProgress, Button, IconButton, Grid, Select, Tooltip, Checkbox } from '@material-ui/core';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { DateTimePicker } from "@material-ui/pickers";
import { AddCircle, Edit, Delete, MoreHoriz } from '@material-ui/icons';
import ImageIcon from '@material-ui/icons/Image';
import Icon from '@material-ui/core/Icon';

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    title:'Learning',
    titleDialog:'Learning',

    tableHeaderActive:'Active',
    tableHeaderPicture:'Picture',
    tableHeaderLink:'Link',
    tableHeaderCategory:'Category',
    tableHeaderMsg:'Message',
    tableHeaderStart:'Starts',
    tableHeaderEnd:'Expires',

    templatesLabel:'Templates',
    softwareLabel:'Software',
    newsLabel:'News',
    otherLabel:'Other',

    neverExpiresLabel:'<never Expires>',

    labelPicture:'Picture',
    labelLink:'Link',
    labelCategory:'Category',
    labelValue:'Value',
    labelMsg:'Message',
    labelPersistent:'Persistent',
    labelNeverExpires:'Never expires',
    labelStartDate:'Start Date',
    labelEndDate:'End Date',

    buttonAdd:'Add Learning',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    toolTipEdit:'Edit the Learning',
    toolTipDelete:'Delete the Learning',
    toolTipDeletePicture:'Remove featured picture',
    toolTipEditPicture:'Click the picture to edit',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete this Learning?',
    yes: 'Yes',
    no: 'No',
	}
})

function SettingsLearning( props ) {

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { } );
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );

  const { settingsLearningTableForceRefresh } = props;
  const { settingsLearningList } = props;

  const [ state, setState ] = useState( { hover: false } );   

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;
  const fileUploader = useRef(null);

  useEffect(
    ( ) => {
      getSettingsLearning( page * rowsPerPage, rowsPerPage );
    },
    [ page, rowsPerPage, settingsLearningTableForceRefresh ]
  );

  const startNewFile = ( file ) => {
    var reader = new FileReader();
    reader.onload = () => {
     setValues( { ...values, picture: reader.result } );
    };
    reader.readAsDataURL(file);
  }

  const handleChange = name => event => {

    switch ( name ) {
      case 'delPicture': {
        setValues( { ...values, picture: '' } );
        break;
      }
      case 'link': {
        setValues( { ...values, link: event.target.value } );
        break;
      }
      case 'category': {
        setValues( { ...values, category: Number( event.target.value ), value: 0 } );
        break;
      }
      case 'msg': {
        setValues( { ...values, msg: event } );
        break;
      }
      case 'persistent': {
        setValues( { ...values, persistent: Number(!values.persistent) } );
        break;
      }     
      case 'start_date': {
        setValues( { ...values, start_date: event.getTime() / 1000 } );
        break;
      }
      case 'end_date': {
        setValues( { ...values, end_date: event.getTime() / 1000 } );
        break;
      }

      default:
        break;
    }
  };

  const mouseOver = () => {
    setState({ hover: true });
    }

  const mouseOut = () => {
    setState({ hover: false });
  }

  const onPictureEdit = ( event ) => {
    var reader = new FileReader();
    reader.onload = () => {
      setValues( { ...values, picture: reader.result } );
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  const handleEditPicClick = () => {
    fileUploader.current.click();
  }

  const handleAdd = () => {
    setEditMode( true );
    var now = new Date().getTime() / 1000;
    setValues( {
      learning_id: 0,
      active: 1,
      picture: '',
      link: '',
      category: 801,
      msg: '',
      msgShow: '',
      persistent: 1,
      start_date: now,
      end_date: now + 2592000 } ); //30 days
  }

  const handleActive = ( learning ) => {
    setSettingsLearning( { ...learning, active: !learning.active } );
  }

  const handleEdit = learning => {
    setEditMode( true );
    setValues( {
      learning_id: learning.notification_id,
      active: learning.active,
      picture: learning.picture,
      link: learning.link,
      category: learning.category,
      msg: learning.msg,
      msgShow: learning.msg,
      persistent: learning.persistent,
      start_date: learning.start_date,
      end_date: learning.end_date } );
  };

  const handleClose = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setEditMode( false );
    setSettingsLearning( values );
  };

  const handleDeleteDialogClose = confirm => {
    if ( confirm )
    deleteSettingsLearning( entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleDelete = learning => {
    setEntityToDelete( learning );
  };

  return (
    <Box>
      <Paper className={ classes.workareaTopBoxes }>
        <Box className={ classes.flexHeader }>
          <Typography variant='h4' className={ classes.title }>{ strings.title }</Typography>
          <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( ) => handleAdd( ) }>
            <AddCircle className={ classes.leftIcon } />
            <Typography variant='button'>{ strings.buttonAdd }</Typography>
        </Button>
        </Box>
        { settingsLearningList !== undefined &&
          <Box className={ classes.tableArea }>

            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderActive }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderCategory }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderPicture }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderLink }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderMsg }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderStart }</Typography>
                  </TableCell>                                                      
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderEnd }</Typography>
                  </TableCell>                                                      
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>              
              <TableBody>
                { settingsLearningList.items.map( ( learning ) => ( 
                  <TableRow key={ learning.notification_id }>
                    <TableCell padding="checkbox">
                      <Checkbox checked={ learning.active === 1 } onClick={ ( ) => handleActive( learning ) }/>
                    </TableCell>                     
                    <TableCell>
                      { learning.category === 801 &&
                        <Typography variant='body2'>{ strings.templatesLabel }</Typography>
                      }
                      { learning.category === 802 &&
                        <Typography variant='body2'>{ strings.softwareLabel }</Typography>
                      }
                      { learning.category === 803 &&
                        <Typography variant='body2'>{ strings.newsLabel }</Typography>
                      }
                      { learning.category === 804 &&
                        <Typography variant='body2'>{ strings.otherLabel }</Typography>
                      }
                    </TableCell>
                    <TableCell>
                    <Tooltip title={state.hover ? (<img src={learning.picture} height={ 100 } width={ 100 } alt="" />) : ''}>
                      <Icon size='small' onMouseOver={mouseOver} onMouseOut={mouseOut} >
                        <ImageIcon/>  
                      </Icon> 
                    </Tooltip>
                    </TableCell>
                    <TableCell style={{ width:'20%' }}>{ learning.link }</TableCell>
                    <TableCell>
                      <Tooltip
                        title={ <SunEditor
                                  setContents={ learning.msg }
                                  setOptions={{
                                    mode:'balloon',
                                    resizingBar: false,
                                    width: '300px',
                                    buttonList: [ ] }} />
                        } >
                        <MoreHoriz/>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ learning.persistent !==  1 ? formatDateEpoch( learning.start_date ) : "" }</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ learning.persistent !==  1 ? formatDateEpoch( learning.end_date ) : strings.neverExpiresLabel }</Typography>
                    </TableCell>                                        
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipEdit }>
                        <IconButton size='small' onClick={ ( ) => handleEdit( learning ) } >
                          <Edit/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipDelete }>
                        <IconButton size='small' onClick={ ( ) => handleDelete( learning ) } >
                          <Delete/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow> ) )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 9 } count={ settingsLearningList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        }
        { settingsLearningList === undefined &&
          <CircularProgress className={ classes.spinner }/>
        }
      </Paper>
      
      <Dialog open={ editMode } style={ { zIndex:0, height:'900px' } }>
        <Box className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleDialog }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

              <Grid item xs={6}>
                <FormControl variant='outlined'>
                  <InputLabel required>{ strings.labelCategory }</InputLabel>
                  <Select
                    native
                    value={ values.category }
                    onChange={ handleChange('category') }
                    input={ <OutlinedInput labelWidth={ 75 } name='category'/> }>
                    <option value={ 801 }>{ strings.templatesLabel }</option>
                    <option value={ 802 }>{ strings.softwareLabel }</option>
                    <option value={ 803 }>{ strings.newsLabel }</option>
                    <option value={ 804 }>{ strings.otherLabel }</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth >
                  <InputLabel variant='outlined'>{ strings.labelPersistent }</InputLabel>
                  <OutlinedInput
                    labelWidth={ 75 }
                    startAdornment={
                      <InputAdornment position="start">
                        <Checkbox
                          checked={ values.persistent === 1 }
                          onChange={ handleChange( 'persistent' ) } />
                        <Typography>{ strings.labelNeverExpires }</Typography>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <DateTimePicker
                  label={ strings.labelStartDate }
                  showTodayButton
                  ampm={false}
                  required={ values.persistent === 1 }
                  disabled={ values.persistent === 1 }
                  fullWidth
                  inputVariant='outlined'
                  value={ formatDateEpoch( values.start_date ) }
                  onChange={ handleChange('start_date') }
                  format='yyyy/MM/dd HH:mm' />
              </Grid>

              <Grid item xs={4}>
                <DateTimePicker
                  label={ strings.labelEndDate }
                  showTodayButton
                  ampm={false}
                  required={ values.persistent === 1 }
                  disabled={ values.persistent === 1 }
                  fullWidth
                  inputVariant='outlined'
                  value={ formatDateEpoch( values.end_date ) }
                  onChange={ handleChange('end_date') }
                  format='yyyy/MM/dd HH:mm' />
              </Grid>

              { values.picture === '' &&
              <Grid item xs={6}>
                <FormControl variant='outlined' required>
                <FormLabel><Typography variant='caption'>{ strings.labelPicture }</Typography></FormLabel>
                  <DropzonePic onFileAdded={ ( file ) => { startNewFile( file ) } } />
                </FormControl>
              </Grid>
              }

              { values.picture !== '' &&
              <Grid item xs={6}>
                <FormControl variant='outlined' required onClick={ handleEditPicClick }>
                  <FormLabel><Typography variant='caption'>{ strings.labelPicture }</Typography></FormLabel>
                    <img src={values.picture} height={ 160 } width={ 160 } alt=""/>
                    <input type='file' accept='.png'  ref= { fileUploader } style={{display: "none"}} onChange={ ( file ) => { onPictureEdit( file ) }}/>
                    <FormControl><Typography variant='body1' style={{fontStyle:"italic", fontSize:"13px"}}>{ strings.toolTipEditPicture }</Typography></FormControl>
                </FormControl>
                <FormControl><FormLabel onClick={ handleChange( 'delPicture' ) }><Typography style={{color:"blue", textDecoration:"underline", fontSize:"13px"}}>{ strings.toolTipDeletePicture }</Typography></FormLabel> </FormControl>

              </Grid>
              }

              <Grid item xs={6}>
                <FormControl variant='outlined' required>
                  <FormLabel><Typography variant='caption'>{ strings.labelMsg }</Typography></FormLabel>
                  <SunEditor
                    setContents={ values.msgShow }
                    onChange={ handleChange( 'msg' ) }
                    setOptions={{
                      mode:'balloon',
                      resizingBar: false,
                      width: '275px',
                      buttonList: [ ['undo', 'redo'], ['formatBlock'], ['bold', 'underline', 'italic', 'align'], ['link' ], ] }} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth type='url' variant='outlined' margin='dense' id='link'
                  label={ strings.labelLink } value={ values.link } onChange={ handleChange('link') }/>
              </Grid>

            </Grid>

            <Box className={ classes.lineButtonBox }>
              <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
              <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleClose() }>{ strings.buttonCancel }</Button>
            </Box> 
          </form>
        </Box>       
      </Dialog>

      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    settingsLearningTableForceRefresh: state.settingsLearningTableForceRefresh,
    settingsLearningList: state.settingsLearningList,
  }
};

export default connect( mapStateToProps, {  })( withStyles( gStyles )( SettingsLearning ) );