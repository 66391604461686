import React, { useState }  from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { setCustomer } from './CustomerRest';
import { gStyles, fieldLimits } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Box, CircularProgress, TextField, Button, Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';


let strings=new LocalizedStrings({
	en:{
    defaultCustomerName:'new customer',
    labelName:'Name',
    labelVAT:'VAT',
    labelAddress:'Address',

    buttonEdit:'Edit',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',
	}
});

function CustomerDetail( props ) { 

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { customer_id: 0, valid: false, name: '', vat: '', address: '' } );

  const { userType } = props;
  const { customerSelected } = props;

  if ( customerSelected !== undefined && !values.valid ) {
    setValues( { valid:true, customer_id: customerSelected.customer_id, name: customerSelected.name, vat: customerSelected.vat, address: customerSelected.address } );
  }

  const handleChange = name => event => {
    setValues( { ...values, [name]: event.target.value } );
  };

  const handleEdit = () => {
    setEditMode( true );
  };

  const handleCancel = () => {
    setEditMode( false );
    setValues( { ...values, name: customerSelected.name, vat: customerSelected.vat, address: customerSelected.address } );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setCustomer( values );
    setEditMode( false );
  };

  return (
    <Box className={ classes.workarea }>
      { customerSelected !== undefined && 
        <Box>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>

            <Grid container spacing={2}>

              <Grid item xs={8}>
                <TextField fullWidth variant='outlined' required id='name' disabled={ !editMode }
                  label={ strings.labelName } value={ values.name } onChange={ handleChange('name') }
                  inputProps={ { maxLength: fieldLimits.customer.name.size } }/>
              </Grid> 

              <Grid item xs={4}>
                <TextField fullWidth variant='outlined' id='vat' disabled={ !editMode }
                  label={ strings.labelVAT } value={ values.vat } onChange={ handleChange('vat') }
                  inputProps={ { maxLength: fieldLimits.customer.vat.size } }/>
              </Grid> 

              <Grid item xs={12}>
                <TextField fullWidth variant='outlined' id='address' disabled={ !editMode }
                  label={ strings.labelAddress } value={ values.address } onChange={ handleChange('address') }
                  inputProps={ { maxLength: fieldLimits.customer.address.size } }/>
              </Grid> 
         
              <Grid item xs={12}>
                <Box className={ classes.lineButtonBox }>
                  { !editMode && userType !== 0 && customerSelected.is_direct_customer === 1 &&
                    <Button variant='contained' color='secondary' className={ classes.lineButton } onClick={ handleEdit }>
                      <Edit className={ classes.leftIcon }/>
                      { strings.buttonEdit }
                    </Button>
                  }
                  { editMode &&
                    <Button variant='contained' color='secondary' className={ classes.lineButton } type='submit'>
                      { strings.buttonConfirm }
                    </Button>
                  }
                  { editMode &&
                    <Button variant='contained' color='secondary' className={ classes.lineButton } onClick={ handleCancel }>
                      { strings.buttonCancel }
                    </Button>
                  }
                </Box>
              </Grid> 

            </Grid>            

          </form>
        </Box>
      }
      { customerSelected === undefined &&
        <CircularProgress className={ classes.spinner }/>
      }
    </Box>
  );
 }

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    customerSelected: state.customerSelected,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( CustomerDetail ) );