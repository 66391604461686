import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme ({
    palette: {
        primary: {
            main: '#25282F',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#5BC592',
            contrastText: '#FFFFFF',

        },
        background: {
            paper: '#F7F7F7',
        }
    },
} );

//        zIndex: theme.zIndex.drawer + 1,
export const gStyles = theme => ( {
    app: {
        padding: theme.spacing(0),
    },
    appBarBox: {
        zIndex: theme.zIndex.drawer + 1,
        position:'relative',
    },
    appBar: {
        backgroundColor: theme.palette.primary.main,
    },
    appBarLogoImg: {
        width: '255px',
    },
    appUserBox: {
        display: 'flex',
        flexFlow: 'column',
    },
    appUserItem: {
        margin: 'auto',
    },
    appBarBanner: {
        position:'fixed',
        top: '70px',
    },
    appDashboard: {
        width:'100%',
        position:'absolute',
        top:'104px',
    },
    appSettingsButton: {
        position:'fixed',
        top:'90px',
        left:'2px',
    },
    appDrawer: {
        marginTop:'90px',
    },
    appDrawerSettingsButton: {
        zIndex: theme.zIndex.drawer + 1,
        position:'absolute',
        right:'2px',
    },

    
    title: {
        color: '#5BC592',
    },

    boxOEMDashboard: {
        justifyContent: 'right',
        alignItems: 'right',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    
    selectedItemPaper: {
        marginTop: theme.spacing(0),
        marginRight: 'auto',
        marginLeft: 'auto',
        padding: theme.spacing(0),
        width: '1200px',
    },
    selectedItemAppBar: {
        backgroundColor: theme.palette.primary.main,
    },

    workareaTopGrid: {
        margin: theme.spacing(0), 
        padding: theme.spacing(2),
        textAlign: 'left',
        position:'relative', 
    },

    workareaGrid: {
        margin: theme.spacing(0), 
        padding: theme.spacing(2),
        textAlign: 'center',
        position:'relative',
        minHeight: theme.spacing(22), 
    },

    workareaTopBoxes: {
        margin: theme.spacing(0), 
        padding: theme.spacing(2),
        textAlign: 'left',
        position:'relative', 
        minHeight: theme.spacing(32),      
    },
    workarea: {
        margin: theme.spacing(0), 
        padding: theme.spacing(2),
        textAlign: 'left',
        position:'relative', 
        minHeight: theme.spacing(22),      
    },
    floatingImage: {
        height: '290px',
        position:'absolute',
        top: theme.spacing(1),
        right: theme.spacing(6),
    },
    tableArea: {
        marginTop: theme.spacing(1),
        minHeight: theme.spacing(25),
    },
    tableButton: {
        padding: '0px',
        width: '24px',
        cursor:'pointer',
    },

    flexHeader: {
        display: 'flex',
    },
    flexExpand: {
        flex: 1,
    },
    flexButton: {
        marginRight: '18px',
        marginLeft: '18px',
    },

    searchPaper: {
        padding: '2px 4px',
        display: 'flex',
        width: theme.spacing(30),
        backgroundColor: '#FFFFFF',
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },

    link: {
        cursor:'pointer',
        textDecoration: 'underline',
    },
    spinner: {
        position: 'absolute',
        top:'40%',
        left:'50%',
    },

    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    formSubmit: {
        margin: theme.spacing(3, 0, 2),
    },

    dividerSpacer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    piechart: {
        width: theme.spacing(88),
        height: theme.spacing(23),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    signInArea: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    signInIcon: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },

    pagination: { 
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },

    lineButtonBox: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
    },
    lineButton: {
        margin: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },

    uploadContent: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '16px',
        boxSizing: 'border-box',
        width: '100%',
    },
    uploadFileInfo: {
        marginLeft: '32px',
        alignItems: 'flex-start',
        justifyItems: 'flex-start',
        flex: 1,
        overflowY: 'auto',
        minWidth: '350px',
    },
    uploadProgressWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center', 
    },
    uploadProgressBar: {
        width: '100%',
        height: '8px',
        backgroundColor: 'rgb(183, 155, 229)',
        borderRadius: '5px',
        marginRight: theme.spacing(2),
    },
    uploadProgress: {
        backgroundColor: 'rgba(103, 58, 183, 1)',
        height: '100%',
        margin: '0px',
        borderRadius: '5px',
    },
    dropZone: {
        height: '180px',
        width: '180px',
        backgroundColor: '#fff',
        border: '2px dashed rgb(187, 186, 186)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontSize: '16px',
    },
    dropZoneHighlight: {
        backgroundColor: 'rgb(188, 185, 236)',
    },
    dropZoneIcon: {
        opacity: 0.3,
        height: '64px',
        width: '64px',
    },
    dropZonePic: {
        height: '150px',
        width: '150px',
        backgroundColor: '#fff',
        border: '2px dashed rgb(187, 186, 186)',
        borderRadius: '0%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontSize: '16px',
    },
    dropZonePicHighlight: {
        backgroundColor: 'rgb(188, 185, 236)',
    },
    dropZonePicIcon: {
        opacity: 0.3,
        height: '64px',
        width: '64px',
    },

    cardAction: {
        display: 'block',
        textAlign: 'initial',
    },

    lineEditor: {
        width: '150px',      
    },

    workareaEditor: { 
        height: '400px',      
    },
});

export const colorArray = [ '#DF536E', '#278BE1', '#01CAB1', '#bfef45', '#3cb44b', '#42d4f4', '#4363d8', '#911eb4', '#f032e6', '#a9a9a9' ];

export const fieldLimits = {
    signIn: {
        user : {
            size: 48,
        },
        password: {
            size:32,
        },
    },
    distributor: {
        name : {
            size: 48,
        },
        email: {
            email: 64,
        },
        phone: {
            phone: 32,
        },
        url: {
            url: 64,
        },
    },
    distributorUser: {
        id: {
            size: 32,
        },
        name: {
            size: 32,
        },
        email: {
            size: 64,
        },
        phone: {
            size: 32,
        },
        url: {
            size: 64,
        },
        password: {
            size: 32,
        },
    },
    customer: {
        name : {
            size: 48,
        },
        vat: {
            size:16,
        },
        address: {
            size: 128,
        },
    },
    customerContact: {
        email: {
            size: 64,
        },
        first_name: {
            size: 48,
        },
        last_name: {
            size: 48,
        },
        phone: {
            size:16,
        },
    },
}