import React, { useEffect, useRef }  from 'react';
import LocalizedStrings from 'react-localization';
import { useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux'
import { getSettingsOEMTheme, setSettingsOEMTheme } from './SettingsRest';

import { withStyles } from '@material-ui/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, AppBar, Toolbar, Typography, Button, CircularProgress, Paper, Tooltip, IconButton } from '@material-ui/core';

import logoIcon from './../resources/preview/icon32.png';
import appIcon from './../resources/preview/appIcon.png';
import backgroundIcon from './../resources/preview/icon74.png';

import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

let strings=new LocalizedStrings({
	en:{
    title:'MY OEM CUSTOMIZATION',

    softwareLogo:'Software Logo',
    softwareIcon:'Software Icon',
    backgroundImage:'Background Image',

    buttonPreview:'Preview',

	}
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

/*interface RouteParams {
  omeid: string
}*/


function Test( {settingsOEMPreviewForceRefresh, settingsOEMThemeValues} : Props ) {

  //const [ values, setValues ] = useState( { valid: false, oem_id: 0, theme_id: 0, logo: '', icon: '', background: '' } );

  //const fileUploader = useRef(null);
  //const oemId = useParams<RouteParams>();

  const classes = useStyles();


  useEffect(
    () => {
      //getSettingsOEMTheme( oemId );
    },
    [ settingsOEMPreviewForceRefresh, settingsOEMThemeValues ]
  );


  return (
    <Box>
      <Paper style={{ minHeight: '362px'}}>
        { settingsOEMThemeValues !== undefined && 
            <Grid container spacing={0}>
              
              <Grid item xs={12}>

                  { settingsOEMThemeValues.logo === '' &&
                    <AppBar position="static" style={{ backgroundColor: `${settingsOEMThemeValues.data.background_color_primary}` }}>
                      <Toolbar>
                        <img src={ appIcon } height={ 30 } width={ 30 } alt=""/>
                        <Typography variant='h6'> { 'Welcome Preview' } </Typography>
                      </Toolbar>
                    </AppBar>
                  }


                  { settingsOEMThemeValues.logo !== '' &&
                    <AppBar position="static" style={{ backgroundColor: `${settingsOEMThemeValues.data.background_color_primary}` }}>
                      <Toolbar>
                        <img src={settingsOEMThemeValues.logo} height={ 30 } width={ 30 } alt=""/>
                      </Toolbar>
                    </AppBar>
                  }
              </Grid>

              <Grid item xs={3}>
                <Box border={1}>
                  <Paper style={{ minHeight: '374px', backgroundColor: `${settingsOEMThemeValues.data.background_color_primary}` }}>
                    <Grid container spacing={5}>


                      <Grid item xs={12}>
                        { settingsOEMThemeValues.icon === '' &&
                          <Grid container justify="center" direction="column" spacing={0}>
                            <img src={logoIcon} height={ 100 } width={ 100 } alt=""/>
                            <Typography variant='h6'> { 'Software Logo' } </Typography> 
                          </Grid>
                        }

                        { settingsOEMThemeValues.icon !== '' &&
                          <Grid container justify="center">
                            <img src={settingsOEMThemeValues.icon} height={ 100 } width={ 100 } alt=""/>
                          </Grid>
                        }
                      </Grid>

                      <Grid item xs={12}>
                        <Typography style={{ color: `${settingsOEMThemeValues.data.text_color_primary}` }} align={'center'} variant='subtitle1'> { 'Primary' } </Typography>
                        <Typography style={{ color: `${settingsOEMThemeValues.data.text_color_default}` }} align={'center'} variant='subtitle1'> { 'Default Text' } </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography style={{ color: `${settingsOEMThemeValues.data.text_color_secondary}` }} align={'center'} variant='subtitle1'> { 'Secondary Text' } </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Grid>

              <Grid item xs={9}>
                <Box border={1}>

                  { settingsOEMThemeValues.background === '' &&
                    <Paper style={{ minHeight: '362px' }}>
                      <Grid container spacing={2}>

                        <Grid item xs={3}>
                          <Typography style={{ color: `${settingsOEMThemeValues.data.text_color_primary}` }} align={'center'} variant='subtitle1'> { 'Primary' } </Typography>
                          <Typography style={{ color: `${settingsOEMThemeValues.data.text_color_default}` }} align={'center'} variant='subtitle1'> { 'Default Text' } </Typography>
                          <Button style={{ backgroundColor: settingsOEMThemeValues.data.button_normal_fill, borderColor: settingsOEMThemeValues.data.button_normal_outline }} variant="contained" startIcon={<HomeIcon style={{ color: settingsOEMThemeValues.data.button_normal_icon}} />} />
                          <Button style={{ backgroundColor: settingsOEMThemeValues.data.button_selected_fill }} variant="contained" startIcon={<HomeOutlinedIcon style={{ color: settingsOEMThemeValues.data.button_selected_icon}} />} />
                          <Button style={{ backgroundColor: settingsOEMThemeValues.data.button_disabled_fill }} disabled variant="contained" startIcon={<HomeIcon style={{ color: settingsOEMThemeValues.data.button_disabled_icon}} />} />
                          <Typography style={{ color: `${settingsOEMThemeValues.data.text_color_secondary}` }} align={'center'} variant='subtitle1'> { 'Secondary Text' } </Typography>
                        </Grid>
                        

                        <Grid item xs={9}>
                          <img src={backgroundIcon} height={ 160 } width={ 160 } alt=""/>
                          <Typography variant='h6'> { 'Background Image' } </Typography>
                        </Grid>
                        
                        
                      </Grid>
                    </Paper>
                  }

                  { settingsOEMThemeValues.background !== '' &&
                    <Paper style={{ minHeight: '362px', backgroundImage: `url(${settingsOEMThemeValues.background})` }}>
                      <Grid container spacing={2}>

                        <Grid item xs={3}>
                          <Typography style={{ color: `${settingsOEMThemeValues.data.text_color_primary}` }} align={'center'} variant='subtitle1'> { 'Primary' } </Typography>
                          <Typography style={{ color: `${settingsOEMThemeValues.data.text_color_default}` }} align={'center'} variant='subtitle1'> { 'Default Text' } </Typography>
                          <Button style={{ backgroundColor: settingsOEMThemeValues.data.button_normal_fill, borderColor: settingsOEMThemeValues.data.button_normal_outline }} variant="contained" startIcon={<HomeIcon style={{ color: settingsOEMThemeValues.data.button_normal_icon}} />} />
                          <Button style={{ backgroundColor: settingsOEMThemeValues.data.button_selected_fill }} variant="contained" startIcon={<HomeOutlinedIcon style={{ color: settingsOEMThemeValues.data.button_selected_icon}} />} />
                          <Button style={{ backgroundColor: settingsOEMThemeValues.data.button_disabled_fill }} disabled variant="contained" startIcon={<HomeIcon style={{ color: settingsOEMThemeValues.data.button_disabled_icon}} />} />
                          <Typography style={{ color: `${settingsOEMThemeValues.data.text_color_secondary}` }} align={'center'} variant='subtitle1'> { 'Secondary Text' } </Typography>
                        </Grid>
                        

                        <Grid item xs={9}>
                        </Grid>
                        
                        
                      </Grid>
                    </Paper>
                  }

                  
                </Box>
              </Grid>
                
                          
            </Grid>
        }
        { settingsOEMThemeValues === undefined &&
          <CircularProgress/>
        }
      </Paper>
    </Box>
      

  );
}

interface Props extends PropsFromRedux {

  }

  //type PropsFromRedux = ConnectedProps<typeof connector>

/*const mapStateToProps = state => { 
  return {
    settingsOEMEditorForceRefresh: state.settingsOEMEditorForceRefresh,
    settingsOEMThemeValues: state.settingsOEMThemeValues,
  }
};*/

interface RootState {
  settingsOEMPreviewForceRefresh: boolean,
  settingsOEMThemeValues: { logo: string; icon: string; background: string, data: Data },
}


const mapStateToProps = (state: RootState) => ({
  settingsOEMPreviewForceRefresh: state.settingsOEMPreviewForceRefresh,
  settingsOEMThemeValues: state.settingsOEMThemeValues,
})

const mapDispatch = {
  toggleOn: () => ({ type: 'TOGGLE_IS_ON' }),
}

const connector = connect(mapStateToProps)


type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Test)

//export default connect( mapStateToProps, { } )( withStyles( gStyles )( test ) );