import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { weekDay, month } from '../utils/Utils';
import { getPrinterStatus } from './PrinterRest';
import { gStyles, colorArray } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { CircularProgress, FormControl, InputLabel, Select, OutlinedInput } from '@material-ui/core';

import { Line } from 'react-chartjs-3';

let strings=new LocalizedStrings({
	en:{
    today: 'Today',
    labelPeriod: 'Period',
    week: 'Last Week',
    month: 'Last Month',
    year: 'Last Year',
    fiveYears: 'Last 5 Years',
	}
})

function PrinterUsage( props ) {

  const { classes } = props;

  const [ printerUsagePeriod, setPrinterUsagePeriod ] = useState(0);
  const { printerStatusList } = props;
  const { printerId } = useParams();

  useEffect(
    ( ) => {
      getPrinterStatus( printerId );
    },
    [ printerId ]
  );

  const handleChange = event => {
    setPrinterUsagePeriod( Number(event.target.value) );
  };

  const getGraphDots = ( baseArray, dataArray, today, minus, printer ) => {
    // dataArray is in DESC order, from newer to older
    // baseArray is reversed from [ 14, ..., 0 ], is [0, ..., 14]
    var index = 0;
    return baseArray.map( value => {
        var baseValueToCompare = today - value * minus;
        while ( index < dataArray.length ) {
          var dataCreateDate = dataArray[index].create_date * 1000;
          if ( baseValueToCompare >= dataCreateDate ) {
            //if the value is too old, apply only to ribbons
            if ( dataCreateDate < baseValueToCompare - minus && index === 0 && !printer) 
              return undefined;
            return dataArray[index].value;
          }
          else {
            index++;
            //the first data value is allways showned on first date - 1
            //example: printer starts in may with 100, 101, ..., 200
            //         it will show 100 in april and 200 in may
            if ( dataArray.length === index )
              return dataArray[index-1].value;
          }
        }
        return undefined;
    } )
  }

  var data = {
    datasets: [
      //{ label: 'Printer', borderColor: '#71B37C', yAxisID: 'y-axis-1', lineTension: 0, data: yAxis1Data, },
      //{ label: 'Ribbon1', borderColor: '#EC932F', yAxisID: 'y-axis-2', lineTension: 0, data: yAxis2Data, },
    ]
  };
  var options = {
    responsive: true,
    tooltips: { mode: 'label' },
    elements: { line: { fill: false } },
    scales: {
      xAxes: [
        { display: true, gridLines: { display: true }, /*labels: xLables,*/ }
      ],
      yAxes: [
        { display: true, position: 'left', id: 'y-axis-1', gridLines: { display: true }, labels: { show: true } },
        { display: true, position: 'right', id: 'y-axis-2', gridLines: { display: false }, labels: { show: true } },
      ],
    }
  };
  
  if ( printerStatusList !== undefined ) {

    const today = Date.now();
    var minus = 0;
    var baseArray = [];

    switch ( printerUsagePeriod ) {
      case 0: //last week
        minus = 43200000; //12h
        baseArray = [ 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0 ];
        break;
      case 1: //last month
        minus = 86400000; //1d
        baseArray = [ 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0 ];
        break;
      case 2: //last year
        minus = 1296000000; //15d
        baseArray = [ 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0 ];
        break;    
      case 3: //last 5 year
        minus = 15552000000; //180d
        baseArray = [ 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0 ];
        break;  
      default:
        minus = 43200000; //12h
        baseArray = [ 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0 ];
        break;
    }

    options.scales.xAxes[0].labels = baseArray.map( value => {
      if ( value === 0 )
        return strings.today;
      if ( value % 2 === 0 ) {
        var date = new Date( today - value * minus );
        switch( printerUsagePeriod ) {
          case 0: return weekDay( date.getDay() );
          case 1: return date.getDate();
          case 2: return month( date.getMonth() );
          case 3: return date.getFullYear();
          default: return date.getDay();
        }
      }  
      return '';
    } )

    baseArray.reverse();
    const yAxis1Data = getGraphDots( baseArray, printerStatusList.cardCount, today, minus, true ).reverse();
    data.datasets.push( { label: 'Printer', borderColor: colorArray[0], yAxisID: 'y-axis-1', lineTension: 0, data: yAxis1Data } );

    printerStatusList.ribbons.forEach( ( ribbon, index ) => {
      const yAxis2Data = getGraphDots( baseArray, ribbon.status, today, minus, false ).reverse();
      data.datasets.push( { label: ribbon.type, borderColor: colorArray[ (index+1) % colorArray.length ], yAxisID: 'y-axis-2', lineTension: 0, data: yAxis2Data } );
    } )
  }
  
  return (
    <div className={ classes.workarea }>
      <div className={ classes.flexHeader }>
        <div className={ classes.flexExpand }/>
        <FormControl variant='outlined'>
          <InputLabel>{ strings.labelPeriod }</InputLabel>
          <Select
            native
            value={ printerUsagePeriod }
            onChange={ handleChange }
            input={ <OutlinedInput labelWidth={ 50 } name='type'/>}>
            <option value={0}>{ strings.week }</option>
            <option value={1}>{ strings.month }</option>
            <option value={2}>{ strings.year }</option>
            <option value={3}>{ strings.fiveYears }</option>
          </Select>
        </FormControl>
      </div>
      { printerStatusList !== undefined &&
        <Line data={ data } options={ options }/>
      }
      { printerStatusList === undefined &&
        <CircularProgress className={ classes.spinner }/>
      }
    </div>
  );
}

const mapStateToProps = state => { 
  return {
    printerStatusList: state.printerStatusList,
  }
};


export default connect( mapStateToProps, { } )( withStyles( gStyles )( PrinterUsage ) );