import React from 'react';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';

import DistributorTable from './DistributorTable';

function DistributorsDashboard( props ) {

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DistributorTable/>
        </Grid> 
      </Grid>
    </Container>
  );
}

export default connect( null, { } )( withStyles( gStyles )( DistributorsDashboard ) );
