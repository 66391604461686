import React, { useEffect }  from 'react';
import { useHistory } from 'react-router-dom';
import { Switch, Route, Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Grid, Container,Paper, AppBar, Toolbar, Tabs, Tab } from '@material-ui/core';

import SettingsOEMEditor from './SettingsOEMEditor';
//import SettingsOEMPreview from './SettingsOEMPreview';
import Filetest from './Test';


let strings=new LocalizedStrings({
	en:{
    title:'MY OEM CUSTOMIZATION',

    tabGrip:'DEFAULT GRIP',
    tabTheme:'APP CUSTOM THEME',
    tabEditions:'EDITIONS',
    tabVariables:'VARIABLES',

	}
})


function SettingsOEMTheme( props ) {

  const { classes } = props;
  
  //let history = useHistory();

 /* useEffect(
    () => {
      if ( history.location.pathname === '/settings/OEMdashboard' )
        history.replace( '/settings/OEMdashboard/grip' );
    },
    [ history ]
  );*/

  return (
    <Container maxWidth={false}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SettingsOEMEditor/>
      </Grid>
      <Grid item xs={6}>
        <Filetest/>
      </Grid>
    </Grid>
  </Container>

  );
}

const mapStateToProps = state => { 
  return {
    userType: state.userType,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( SettingsOEMTheme ) );