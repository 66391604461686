import React, { useEffect, useState }  from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { getDistributorUsers, setDistributorUser, deleteDistributorUser } from './DistributorRest';
import { gStyles, fieldLimits } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { Typography, CircularProgress, TextField, Button, Tooltip, IconButton, Grid } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Edit, Delete, AddCircle } from '@material-ui/icons';

import TablePaginationActions, { handleChangePage, handleChangeRowsPerPage } from '../components/TablePaginationActions';

let strings=new LocalizedStrings({
	en:{
    tableHeaderId:'Id',
    tableHeaderName:'Name',
    tableHeaderEmail:'Email',

    toolTipEdit:'Edit the user',
    toolTipDelete:'Delete the user',

    titleEdit:'User',
    labelId:'Id',
    labelName:'Name',
    labelEmail:'Email',
    labelPassword:'Password',

    buttonAdd:'Add new user',
    buttonResetPassword:'Reset Password',
    buttonGeneratePassword:'Generate Password',
    buttonConfirm:'Confirm',
    buttonCancel:'Cancel',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete user \'{entity}\'?',
    yes: 'Yes',
    no: 'No',
	}
});

function DistributorUserTable( props ) { 

  const { classes } = props;

  const [ editMode, setEditMode ] = useState( false );
  const [ values, setValues ] = useState( { id: '', name: '', email: '', password: '' } );
  const [ entityToDelete, setEntityToDelete ] = useState( undefined );

  const { distributorUserList } = props;
  const { distributorUserTableForceRefresh } = props;
  const { distributorId } = useParams();

  var history = useHistory();
  var searchParams = new URLSearchParams( history.location.search );
  const page = Number( searchParams.get( 'page' ) );
  var urlRowsPerPage =  Number( searchParams.get( 'rows' ) );
  const rowsPerPage = ( urlRowsPerPage !== 10 && urlRowsPerPage !== 20 && urlRowsPerPage !== 40 ) ? 20 : urlRowsPerPage;

  useEffect(
    ( ) => { 
      getDistributorUsers( distributorId, page * rowsPerPage, rowsPerPage );
   },
   [ distributorId, page, rowsPerPage, distributorUserTableForceRefresh ]
  );

  const handleChange = name => event => {
    setValues( { ...values, [name]: event.target.value } );
  };

  const handleAdd = () => {
    setEditMode( true );
    setValues( { id: 'newuser', name: '', email: '', password: '' } );
  };

  const handleEdit = user => {
    setEditMode( true );
    setValues( { id: user.id, name: user.name, email: user.email, password: '' } );
  };

  const handleCancel = () => {
    setEditMode( false );
  };

  const handleConfirm = ( event ) => {
    event.preventDefault();
    setDistributorUser( distributorId, values );
    setEditMode( false );
  };

  const handleDeleteDialogClose = confirm => {
    if ( confirm )
      deleteDistributorUser( distributorId, entityToDelete );
    setEntityToDelete( undefined );
  }

  const handleDelete = user => {
    setEntityToDelete( user );
  };

  const onGeneratePassword = ( ) => {
    setValues( { ...values, password: Math.random().toString(36).substr(2) } );
  };

  return (
    <div>
      <div className={ classes.workarea }>
        <div className={ classes.flexHeader }>
          <Button variant='contained' color='secondary' onClick={ ( ) => handleAdd( ) }>
            <AddCircle className={ classes.leftIcon } />
            <Typography variant='button'>{ strings.buttonAdd }</Typography>
          </Button>
        </div>
        <div className={ classes.tableArea } >
          { distributorUserList !== undefined &&
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderName }</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>{ strings.tableHeaderEmail }</Typography>
                  </TableCell>
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                { distributorUserList.items.map( ( user, index ) => (
                  <TableRow key={ index }>  
                    <TableCell>
                      <Typography variant='body2'>{ user.name }</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body2'>{ user.email }</Typography>
                    </TableCell>  
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipEdit }>
                        <IconButton size='small' onClick={ ( ) => handleEdit( user ) } >
                          <Edit/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipDelete }>
                        <IconButton size='small' onClick={ ( ) => handleDelete( user ) } >
                          <Delete/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )) }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 4 } count={ distributorUserList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ ( event, newPage ) => handleChangePage( history, newPage )  }
                    onChangeRowsPerPage={ ( event ) => handleChangeRowsPerPage( history, event, page, rowsPerPage ) }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          }
          { distributorUserList === undefined &&
              <CircularProgress className={ classes.spinner }/>
          }
        </div>
      </div>
      <Dialog open={ editMode }>
        <div className={ classes.workarea }>
          <Typography variant='h4' className={ classes.title }>{ strings.titleEdit }</Typography>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => handleConfirm( event ) }>
          
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField variant='outlined' fullWidth id='name'
                  label={ strings.labelName } value={ values.name } onChange={ handleChange('name') }
                  inputProps={ { maxLength: fieldLimits.distributorUser.name.size } }/>
              </Grid>

              <Grid item xs={12}>
                <TextField variant='outlined' required fullWidth id='email'
                  label={ strings.labelEmail } value={ values.email } onChange={ handleChange('email') }
                  inputProps={ { maxLength: fieldLimits.distributorUser.email.size } }/>
              </Grid>

              <Grid item xs={12}>
                <div className={ classes.lineButtonBox }>
                  <TextField variant='outlined' required={ values.id === 'newuser' } id='token'
                    label={ strings.labelPassword } value={ values.password }
                    inputProps={ { maxLength: fieldLimits.distributorUser.password.size } }/>
                  <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => onGeneratePassword() }>{ values.id === 'newuser' ? strings.buttonGeneratePassword : strings.buttonResetPassword }</Button>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={ classes.lineButtonBox }>
                  <Button className={ classes.lineButton } variant='contained' color='secondary' type='submit'>{ strings.buttonConfirm }</Button>
                  <Button className={ classes.lineButton } variant='contained' color='secondary' onClick={ ( ) => handleCancel() }>{ strings.buttonCancel }</Button>
                </div>
              </Grid>

            </Grid>            
          </form>
        </div>       
      </Dialog>
      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText.replace( '{entity}', entityToDelete !== undefined ? entityToDelete.email : '' ) }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
 }

const mapStateToProps = state => { 
  return {
    distributorUserList: state.distributorUserList,
    distributorUserTableForceRefresh: state.distributorUserTableForceRefresh,
  }
};

export default connect( mapStateToProps, { } )( withStyles( gStyles )( DistributorUserTable ) );